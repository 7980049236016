import { Box, Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import { generateUUID } from "../../../utils/app-utils";
import { useEffect, useRef, useState } from "react";

export const AddContentButtons = (props) => {

    //-------------------------------------
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const prevOpen = useRef(open);

    const handleToggle = (param) => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    // const handleAddContentItem = (type, index) => {
    //     let temp = [...props.contents]
    //     const sampleText = {
    //         "id": generateUUID(),
    //         "type": "TEXT",
    //         "color": "#181818",
    //         "font": "FMDerana",
    //         "position": "left",
    //         "fontSize": "20px",
    //         "text": "mdidÈl fjí wvúh fj; Tnj idorfhka ms<s.ksuq",
    //     }
    //     const sampleList = {
    //         "id": generateUUID(),
    //         "type": "TEXT_LIST",
    //         "color": "#16212c",
    //         "font": "FMSamantha",
    //         "fontSize": "20px",
    //         "position": "left",
    //         "icon": "",
    //         "items": ["l,Hd‚ fhda.dY%u ixia:dj", "l,Hd‚ fhda.dY%u ixia:dj",],
    //     }
    //     const sampleImages = {
    //         "id": generateUUID(),
    //         "images": [],
    //         "position": "center",
    //         "caption": 'mdidÈl fikiqk',
    //         "borderShadow": `0px 2px 10px rgba(0, 62, 200, 0.5)`,
    //         "type": "IMAGES",
    //         "columns": 3,
    //         "gap": 1,
    //     }

    //     const sampleCard = {
    //         "id": generateUUID(),
    //         "type": "CARD",
    //         "columns": 3,
    //         "gap": 1,
    //     }
    //     if (props.contents.length === 0) {
    //         index = 0;
    //     } else {
    //         index = index + 1
    //     }

    //     if (type === "TEXT") {
    //         temp.splice(index, 0, sampleText);
    //     } else if (type === "TEXT_LIST") {
    //         temp.splice(index, 0, sampleList);
    //     } else if (type === "IMAGES") {
    //         temp.splice(index, 0, sampleImages);
    //     } else if (type === "CARD") {
    //         temp.splice(index, 0, sampleCard);
    //     }
    //     props.setContents(temp)
    //     props.setHasToBeSaved(true)
    // }

    return (
        <Box textAlign='center' marginTop={'0.1rem'} marginBottom={'1rem'}>
            <Button
                ref={anchorRef}
                id="text"
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={() => handleToggle("TEXT")}
            >
                {props.title}
            </Button>
            <div>
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                        autoFocusItem={open}
                                        id="composition-menu"
                                        aria-labelledby="composition-button"
                                        onKeyDown={handleListKeyDown}
                                    >
                                        {props.menuOptions && props.menuOptions.map((item, index) => (
                                            <MenuItem onClick={(event) => {
                                                props.onSelect(item.name)
                                                handleClose(event)
                                            }}
                                                key={index}>{item.name}</MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </Box>
    )
}