import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";

import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material';
import {themeOptions} from './themes/themes'
import { createTheme, styled } from '@mui/material/styles';
import {AppContextProvider} from "./context/appContext";

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = createTheme(themeOptions);

root.render(
    <React.StrictMode>
        <BrowserRouter>
        <SnackbarProvider maxSnack={5}>
            <ThemeProvider theme={theme}>
                <AppContextProvider>
                    <App />
                </AppContextProvider>
            </ThemeProvider>
        </SnackbarProvider>
        </BrowserRouter>
    </React.StrictMode>
);

