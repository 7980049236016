import {BFF_CONFIGS} from "../../config";
import {BFF_ENDPOINTS} from "../../utils/constants";
import {_delete, _post} from "../fetch-api";

export const createUpdateThumbnail = (callback, data, errCallback) => {
    const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.createOrUpdateThumbnail}`;
    _post(callback, endpointURL, data, {}, errCallback);
};

export const deleteThumbnail = (callback, parentId, parentType, errCallBack) => {
    const params = {
        parentId: parentId,
        parentType: parentType,
    };
    const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.deleteThumbnail}`;
    _delete(callback, endpointURL, errCallBack, params);
};