import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@mui/material";
import {Fragment} from "react";

export const FontOptionList = (props) => {

    return (
        <Fragment>
            <FormControl sx={{m: 1, minWidth: '20ch', marginRight: 0}}>
                <InputLabel id="font-label"> Font</InputLabel>
                <Select
                    labelId="font-label"
                    id="font-input"
                    defaultValue=""
                    onChange={props.onChangeValue}
                    value={props.value}
                    autoWidth
                    label="Font"
                    size="small"
                >
                    <MenuItem value={''}>None </MenuItem>
                    <MenuItem value={'FMAba-Bold'}>FMAba Bold </MenuItem>
                    <MenuItem value={'FMAbhaya'}>FMAbhaya </MenuItem>
                    <MenuItem value={'FMArjunn'}>FMArjunn</MenuItem>
                    <MenuItem value={'FMBasuru'}>FMBasuru</MenuItem>
                    <MenuItem value={'FMBindumathi'}>FMBindumathi</MenuItem>
                    <MenuItem value={'FMDerana'}>FMDerana</MenuItem>
                    <MenuItem value={'FMEb'}>FMEb</MenuItem>
                    <MenuItem value={'FMEmanee'}>FMEmanee</MenuItem>
                    <MenuItem value={'FMGanganee'}>FMGanganee</MenuItem>
                    <MenuItem value={'FMGangani'}>FMGangani</MenuItem>
                    <MenuItem value={'FMGemunu'}>FMGemunu</MenuItem>
                    <MenuItem value={'FMMalithi'}>FMMalithi</MenuItem>
                    <MenuItem value={'FMRajantha'}>FMRajantha</MenuItem>
                    <MenuItem value={'FMSamantha'}>FMSamantha</MenuItem>
                    <MenuItem value={'Cannon'}>Cannon</MenuItem>
                    <MenuItem value={'IMFellEnglish'}>IMFellEnglish</MenuItem>
                    <MenuItem value={'Oswald'}>Oswald</MenuItem>
                </Select>
            </FormControl>
        </Fragment>
    )
}