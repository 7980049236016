import {useState, useEffect} from "react";

import {
    Box,
    TextField,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid, Checkbox, FormControlLabel,
} from "@mui/material";
import {
    Cancel,
    Create,
    Update,
} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import {
    createUserRole,
    getUserAuthoritySuggestions,
    getUserRoleById,
    updateUserRole
} from "../../../../client/roles/call";
import {toast} from "react-toastify";


// create role form
export default function CreateRoleForm(props) {

    const [isLoading, setIsLoading] = useState(false);

    // create user inputs
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [authorities, setAuthorities] = useState([]);
    const [authoritiesCheckStatus, setAuthoritiesCheckStatus] = useState([]);

    // role list
    const [authoritiesSuggestions, setAuthoritiesSuggestions] = useState([]);

    useEffect(loadAuthoritiesSuggestions, [])

    const [status, setStatus] = useState('');

    //--------------------------------------------------
    const handleChangeName = (event) => {
        setName(event.target.value);
    };
    const handleChangeDescription = (event) => {
        setDescription(event.target.value);
    };

    // validate user inputs
    const [nameValidity, setNameValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateName = () => {
        let validity = {};
        if (!name || name === '') {
            validity.isValid = false;
            validity.errorMessage = "Name cannot be empty"
            setNameValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setNameValidity(validity)
            return true
        }
    };

    const [descriptionValidity, setDescriptionValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateDescription = () => {
        let validity = {};
        if (!description || description === '') {
            validity.isValid = false;
            validity.errorMessage = "Description cannot be empty"
            setDescriptionValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setDescriptionValidity(validity)
            return true
        }
    };

    const addOrRemoveAuthorities = (auth, index) => {
        if (index > -1) {
            let tempStatuses = [...authoritiesCheckStatus]
            if (authoritiesCheckStatus[index] === true) {
                tempStatuses[index] = false
                let temp = [...authorities]
                let ind = authorities.findIndex((e) => e.id === auth.id);
                temp.splice(ind, 1);
                setAuthorities([...temp])
            } else {
                let tempAuths = [...authorities]
                tempStatuses[index] = true
                tempAuths = tempAuths.concat([auth])
                setAuthorities(tempAuths);
            }
            setAuthoritiesCheckStatus(tempStatuses)
        }
    }

    useEffect(() => {
        let temp = [...authoritiesCheckStatus]
        authoritiesSuggestions.map((auth, index) => {
            if (authorities.filter(e => e.id === auth.id).length > 0) {
                temp[index] = true
            }
        })
        setAuthoritiesCheckStatus(temp)
    }, [authorities])

    function loadAuthoritiesSuggestions() {
        getUserAuthoritySuggestions(result => {
            setAuthoritiesSuggestions(result.content);
            let temp = [...authoritiesCheckStatus]
            result.content.map((auth, index) => {
                temp[index] = false
            })
            setAuthoritiesCheckStatus(temp)
            // call after loading suggestions
            if (props.isUpdate) {
                setIsLoading(true)
                getUserRoleById(
                    result => {
                        setId(result.content.id)
                        setName(result.content.name)
                        setDescription(result.content.description)
                        setAuthorities(result.content.authorities)
                        setStatus(result.content.status)

                        setIsLoading(false)
                    },
                    error => {
                    },
                    props.roleId,
                );
            }
        });
    }

    const validateInputFields = () => {
        let formValidators = []
        let isFormValid = true
        if (!validateName()) {
            formValidators[0] = false
        }
        if (!validateDescription()) {
            formValidators[1] = false
        }
        formValidators.map((val) => {
            if (!val) {
                isFormValid = false
            }
        })
        return isFormValid;
    }

    const setInputsToDefaultOnFormSubmit = () => {
        setName('')
        setDescription('')
        setAuthorities([])
        setStatus('')
    }

    const createRoleFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        let role = {
            name: name,
            description: description,
            authorities: authorities
        };

        createUserRole(
            (result) => {
                toast(`User role: ${role.name} was created!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.createOrUpdatecallback(result.content);
            },
            role,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    const updateRoleFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        let role = {
            id: id,
            name: name,
            description: description,
            authorities: authorities,
            status: status
        };

        updateUserRole(
            (result) => {
                toast(`User role: ${role.name} was updated!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.createOrUpdatecallback(result.content);
            },
            role,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    return (<Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1, width: '27ch'},
            }}
            noValidate
            autoComplete="off"
        >
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                >
                </DialogContentText>
                <div>
                    <TextField
                        required
                        id="name-input"
                        label="Name"
                        onChange={handleChangeName}
                        value={name}
                        error={!nameValidity.isValid}
                        onBlur={validateName}
                        helperText={(nameValidity.isValid) ? "Enter name here" : nameValidity.errorMessage}
                    />
                    <TextField
                        required
                        id="description-input"
                        label="Description"
                        onChange={handleChangeDescription}
                        value={description}
                        error={!descriptionValidity.isValid}
                        onBlur={validateDescription}
                        helperText={(descriptionValidity.isValid) ? "Enter description here" : descriptionValidity.errorMessage}
                    />
                </div>
                <Box sx={{mt: 3, ml: 1}}>
                    <Grid
                        container spacing={{xs: 2, md: 3}}
                        columns={{xs: 4, sm: 8, md: 12}}
                    >
                        {authoritiesSuggestions && authoritiesSuggestions.length > 0 && authoritiesSuggestions.map((auth, index) =>
                            <Grid item xs={2} sm={4} md={4}>
                                <FormControlLabel
                                    label={auth.name}
                                    control={<Checkbox
                                        checked={authoritiesCheckStatus[index]}
                                        onChange={() => {
                                            addOrRemoveAuthorities(auth, index)
                                        }}
                                    />
                                    }
                                />
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                {props.isUpdate ? (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={updateRoleFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Update/>}
                >Update</LoadingButton>) : (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={createRoleFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Create/>}
                >Create</LoadingButton>)}
                <LoadingButton
                    color={'warning'}
                    size="medium"
                    variant="outlined"
                    onClick={props.handleClose}
                    loading={false}
                    loadingPosition="end"
                    disabled={false}
                    endIcon={<Cancel/>}
                >Cancel</LoadingButton>
            </DialogActions>
        </Box>
    )
}