import {useState, useEffect} from "react";

import {
    Box, TextField,
    InputLabel, Select, MenuItem, FormHelperText, FormControl, DialogContent, DialogContentText, DialogActions,
} from "@mui/material";
import {Cancel, Create, Update,} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";

import {getUserRoleSuggestions, userCreate, userUpdate, loadUserById} from '../../../../client/users/call'
import {toast} from "react-toastify";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";


// create user form
export default function CreateUserForm(props) {

    const [isLoading, setIsLoading] = useState(false);

    // create user inputs
    const [id, setId] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userName, setUserName] = useState('');
    const [role, setRole] = useState('');
    const [userType, setUserType] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    // role list
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        loadUserRolesSuggestions()
        if (props.isUpdate) {
            setIsLoading(true)
            loadUserById(
                result => {
                    setId(result.content.id)
                    setFirstName(result.content.firstName)
                    setLastName(result.content.lastName)
                    setUserName(result.content.userName)
                    setUserType(result.content.userType)
                    setRole(result.content.roles[0])
                    setStatus(result.content.status)

                    setIsLoading(false)
                },
                error => {
                },
                props.userId,
            );
        }
    }, [])

    const [status, setStatus] = useState('');

    //--------------------------------------------------
    const handleChangeFirstName = (event) => {
        setFirstName(event.target.value);
    };
    const handleChangeLastName = (event) => {
        setLastName(event.target.value);
    };
    const handleChangeRole = (event) => {
        setRole(roles.find((rl) => rl.id === event.target.value));
    };
    const handleChangeUserType = (event) => {
        setUserType(event.target.value);
    };
    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    };
    const handleChangePasswordConfirm = (event) => {
        setConfirmPassword(event.target.value);
    };

    // validate user inputs
    const [firstNameValidity, setFirstNameValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateFirstName = () => {
        let validity = {};
        if (!firstName || firstName === '') {
            validity.isValid = false;
            validity.errorMessage = "FirstName cannot be empty"
            setFirstNameValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setFirstNameValidity(validity)
            return true
        }
    };

    const [lastNameValidity, setLastNameValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateLastName = () => {
        let validity = {};
        if (!lastName || lastName === '') {
            validity.isValid = false;
            validity.errorMessage = "LastName cannot be empty"
            setLastNameValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setLastNameValidity(validity)
            return true
        }
    };

    const [userTypeValidity, setUserTypeValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateUserType = () => {
        let validity = {};
        if (!userType || userType === '') {
            validity.isValid = false;
            validity.errorMessage = "UserType cannot be empty"
            setUserTypeValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setUserTypeValidity(validity)
            return true
        }
    };

    const [passwordValidity, setPasswordValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validatePassword = () => {
        let validity = {};
        let passwordPattern = /(?=.*[!@#$%^&*])/;
        if (!password || password === '' || !passwordPattern.test(password) ) {
            validity.isValid = false;
            validity.errorMessage = "Password must have at least 4 character including special character"
            setPasswordValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setPasswordValidity(validity)
            return true
        }
    };

    const [confirmPasswordValidity, setConfirmPasswordValidity] = useState(0);
    const validatePasswordConfirm = () => {
        let validity = {};
        if (!confirmPassword || confirmPassword === '' || !(confirmPassword === password)) {
            validity.isValid = false;
            validity.errorMessage = "Confirm password doesn't match with password"
            setConfirmPasswordValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setConfirmPasswordValidity(validity)
            return true
        }
    };

    const [roleValidity, setRoleValidity] = useState(0);
    const validateRole = () => {
        let validity = {};
        if (!role || role === '') {
            validity.isValid = false;
            validity.errorMessage = "Role cannot be empty"
            setRoleValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setRoleValidity(validity)
            return true
        }
    };

    function loadUserRolesSuggestions() {
        getUserRoleSuggestions(result => {
            setRoles(result.content);
        });
    }

    const validateInputFields = () => {
        let formValidators = []
        let isFormValid = true
        if (!validateFirstName()) {
            formValidators[0] = false
        }
        if (!validateLastName()) {
            formValidators[1] = false
        }
        if (!validateUserType()) {
            formValidators[2] = false
        }
        if (!validateRole()) {
            formValidators[3] = false
        }
        formValidators.map((val) => {
            if (!val) {
                isFormValid = false
            }
        })
        return isFormValid;
    }

    const setInputsToDefaultOnFormSubmit = () => {
        setFirstName('')
        setLastName('')
        setUserType('')
        setPassword('')
        setConfirmPassword('')
        setRole('')
        setStatus('')
    }

    const createUserFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        let user = {
            firstName: firstName,
            lastName: lastName,
            password: password,
            userType: userType,
            roles: [role]
        };

        userCreate(
            (result) => {
                toast(`User: ${user.firstName} was created!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.createOrUpdatecallback(result.content);
            },
            user,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    const updateUserFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        let user = {
            id: id,
            firstName: firstName,
            lastName: lastName,
            userName: userName,
            userType: userType,
            roles: [role],
            status: status
        };

        userUpdate(
            (result) => {
                toast(`User: ${role.name} was updated!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.createOrUpdatecallback(result.content);
            },
            user,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    return (<Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1, width: '27ch'},
            }}
            noValidate
            autoComplete="off"
        >
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                >
                </DialogContentText>
                <div>
                    <TextField
                        required
                        id="first-name-input"
                        label="First Name"
                        onChange={handleChangeFirstName}
                        value={firstName}
                        error={!firstNameValidity.isValid}
                        onBlur={validateFirstName}
                        helperText={(firstNameValidity.isValid) ? "Enter first name here" : firstNameValidity.errorMessage}
                    />
                    <TextField
                        required
                        id="last-name-input"
                        label="Last Name"
                        onChange={handleChangeLastName}
                        value={lastName}
                        error={!lastNameValidity.isValid}
                        onBlur={validateLastName}
                        helperText={(lastNameValidity.isValid) ? "Enter last name here" : lastNameValidity.errorMessage}
                    />
                </div>
                <div>
                    <FormControl sx={{m: 1, minWidth: '27ch'}} error={lastNameValidity === 1}>
                        <InputLabel id="user-type-label">User Type</InputLabel>
                        <Select
                            labelId="user-type-label"
                            id="user-type-input"
                            onChange={handleChangeUserType}
                            value={userType}
                            error={!userTypeValidity.isValid}
                            onBlur={validateUserType}
                            autoWidth
                            label="User Type"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value='USER'>User</MenuItem>
                            <MenuItem value='ADMIN'>Admin</MenuItem>
                        </Select>
                        <FormHelperText>{userTypeValidity.isValid ? "Select you user type" : userTypeValidity.errorMessage}</FormHelperText>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: '27ch'}} error={role === 1}>
                        <InputLabel id="user-role-label">User Role</InputLabel>
                        <Select
                            labelId="user-role-label"
                            id="user-role-input"
                            value={role ? role.id : ""}
                            onChange={(event) => {
                                handleChangeRole(event)
                            }}
                            error={!roleValidity.isValid}
                            onBlur={validateRole}
                            autoWidth
                            label="Role"
                        >
                            <MenuItem key={"none"} value="">
                                <em>None</em>
                            </MenuItem>
                            {(roles) && roles.map((rl) => (
                                <MenuItem key={rl.id} value={rl.id}>{rl.name}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{roleValidity.isValid ? "Select a user role" : roleValidity.errorMessage}</FormHelperText>
                    </FormControl>
                </div>
                {!(props.isUpdate) && (
                    <>
                        <div>
                            <TextField
                                required
                                id="password-input"
                                label="Password"
                                type="password"
                                value={password}
                                onChange={handleChangePassword}
                                error={!passwordValidity.isValid}
                                onBlur={validatePassword}
                                helperText={(passwordValidity.isValid) ? "Enter password here" : passwordValidity.errorMessage}
                            />
                            <TextField
                                required
                                id="confirm-password-input"
                                label="Password Confirm"
                                type="password"
                                onChange={handleChangePasswordConfirm}
                                value={confirmPassword}
                                error={!confirmPasswordValidity.isValid}
                                onBlur={validatePasswordConfirm}
                                helperText={(confirmPasswordValidity.isValid) ? "Enter confirm password here" : confirmPasswordValidity.errorMessage}
                            />
                        </div>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                {props.isUpdate ? (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={updateUserFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Update/>}
                >Update</LoadingButton>) : (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={createUserFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Create/>}
                >Create</LoadingButton>)}

                <LoadingButton
                    color={'warning'}
                    size="medium"
                    variant="outlined"
                    onClick={props.handleClose}
                    loading={false}
                    loadingPosition="end"
                    disabled={false}
                    endIcon={<Cancel/>}
                >Cancel</LoadingButton>
            </DialogActions>
        </Box>
    )
}