import PageLayout from "../../../ui/layout/pageLayout";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {deleteDiscountById, searchDiscounts} from "../../../../client/discounts/call";
import {Card, MenuItem, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import ActionMenu from "../../../ui/menus/actionMenu";
import ListItemIcon from "@mui/material/ListItemIcon";
import {Delete, Edit} from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import CreateDiscountForm from "../discounts/createDiscountForm";
import AddIcon from "@mui/icons-material/Add";
import FloatingActionButtons from "../../../ui/buttons/floatingActions";
import MainModalDialog from "../../../ui/modal/mainModal";
import MainTable from "../../../ui/table/mainTable";
import Pagination from "../../../ui/pagination/pagination";
import {toast} from "react-toastify";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {formatAmount} from "../../../../utils/app-utils";

export default function Discounts() {

    const navigate = useNavigate()
    const params = useParams()

    const [pagination, setPagination] = useState({})

    // loading section
    const [isDiscountsLoading, setIsDiscountsLoading] = useState(true);

    // discounts list
    const [discountsList, setDiscountsList] = useState([]);

    function loadDiscounts(page, rowsPerPage) {
        setIsDiscountsLoading(true)
        searchDiscounts(
            result => {
                setDiscountsList(result.content);
                setPagination(result.pagination);
                setIsDiscountsLoading(false)
            },
            err => {
                setIsDiscountsLoading(false)
            },
            page ? page : pagination.pageNumber,
            rowsPerPage ? rowsPerPage : pagination.pageSize,
            null,
            null,
            null
        );
    }

    const {page, rowsPerPage} = pagination
    useEffect(loadDiscounts, [page, rowsPerPage])

    // modal related properties
    const [modalOpen, setModalOpen] = useState(false);
    const [modelToOpen, setModelToOpen] = useState('');
    const [modelHeader, setModelHeader] = useState('');
    const [discountTobeUpdated, setDiscountTobeUpdated] = useState('');

    function deleteDiscountsFunc(discountId) {
        deleteDiscountById(data => {
                toast(`Discount was deleted!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                loadDiscounts();
            },
            discountId,
            error => {
                toast(`Error: ${error}`,
                    {
                        closeOnClick: true,
                        type: "error",
                        theme: "light",
                        icon: <ErrorOutlineIcon/>
                    });
            })
    }

    const tableHeader = (<TableHead>
        <TableRow>
            <TableCell>Amount</TableCell>
            <TableCell align="left">Vendor ID</TableCell>
            <TableCell align="left">Payment ID</TableCell>
            <TableCell align="left">Action Items</TableCell>
        </TableRow>
    </TableHead>)

    const tableBody = (
        (discountsList && discountsList.length > 0) ? (<TableBody>
                {discountsList.map((discount, index) => (
                    <TableRow
                        key={index}
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                        <TableCell align="left">{formatAmount(discount.amount)}</TableCell>
                        <TableCell align="left">{discount.paymentId}</TableCell>
                        <TableCell align="left">{discount.vendorId}</TableCell>
                        <TableCell align="left" onClick={() => {
                            setDiscountTobeUpdated(discount.id)
                        }}>
                            <ActionMenu>
                                <MenuItem onClick={() => {
                                    deleteDiscountsFunc(discount.id)
                                }}>
                                    <ListItemIcon>
                                        <Delete/>
                                    </ListItemIcon>
                                    <ListItemText>Delete</ListItemText>
                                    <Typography variant="body2" color="text.secondary">
                                    </Typography>
                                </MenuItem>
                                {/*<MenuItem onClick={() => { handleModalOpen("updateDiscount")}}>
                                    <ListItemIcon>
                                        <Edit />
                                    </ListItemIcon>
                                    <ListItemText>Update</ListItemText>
                                    <Typography variant="body2" color="text.secondary">
                                    </Typography>
                                </MenuItem>*/}
                            </ActionMenu>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        ) : null
    )

    const handleModalOpen = (model) => {
        if (model === 'createDiscount') {
            setModelHeader("Create Discount")
            setModelToOpen(createDiscountForm)
        } else if (model === 'updateDiscount') {
            setModelHeader("Update Discount")
            setModelToOpen(updateDiscountForm)
        }
        setModalOpen(true);
    }
    const handleModalClose = () => setModalOpen(false);

    const createDiscountForm = (<CreateDiscountForm
        isUpdate={false}
        handleClose={handleModalClose}
        discountId={null}
        createOrUpdatecallback={(data) => {
            loadDiscounts()
            handleModalClose()
        }}
    />);

    const updateDiscountForm = (<CreateDiscountForm
        isUpdate={true}
        handleClose={handleModalClose}
        discountId={discountTobeUpdated}
        createOrUpdatecallback={() => {
            loadDiscounts()
            handleModalClose()
        }}
    />);

    const floatingActionConfig = [
        /*{
            title: "Create",
            icon: <AddIcon sx={{mr: 1}}/>,
            onClickAction: handleModalOpen,
            modelName: "createDiscount",
            visible: true,
        }*/
    ]
    return (
        <PageLayout header="Discounts">
            <Card sx={{padding: 1, margin: 0, border: '1px solid', borderColor: 'rgb(229,226,226)'}}>
                <FloatingActionButtons config={floatingActionConfig}/>
                <MainModalDialog
                    open={modalOpen}
                    handleClose={handleModalClose}
                    header={modelHeader}
                    body={modelToOpen}
                />
                <MainTable
                    iscontentLoading={isDiscountsLoading}
                    tableHeader={tableHeader} tableBody={tableBody}
                />
                <Pagination
                    reloadData={(pageNumber, pageSize) => loadDiscounts(pageNumber, pageSize)}
                    pagination={pagination}
                />
            </Card>
        </PageLayout>
    );
}