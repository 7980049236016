import { BFF_CONFIGS } from '../../config';
import { BFF_ENDPOINTS } from '../../utils/constants';
import { _get, _put, _post ,_delete } from '../fetch-api';

export const searchAssets = (
    callback,
    errorCallback,
    pageNumber,
    pageSize,
    status,
    keyword
) => {
  const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.assetSearch}`;
  const params = {
    pageNumber,
    pageSize,
    status,
    keyword,
    sortProperty: 'createdDate',
  };
  _get(callback, endpointURL, params, null, errorCallback);
};

export const createAsset = (callback, data, errCallBack) => {
  const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.assetCreate}`;
  _post(callback, endpointURL, data, {}, errCallBack);
};

export const deleteAssetById = (callback, id, errCallBack) => {
  const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.assetDelete}/${id}`;
  _delete(callback, endpointURL, errCallBack);
};

export const getAssetById = (callback, errorCallback, id) => {
  const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getAssetById}/${id}`;
  _get(callback, endpointURL, {}, null, errorCallback);
};

export const getNextUniqueId = (callback, errorCallback) => {
  const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getNextAssetUniqueId}`;
  _get(callback, endpointURL, {}, null, errorCallback);
};

export const getUserAuthoritySuggestions = callback => {
  const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getUserAuthoritySuggestions}`;
  _get(callback, endpointURL);
};

export const updateAsset = (callback, body, errCallBack) => {
  const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.updateAsset}`;
  _put(callback, endpointURL, body, errCallBack);
};
