import {Card} from "@mui/material";
import Typography from "@mui/material/Typography";

export const Stats = (props) => {

    return (
        <Card sx={{
            margin: '1%',
            height: '100%',
            background: '#f3f3f3',
            border: '1px solid ' +
                '#afa7a8',
            borderBottom: '5px solid ' +
                '#b6b2b2',
        }}>
            <Typography variant={"h6"} color={"#0a0a0a"}>{props.header}</Typography>
            <Typography variant={"subtitle1"} color={"#ffffff"}>{props.subheader}</Typography>
            <Typography variant={"h4"}
                        color={"#0d3f07"}>{props.value} {props.unit ? `(${props.unit})` : ""}</Typography>
            {props.children}
        </Card>
    );
}