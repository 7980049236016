import {
    AppBar,
    Box, Button,
    Grid,
    IconButton, ListItem, ListItemIcon, TextField,
    Toolbar,
    Typography
} from "@mui/material"
import { Cancel, Delete, Done, Edit } from "@mui/icons-material"
import { useEffect, useState } from "react"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import { FontOptionList } from "../common/fontOptionList"
import { MuiColorInput } from "mui-color-input"

export const CommonTextListFormattingForm = (props) => {

    const [collapse, setCollapse] = useState(false)

    const [listItems, setListItems] = useState(props.data.items)

    const [text, setText] = useState(props.data.items ? props.data.items.join("\n") : "")

    const [fontSize, setFontSize] = useState(props.data.fontSize ? props.data.fontSize : "1.2rem")
    const [color, setColor] = useState(props.data.color ? props.data.color : 'orange')
    const [font, setFont] = useState(props.data.font ? props.data.font : 'FMSamantha')

    const handleChangeFontSize = (event) => {
        setFontSize(event.target.value)
    }
    const handleChangeColor = (value) => {
        setColor(value)
    }
    const handleChangeFont = (event) => {
        setFont(event.target.value)
    }
    const handleChangeText = (event) => {
        setText(event.target.value)
    }
    useEffect(() => {
        const timer = setTimeout(() =>
            setListItems(text ? text.split("\n") : []),
            800)
        return () => clearTimeout(timer)
    }, [text])

    const handleOnModified = (isCancel) => {
        if (!isCancel) {
            const temp = {
                id: props.data.id,
                items: listItems,
                font: font,
                fontSize: fontSize,
                color: color,
                type: "TEXT_LIST",
            }
            props.handleOnFormSubmit(temp)
        }
        setCollapse(!collapse)
    }

    return (
        <Box>
            <Box>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    {!collapse && (
                        <>
                            {
                                listItems.map((item, lstIndex) => (
                                    <Grid item xs={8} sm={6} md={4} key={lstIndex}>
                                        <ListItem >
                                            <ListItemIcon sx={{
                                                    fontSize: fontSize,
                                                    color: color,
                                                }}
                                                >
                                                <CheckCircleOutlineIcon />
                                            </ListItemIcon>
                                            <Typography
                                                sx={{
                                                    fontSize: fontSize,
                                                }}
                                                color={color}
                                                fontFamily={font}
                                            >
                                                {item}
                                            </Typography>
                                        </ListItem>
                                    </Grid>
                                ))
                            }
                        </>

                    )}
                    {collapse && (
                            <TextField sx={{
                                '& .MuiOutlinedInput-input': { 
                                    fontFamily: font, 
                                    padding: '.5rem',
                                    fontSize: {fontSize},
                                    color: {color},
                                },
                                minWidth: '90%'
                            }}
                                multiline
                                rows={5}
                                required
                                id="height-input"
                                onChange={handleChangeText}
                                value={text}
                            />

                    )}
                </Grid>


                <IconButton
                    size="large"
                    color="red"
                    onClick={() => setCollapse(!collapse)}
                >
                    <Edit />
                </IconButton>
                <IconButton
                    size="large"
                    color="red"
                    onClick={props.handleOnDelete}
                >
                    <Delete />
                </IconButton>
            </Box>
            {collapse && (
                <AppBar position="fixed" color="inherit" sx={{
                    top: 'auto', bottom: 0, justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Toolbar>
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto' } }}
                            required
                            id="size-input"
                            label="Size"
                            onChange={handleChangeFontSize}
                            value={fontSize} size="small"
                            style={{ width: '100px' }}
                        />
                        <FontOptionList value={font} onChangeValue={handleChangeFont} />
                        <MuiColorInput
                            format="hex"
                            value={color}
                            onChange={handleChangeColor}
                            size="small"
                            style={{ width: '100px' }}
                        />
                        <Button
                            sx={{ m: 1 }}
                            size="medium"
                            variant="outlined"
                            onClick={() => handleOnModified(false)}
                            endIcon={<Done />}
                        >Done</Button>
                        <Button
                            color={'warning'}
                            sx={{ m: 1 }}
                            size="medium"
                            variant="outlined"
                            onClick={() => handleOnModified(true)}
                            endIcon={<Cancel />}
                        >Cancel</Button>
                    </Toolbar>
                </AppBar>
            )}
        </Box>
    )
}