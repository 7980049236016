import {useState, useEffect} from "react";

import {
    Box,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    FormControl,
    DialogContent,
    DialogContentText,
    DialogActions, IconButton, Autocomplete,
} from "@mui/material";
import {AddCircleOutlineOutlined, Cancel, Create, Update,} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";

import {
    createVoucher,
    getVoucherById,
    updateVoucher,
    getNextUniqueId,
} from "../../../../client/vouchers/call";
import {getUserSuggestions} from "../../../../client/users/call";
import {loadLocationsSuggestions} from "../../../../client/location/call";
import {loadFoundationsSuggestions} from "../../../../client/foundation/call";
import {getEventSuggestions} from "../../../../client/events/call";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import * as moment from "moment";
import Typography from "@mui/material/Typography";
import CreateEventForm from "../../events/createEventForm";
import MainModalDialog from "../../../ui/modal/mainModal";
import {toast} from "react-toastify";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {formatAmount} from "../../../../utils/app-utils";


// create voucher form
export default function CreateVoucherForm(props) {

    const [isLoading, setIsLoading] = useState(false);

    // create user inputs
    const [id, setId] = useState('');
    const [voucherId, setVoucherId] = useState('');
    const [amount, setAmount] = useState('');
    const [description, setDescription] = useState('');
    const [location, setLocation] = useState({});
    const [foundation, setFoundation] = useState({});
    const [event, setEvent] = useState({});
    const [approvedBy, setApprovedBy] = useState({});
    const [issuedFor, setIssuedFor] = useState('');
    const [issuedBy, setIssuedBy] = useState({});
    const [issuedDate, setIssuedDate] = useState(null);

    const [modalOpen, setModalOpen] = useState(false);
    const [modelToOpen, setModelToOpen] = useState('');
    const [modelHeader, setModelHeader] = useState('');

    const [userSuggestions, setUserSuggestions] = useState(['']);
    const [locationSuggestions, setLocationSuggestions] = useState(['']);
    const [foundationSuggestions, setFoundationSuggestions] = useState(['']);
    const [eventSuggestions, setEventSuggestions] = useState(['']);

    useEffect(() => {
        loadUserSuggestions()
        loadLocationSuggestionsFunc()
        loadFoundationSuggestionsFunc()
        loadEventSuggestionsFunc()
        if (props.isUpdate) {
            setIsLoading(true)
            getVoucherById(
                result => {
                    setId(result.content.id)
                    setVoucherId(result.content.voucherId)
                    setAmount(result.content.amount)
                    setDescription(result.content.description)
                    setLocation(result.content.monastery)
                    setFoundation(result.content.foundation)
                    setEvent(result.content.event)
                    setApprovedBy(result.content.approvedBy)
                    setIssuedFor(result.content.issuedFor)
                    setIssuedBy(result.content.issuedBy)
                    setIssuedDate(result.content.issuedDate)
                    setStatus(result.content.status)

                    setIsLoading(false)
                },
                error => {
                },
                props.voucherId,
            );
        } else {
            getNextUniqueId((data) => {
                setVoucherId(data.content)
            }, () => {
            })
        }
    }, [])

    function loadUserSuggestions() {
        getUserSuggestions(result => {
            setUserSuggestions(result.content);
        });
    }

    function loadLocationSuggestionsFunc() {
        loadLocationsSuggestions(result => {
            setLocationSuggestions(result.content);
        });
    }

    function loadFoundationSuggestionsFunc() {
        loadFoundationsSuggestions(result => {
            setFoundationSuggestions(result.content);
        });
    }

    function loadEventSuggestionsFunc() {
        getEventSuggestions(result => {
            setEventSuggestions(result.content);
        });
    }

    const [status, setStatus] = useState('');

    //--------------------------------------------------
    const handleChangeAmount = (event) => {
        const sanitizedValue = event.target.value.replace(/,/g, '');
        setAmount(sanitizedValue);
    }
    const handleChangeDescription = (event) => {
        setDescription(event.target.value);
    };

    const handleChangeLocation = (event) => {
        setLocation(locationSuggestions.find((mnstry) => mnstry.id === event.target.value));
    };

    const handleChangeEvent = (event, newValue) => {
        setEvent(newValue);
    };
    const handleChangeFoundation = (event) => {
        setFoundation(foundationSuggestions.find((fnd) => fnd.id === event.target.value));
    };
    const handleChangeApprovedBy = (event, newValue) => {
        setApprovedBy(newValue);
    };
    const handleChangeIssuedFor = (event) => {
        setIssuedFor(event.target.value);
    };
    const handleChangeIssuedBy = (event, newValue) => {
        setIssuedBy(newValue);
    };
    const handleChangeIssuedDate = (value) => {
        let val = null
        if (value) {
            val = value.$d
            setIssuedDate(moment(val).format('YYYY-MM-DD'));
        } else {
            setIssuedDate(null)
        }
        validateIssuedDate(val)
    };

    // validate user inputs
    const [amountValidity, setAmountValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateAmount = () => {
        let validity = {};
        if (!amount || amount === '') {
            validity.isValid = false;
            validity.errorMessage = "Amount cannot be empty"
            setAmountValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setAmountValidity(validity)
            return true
        }
    };

    const [descriptionValidity, setDescriptionValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateDescription = () => {
        let validity = {};
        if (!description || description === '') {
            validity.isValid = false;
            validity.errorMessage = "Description cannot be empty"
            setDescriptionValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setDescriptionValidity(validity)
            return true
        }
    };

    const [approvedByValidity, setApprovedByValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateApprovedBy = () => {
        let validity = {};
        if (!approvedBy || (approvedBy && !approvedBy.id)) {
            validity.isValid = false;
            validity.errorMessage = "Approved by user cannot be empty"
            setApprovedByValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setApprovedByValidity(validity)
            return true
        }
    };

    const [issuedForValidity, setIssuedForValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateIssuedFor = () => {
        let validity = {};
        if (!issuedFor || issuedFor === '') {
            validity.isValid = false;
            validity.errorMessage = "Issued for user cannot be empty"
            setIssuedForValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setIssuedForValidity(validity)
            return true
        }
    };

    const [issuedByValidity, setIssuedByValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateIssuedBy = () => {
        let validity = {};
        if (!issuedBy || (issuedBy && !issuedBy.id)) {
            validity.isValid = false;
            validity.errorMessage = "Issued by user cannot be empty"
            setIssuedByValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setIssuedByValidity(validity)
            return true
        }
    };

    const [issuedDateValidity, setIssuedDateValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateIssuedDate = () => {
        let validity = {};
        if (!issuedDate || issuedDate === '') {
            validity.isValid = false;
            validity.errorMessage = "Issued date cannot be empty"
            setIssuedDateValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setIssuedDateValidity(validity)
            return true
        }
    };

    const setInputsToDefaultOnFormSubmit = () => {
        setId('')
        setVoucherId('')
        setAmount('')
        setDescription('')
        setLocation({})
        setFoundation({})
        setEvent({})
        setApprovedBy({})
        setIssuedFor('')
        setIssuedBy({})
        setIssuedDate(null)
        setStatus('')
    }

    const validateInputFields = () => {
        let formValidators = []
        let isFormValid = true
        if (!validateAmount()) {
            formValidators[0] = false
        }
        if (!validateDescription()) {
            formValidators[1] = false
        }
        if (!validateApprovedBy()) {
            formValidators[2] = false
        }
        if (!validateIssuedBy()) {
            formValidators[3] = false
        }
        if (!validateIssuedFor()) {
            formValidators[3] = false
        }
        if (!validateIssuedDate()) {
            formValidators[4] = false
        }
        formValidators.map((val) => {
            if (!val) {
                isFormValid = false
            }
        })
        return isFormValid;
    }

    const createVoucherFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        let voucher = {
            voucherId: voucherId,
            amount: amount,
            description: description,
            monasteryId: location ? location.id : "",
            foundationId: foundation ? foundation.id : "",
            eventId: event ? event.id : "",
            approvedBy: approvedBy.id,
            issuedFor: issuedFor,
            issuedBy: issuedBy.id,
            issuedDate: issuedDate,
        };

        createVoucher(
            (result) => {
                toast(`Voucher: ${voucher.voucherId} was created!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.createOrUpdatecallback(result.content);
            },
            voucher,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    const updateVoucherFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        let voucher = {
            id: id,
            voucherId: voucherId,
            amount: amount,
            description: description,
            monasteryId: location ? location.id : "",
            foundationId: foundation ? foundation.id : "",
            eventId: event ? event.id : "",
            approvedBy: approvedBy.id,
            issuedFor: issuedFor,
            issuedBy: issuedBy.id,
            issuedDate: issuedDate,
            status: status
        };

        updateVoucher(
            (result) => {
                toast(`Voucher: ${voucher.voucherId} was updated!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.createOrUpdatecallback(result.content);
            },
            voucher,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    const handleModalOpen = (model) => {
        if (model === 'createEvent') {
            setModelHeader("Create Project/Event")
            setModelToOpen(createEventForm)
        }
        setModalOpen(true);
    }
    const handleModalClose = () => {
        setModalOpen(false);
    }

    const createEventForm = (<CreateEventForm
        isUpdate={false}
        handleClose={handleModalClose}
        eventId={null}
        createOrUpdatecallback={(evnt) => {
            let temp = [...eventSuggestions]
            temp.push(evnt)
            setEventSuggestions(temp)
            handleModalClose()
            setEvent(evnt)
        }}
    />);

    return (<Box
            component="form"
            sx={{
                overflow: 'scroll',
                '& .MuiTextField-root': {m: 1, width: '27ch'},
            }}
            noValidate
            autoComplete="off"
        >
            <MainModalDialog
                size={'sm'}
                open={modalOpen}
                handleClose={handleModalClose}
                header={modelHeader}
                body={modelToOpen}
            />
            <DialogContent dividers={true} sx={{overflow: "visible"}}>
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                >
                </DialogContentText>
                <div>
                    <Typography sx={{marginLeft: "15px", color: "green"}} variant="h6" component="div">
                        Voucher Number: <span style={{color: "red"}}>{voucherId}</span>
                    </Typography>
                </div>
                <div>
                    <TextField
                        required
                        id="amount-input"
                        label="Amount"
                        onChange={handleChangeAmount}
                        value={formatAmount(amount)}
                        error={!amountValidity.isValid}
                        onBlur={validateAmount}
                        helperText={(amountValidity.isValid) ? "Enter amount here" : amountValidity.errorMessage}
                    />
                    <TextField
                        required
                        id="description-input"
                        label="Description"
                        onChange={handleChangeDescription}
                        value={description}
                        error={!descriptionValidity.isValid}
                        onBlur={validateDescription}
                        helperText={(descriptionValidity.isValid) ? "Enter description here" : descriptionValidity.errorMessage}
                    />
                    <FormControl sx={{m: 1, minWidth: '27ch'}}>
                        <InputLabel id="location-label">Location</InputLabel>
                        <Select
                            labelId="location-label"
                            id="location-input"
                            defaultValue=""
                            value={location ? location.id ? location.id : '' : ""}
                            onChange={handleChangeLocation}
                            autoWidth
                            label="Location"
                        >
                            <MenuItem key={"none"} value="">
                                <em>None</em>
                            </MenuItem>
                            {(locationSuggestions) && locationSuggestions.map((location, index) => (
                                <MenuItem key={index} value={location.id}>{location.nameUnicode}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{"Select a location"}</FormHelperText>
                    </FormControl>
                </div>
                <div>
                    <FormControl sx={{m: 1, minWidth: '27ch'}}>
                        <InputLabel id="foundation-label">Foundation</InputLabel>
                        <Select
                            labelId="foundation-label"
                            id="foundation-input"
                            defaultValue=""
                            value={foundation ? foundation.id ? foundation.id : '' : ""}
                            onChange={handleChangeFoundation}
                            autoWidth
                            label="Foundation"
                        >
                            <MenuItem key={"none"} value="">
                                <em>None</em>
                            </MenuItem>
                            {(foundationSuggestions) && foundationSuggestions.map((foundation, index) => (
                                <MenuItem key={index} value={foundation.id}>{foundation.nameUnicode}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{"Select a foundation"}</FormHelperText>
                    </FormControl>
                    <FormControl sx={{minWidth: '27ch', m: 0,}}>
                        <Autocomplete
                            id="issued-by-input"
                            value={issuedBy}
                            onChange={handleChangeIssuedBy}
                            getOptionLabel={(option) => `${(option.firstName)? option.firstName : ""} ${(option.lastName) ? option.lastName : ""}` }
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            disablePortal
                            options={userSuggestions}
                            sx={{m:0, overflow: 'visible'}}
                            renderInput={(params) =>
                                <TextField {...params}
                                           label="IssuedBy"
                                           sx={{m:0, minWidth: '27ch'}}
                                           onBlur={validateIssuedBy}
                                           error={!issuedByValidity.isValid}
                                           helperText={issuedByValidity.isValid ? "Select the issuedBy" : "IssuedBy must not be empty"}
                                />}
                        />
                    </FormControl>
                    <TextField
                        required
                        labelId="issued-for-label"
                        id="issued-for-input"
                        defaultValue=""
                        value={issuedFor}
                        onChange={handleChangeIssuedFor}
                        error={!issuedForValidity.isValid}
                        onBlur={validateIssuedFor}
                        autoWidth
                        label="Issued For"
                        helperText={(issuedForValidity.isValid) ? "Enter description here" : issuedForValidity.errorMessage}
                    />
                </div>
                <div>
                    <FormControl sx={{minWidth: '27ch', m: 0}}>
                        <Autocomplete
                            id="approved-by-input"
                            value={approvedBy}
                            onChange={handleChangeApprovedBy}
                            getOptionLabel={(option) => `${(option.firstName)? option.firstName : ""} ${(option.lastName) ? option.lastName : ""}`}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            disablePortal
                            options={userSuggestions}
                            sx={{m:0, overflow: 'visible'}}
                            renderInput={(params) =>
                                <TextField {...params}
                                           label="ApprovedBy"
                                           sx={{m:0, minWidth: '27ch'}}
                                           onBlur={validateApprovedBy}
                                           error={!approvedByValidity.isValid}
                                           helperText={approvedByValidity.isValid ? "Select the approvedBy" : "ApprovedBy must not be empty"}
                                />}
                        />
                    </FormControl>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            type='date'
                            id="issued-date-input"
                            label="Issued Date"
                            inputFormat="YYYY-MM-DD"
                            value={issuedDate}
                            onChange={handleChangeIssuedDate}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    helperText={(issuedDateValidity.isValid) ? "Enter issued date here" : issuedDateValidity.errorMessage}
                                    error={!issuedDateValidity.isValid}
                                    onBlur={validateIssuedDate}
                                />
                            }

                        />
                    </LocalizationProvider>
                </div>
                <div>
                    <FormControl sx={{minWidth: '27ch', m: 0}}>
                        <Autocomplete
                            id="event-input"
                            value={event}
                            onChange={handleChangeEvent}
                            getOptionLabel={(option) => option.title? option.title : ""}
                            isOptionEqualToValue={(option, value) => option.title === value.title}
                            disablePortal
                            autoHighlight
                            options={eventSuggestions}
                            sx={{m:0, overflow: 'visible'}}
                            renderInput={(params) =>
                                <TextField {...params}
                                           label="Event"
                                           sx={{m:0, minWidth: '27ch'}}
                                           helperText={"Select an event"}
                                />}
                        />
                    </FormControl>
                    <IconButton
                        aria-label="expand row"
                        size="large"
                        sx={{ marginTop: 1, marginRight: 2, marginLeft: 0 }}
                        onClick={() => {
                            handleModalOpen("createEvent")
                        }}
                    >
                        <AddCircleOutlineOutlined fontSize={"large"} />
                    </IconButton>
                </div>
            </DialogContent>
            <DialogActions>
                {props.isUpdate ? (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={updateVoucherFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Update/>}
                >Update</LoadingButton>) : (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={createVoucherFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Create/>}
                >Create</LoadingButton>)}
                <LoadingButton
                    color={'warning'}
                    size="medium"
                    variant="outlined"
                    onClick={props.handleClose}
                    loading={false}
                    loadingPosition="end"
                    endIcon={<Cancel/>}
                >Cancel</LoadingButton>
            </DialogActions>
        </Box>
    )
}