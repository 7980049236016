import {
    Box,
    Collapse, FormControl, FormHelperText,
    IconButton, InputLabel, MenuItem, Select, TextField, Typography,
} from "@mui/material";
import { Close, Delete, Edit } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { FilesUploadForm } from "../common/filesUploadForm";
import { FILE_PATH_CONST } from "../../../utils/constants";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

export const WrapImageUploadForm = (props) => {

    const [collapse, setCollapse] = useState(false);

    const [image, setImage] = useState(props.data ? props.data.image : "");
    const [size, setSize] = useState(props.data ? props.data.size : "30%");
    const [position, setPosition] = useState(props.data ? props.data.position : "right");


    const handleChangePosition = (event) => {
        setPosition(event.target.value);
    };

    const handleChangeSize = (event) => {
        setSize(event.target.value);
    };

    const onUploadSuccess = (data) => {
        setImage(data.file)
    }

    const handleOnDelete = () => {
        props.handleOnChange(null)
    }
    useEffect(() => {
        const timer = setTimeout(() => {
            const temp = {
                image: image,
                size: size,
                position: position,
            }
            props.handleOnChange(temp)
        },
            400);
        return () => clearTimeout(timer);

    }, [image, size, position])

    return (
        <Box
            sx={{
                margin: '0 3px 0 3px',
                padding: '3px',
                border: '1px solid grey',
                borderRadius: "1rem",
            }}>
            <Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: position,
                        alignItems: position,
                    }}
                >
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <IconButton
                        size="large"
                        color="red"
                        onClick={() => setCollapse(!collapse)}
                    >
                        {collapse && (
                            <Close />
                        )}
                        {!collapse && (
                            <AddPhotoAlternateIcon />
                        )}
                    </IconButton>
                    <IconButton
                        size="large"
                        color="red"
                        onClick={handleOnDelete}
                    >
                        <Delete />
                    </IconButton>
                </Box>
            </Box>
            <Collapse in={collapse} timeout="auto" unmountOnExit>
                <Box
                    component="form"
                    sx={{
                        display: 'flex',
                        '& .MuiTextField-root': { m: 1, width: '27ch', },
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div>
                        <FilesUploadForm
                            containerId={props.containerId}
                            path={`${FILE_PATH_CONST.images}/${props.containerId}`}
                            handleOnUploadSuccess={onUploadSuccess}
                        />
                        <FormControl sx={{ m: 1, minWidth: '10ch', marginRight: 0 }}>
                            <InputLabel id="position-label"> Position</InputLabel>
                            <Select
                                labelId="font-label"
                                id="position-input"
                                defaultValue=""
                                onChange={handleChangePosition}
                                value={position}
                                autoWidth
                                label="Position"
                                size="small"
                            >
                                <MenuItem value={'left'}>Left</MenuItem>
                                <MenuItem value={'right'}>Right</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto' } }}
                            id="size-input"
                            label="Size"
                            onChange={handleChangeSize}
                            value={size}
                            style={{ width: '100px' }}
                            size="small"
                        />
                    </div>
                </Box>
            </Collapse>
        </Box>
    )
}