export const themeOptions = {
    palette: {
        type: 'light',
        primary: {
          main: '#004d40',
        },
        secondary: {
          main: '#c2185b',
        },
        background: {
          default: '#f5f5f5',
          paper: '#fafafa',
        },
      },
    typography: {
        allVariants: {
            //fontFamily: 'Ubuntu , \'sans-serif\'',
            //fontFamily: 'Merriweather , \'serif\'',
        }
    },
    shape: {
        borderRadius: 10,
    },
    spacing: 8,
    props: {
        MuiTooltip: {
            arrow: true,
        },
        MuiAppBar: {
            color: 'inherit',
        },
    },
    overrides: {
        MuiAppBar: {
            colorInherit: {
                backgroundColor: '#689f38',
                color: '#fff',
            },
        },
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    borderRadius: 25,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    borderRadius: 25,
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                // Name of the slot
                root: {fontWeight: "500",fontFamily: "Roboto"},
            },
        }
    }
};