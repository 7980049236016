import { generateUUID } from "../app-utils"

export const textTmeplates = [
    {
        name: "Header 1",
        color: "grey",
        lineHeight: "normal",
        fontSize: "6rem",
        font: "FMBasuru",
        textAlign: "center",
        float: "center",
        letterSpacing: "normal",
        wordSpacing: "normal",
        backgroundColor: "white",
        borderRadius: "5px",
        margin: "5px",
        padding: "5px",
        border: "1px solid black",
        fontStyle: "normal",
    },
    {
        name: "Header 2",
        color: "grey",
        lineHeight: "normal",
        fontSize: "5rem",
        font: "FMBasuru",
        textAlign: "center",
        float: "center",
        letterSpacing: "normal",
        wordSpacing: "normal",
        backgroundColor: "white",
        borderRadius: "5px",
        margin: "5px",
        padding: "5px",
        border: "1px solid black",
        fontStyle: "normal",
    },
    {
        name: "Header 3",
        color: "grey",
        lineHeight: "normal",
        fontSize: "4rem",
        font: "FMBasuru",
        textAlign: "center",
        float: "center",
        letterSpacing: "normal",
        wordSpacing: "normal",
        backgroundColor: "white",
        borderRadius: "5px",
        margin: "5px",
        padding: "5px",
        border: "1px solid black",
        fontStyle: "normal",
    },
    {
        name: "Header 4",
        color: "grey",
        lineHeight: "normal",
        fontSize: "3rem",
        font: "FMBasuru",
        textAlign: "center",
        float: "center",
        letterSpacing: "normal",
        wordSpacing: "normal",
        backgroundColor: "white",
        borderRadius: "5px",
        margin: "5px",
        padding: "5px",
        border: "1px solid black",
        fontStyle: "normal",
    },
    {
        name: "Header 5",
        color: "grey",
        lineHeight: "normal",
        fontSize: "2rem",
        font: "FMBasuru",
        textAlign: "center",
        float: "center",
        letterSpacing: "normal",
        wordSpacing: "normal",
        backgroundColor: "white",
        borderRadius: "5px",
        margin: "5px",
        padding: "5px",
        border: "1px solid black",
        fontStyle: "normal",
    },
    {
        name: "Header 6",
        color: "grey",
        lineHeight: "normal",
        fontSize: "1rem",
        font: "FMBasuru",
        textAlign: "center",
        float: "center",
        letterSpacing: "normal",
        wordSpacing: "normal",
        backgroundColor: "white",
        borderRadius: "5px",
        margin: "5px",
        padding: "5px",
        border: "1px solid black",
        fontStyle: "normal",
    },
    {
        name: "Paragraph 1",
        color: "black",
        lineHeight: "normal",
        fontSize: "1rem",
        font: "FMMalithi",
        textAlign: "justified",
        float: "left",
        letterSpacing: "normal",
        textIndent: "50px",
        wordSpacing: "normal",
        backgroundColor: "white",
        borderRadius: "5px",
        margin: "5px",
        padding: "5px",
        border: "1px solid black",
        fontStyle: "normal",
    },
]

export const imageGridTmeplates = [
    {
        name: "Grid 1",
        caption: {
            color: "grey",
            lineHeight: "normal",
            fontSize: "1rem",
            font: "FMMalithi",
            textAllignment: "center",
            float: "center",
            letterSpacing: "normal",
        },
        backgroundColor: "white",
        borderRadius: "5px",
        gap: "5",
        padding: "5px",
        border: "1px solid black",
        boxShadow: "0px 2px 10px rgba(0, 62, 200, 0.5)",
        columns: "3",
        justifyContent: "center",
        alignItems: "center",
    },
]

export const textLitsTmeplates = [
    {
        name: "List 1",
        color: "blacck",
        lineHeight: "normal",
        fontSize: "1rem",
        font: "FMMalithi",
        textAlign: "center",
        float: "center",
        letterSpacing: "normal",
        wordSpacing: "normal",
        backgroundColor: "white",
        borderRadius: "5px",
        margin: "5px",
        padding: "5px",
        border: "1px solid black",
        fontStyle: "normal",
    },
]

export const buttonTmeplates = [
    {
        name: "Outlined 1",
        font: "FMMalithi",
        position: "center",
        borderRadius: "inherit",
        margin: "5px",
        padding: "5px",
        variant: "",
        size: "small",
    },
]

export const cardTmeplates = [
    {
        name: "Card 1",
        media: {},
        header: {
            color: "black",
            fontSize: "1rem",
            font: "FMMalithi",
        },
        description: {
            color: "grey",
            fontSize: "0.8rem",
            font: "FMMalithi",
        },
        margin: "5px",
        padding: "5px",
        border: "1px solid black",
        boxShadow: "0px 2px 10px rgba(0, 62, 200, 0.5)",
    },
]