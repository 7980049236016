import PageLayout from "../../../../ui/layout/pageLayout";
import {ExpensesByLocation} from "./ExpensesByLocation";

export default function Expenses() {

    return (
        <PageLayout>
            <ExpensesByLocation/>
        </PageLayout>
    );
}