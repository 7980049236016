import {BFF_CONFIGS} from '../../config';
import {BFF_ENDPOINTS} from '../../utils/constants';
import {_get, _get_an, _put, _post, _delete, _downloadFile, _post_open} from '../fetch-api';

export const searchDeshana = (
    callback,
    pageNumber,
    pageSize,
    status,
    keyword
) => {
    const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.deshanaSearch}`;
    const params = {
        pageNumber,
        pageSize,
        status,
        keyword,
        sortProperty: 'name',
    };
    _get(callback, endpointURL, params);
};

export const deshanaSearchByWebLinkUsers = (
    callback,
    pageNumber,
    pageSize,
    status,
    keyword
) => {
    const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.deshanaSearchByWebLinkUsers}`;
    const params = {
        pageNumber,
        pageSize,
        status,
        keyword,
        sortProperty: 'name',
    };
    _get_an(callback, endpointURL, params);
};

export const createDirectory = (callback, data, errCallBack) => {
    const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.directoryCreate}`;
    _post(callback, endpointURL, data, {}, errCallBack);
};

export const createWebLink = (callback, data, directoryId, errCallBack) => {
    const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.createOrUpdateWebLink.replace("{id}", directoryId)}`;
    _put(callback, endpointURL, data, errCallBack);
};

export const directoryUpdate = (callback, body, errCallBack) => {
    const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.directoryUpdate}`;
    _put(callback, endpointURL, body, errCallBack);
};

export const deleteDeshana = (callback, id, errCallBack) => {
    const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.deshanaDelete}/${id}`;
    _delete(callback, endpointURL, errCallBack);
};

export const uploadDeshana = (callback, file, onUploadingProgress, parentId, errCallback) => {
    const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.uploadDeshana}`;
    const bodyFormData = new FormData();
    bodyFormData.set('parentId', parentId);
    bodyFormData.append('file', file, file.fileName);
    _post(
        callback,
        endpointURL,
        bodyFormData,
        {
            headers: {'Content-Type': 'multipart/form-data'},
            onUploadProgress: (progressEvent) => {
                onUploadingProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            }
        },
        errCallback,
    );
};

export const downloadDeshana = (callback, id, onDownloadProgress, errorCallback) => {
    const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.downloadDeshana}/${id}`;
    _downloadFile(callback, endpointURL, {}, 'blob', errorCallback, onDownloadProgress);
};

export const loadDirectoryById = (callback, id) => {
    const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.loadDirectoryById}/${id}`;
    _get(callback, endpointURL, {},);
};

export const findDeshanaIdByWebLink = (callback, payload, errorCallback) => {
    const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.findDeshanaIdByWebLink}`;
    _post_open(callback, endpointURL, payload, null, errorCallback);
};