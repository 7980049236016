import {
    AppBar,
    Box, Button,
    Collapse, FormControl,
    IconButton, InputLabel, MenuItem, Select, TextField,
    Toolbar,
    Typography
} from "@mui/material"
import { Cancel, Delete, Done, Edit } from "@mui/icons-material"
import { useState, useEffect, useRef } from "react"
import { WrapImageUploadForm } from "./wrapImageUploadForm"
import { FILES_BASE_URL } from "../../../utils/constants"
import { FontOptionList } from "../common/fontOptionList"
import { MuiColorInput } from "mui-color-input"
import { deleteFile } from "../../../client/fileUpload"
import useDidUpdateEffect from "../../../hooks/commonHooks"

export const CommonTextFormattingForm = (props) => {

    const [collapse, setCollapse] = useState(false)

    const [text, setText] = useState(props.data.text)
    const [color, setColor] = useState(props.data.color)

    const [lineHeight, setLineHeight] = useState(props.data.lineHeight)
    const [fontSize, setFontSize] = useState(props.data.fontSize)
    const [font, setFont] = useState(props.data.font)
    const [letterSpacing, setLetterSpacing] = useState(props.data.letterSpacing)
    const [backgroundColor, setBackgroundColor] = useState(props.data.backgroundColor)
    const [borderRadius, setBorderRadius] = useState(props.data.borderRadius)
    const [float, setFloat] = useState(props.data.float)
    const [wordSpacing, setWordSpacing] = useState(props.data.wordSpacing)
    const [margin, setMargin] = useState(props.data.margin)
    const [padding, setPadding] = useState(props.data.padding)
    const [border, setBorder] = useState(props.data.border)
    const [fontStyle, setFontStyle] = useState(props.data.fontStyle)

    const [textAlign, setTextAlign] = useState(props.data.textAlign)
    const [wrapImage, setWrapImage] = useState(props.data.wrapImage)

    const handleChangeFontSize = (event) => {
        setFontSize(event.target.value)
    }
    const handleChangeFont = (event) => {
        setFont(event.target.value)
    }
    const handleChangeFontStyle = (event) => {
        setFontStyle(event.target.value)
    }
    const handleChangeLineHeight = (event) => {
        setLineHeight(event.target.value)
    }
    const handleChangeTextAlign = (event) => {
        setTextAlign(event.target.value)
    }
    const handleChangeFloat = (event) => {
        setFloat(event.target.value)
    }
    const handleChangeLetterSpacing = (event) => {
        setLetterSpacing(event.target.value)
    }
    const handleChangeWordSpacing = (event) => {
        setWordSpacing(event.target.value)
    }
    const handleChangeColor = (value) => {
        setColor(value)
    }
    const handleChangeBackgroundColor = (value) => {
        setBackgroundColor(value)
    }
    const handleChangeBorder = (event) => {
        setBorder(event.target.value)
    }
    const handleChangeBorderRadius = (event) => {
        setBorderRadius(event.target.value)
    }
    const handleChangeMargin = (event) => {
        setMargin(event.target.value)
    }
    const handleChangePadding = (event) => {
        setPadding(event.target.value)
    }
    const handleChangeText = (event) => {
        setText(event.target.value)
    }
    const handleOnModifiedWrapImage = (data) => {
        setWrapImage(data)
    }
    const handleOnModified = (isCancel) => {
        if (!isCancel) {
            const temp = {
                id: props.data.id,
                text: text,
                fontSize: fontSize,
                color: color,
                lineHeight: lineHeight,
                font: font,
                textAlign: textAlign,
                float: float,
                letterSpacing: letterSpacing,
                wordSpacing: wordSpacing,
                backgroundColor: backgroundColor,
                borderRadius: borderRadius,
                margin: margin,
                padding: padding,
                border: border,
                fontStyle: fontStyle,
                wrapImage: wrapImage,
                type: props.data.type,
            }
            props.handleOnFormSubmit(temp)
        }
        setCollapse(!collapse)
    }

    useDidUpdateEffect(() => {
        const temp = {
            id: props.data.id,
            text: text,
            fontSize: fontSize,
            color: color,
            lineHeight: lineHeight,
            font: font,
            textAlign: textAlign,
            float: float,
            letterSpacing: letterSpacing,
            wordSpacing: wordSpacing,
            backgroundColor: backgroundColor,
            borderRadius: borderRadius,
            margin: margin,
            padding: padding,
            border: border,
            fontStyle: fontStyle,
            wrapImage: wrapImage,
            type: props.data.type,
        }
        props.handleOnFormSubmit(temp)
    }, [wrapImage])

    const deleteImage = (path) => {
        deleteFile(() => {
            setWrapImage(null);
        },
            path,
            (error) => {
                console.log("file wasn't deleted")
            }
        )
        setWrapImage(null);
    }

    return (
        <Box>
            <Box sx={{ display: { md: 'flex', justifyContent: float, marginTop: '1rem' } }}>
                {!collapse && (
                    <Typography
                        sx={{
                            fontSize: fontSize,
                            fontFamily: font,
                            fontStyle: fontStyle,
                            lineHeight: lineHeight,
                            textAlign: textAlign,
                            float: float,
                            letterSpacing: letterSpacing,
                            wordSpacing: wordSpacing,
                            color: color,
                            backgroundColor: backgroundColor,
                            border: border,
                            borderRadius: borderRadius,
                            margin: margin,
                            padding: padding,
                        }}
                    >
                        {text}
                    </Typography>
                )}
                {collapse && (
                    <textarea
                        style={{
                            fontSize: fontSize,
                            fontFamily: font,
                            fontStyle: fontStyle,
                            lineHeight: lineHeight,
                            textAlign: textAlign,
                            float: float,
                            letterSpacing: letterSpacing,
                            wordSpacing: wordSpacing,
                            color: color,
                            backgroundColor: backgroundColor,
                            border: border,
                            borderRadius: borderRadius,
                            margin: margin,
                            padding: padding,
                            width: '100%'
                        }}
                        type="text"
                        multiple
                        onChange={handleChangeText}
                        value={text}
                    />
                )}
                {wrapImage && (
                    <>
                        <Box
                            component="img"
                            sx={{
                                marginLeft: '1rem',
                                width: wrapImage.size,
                                height: wrapImage.size,
                                shapeOutside: `${FILES_BASE_URL}${wrapImage.image}`,
                            }}
                            src={`${FILES_BASE_URL}${wrapImage.image}`}
                        />
                        <IconButton
                            sx={{ margin: 'auto auto auto 3px' }}
                            onClick={() => deleteImage(wrapImage.image)}
                        >
                            <Delete />
                        </IconButton>
                    </>
                )}

            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <IconButton
                    size="large"
                    color="red"
                    onClick={() => setCollapse(!collapse)}
                >
                    <Edit />
                </IconButton>
                <IconButton
                    size="large"
                    color="red"
                    onClick={props.handleOnDelete}
                >
                    <Delete />
                </IconButton>
            </Box>
            {collapse && (
                <AppBar position="fixed" color="inherit" sx={{
                    top: 'auto', bottom: 0, justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Toolbar>
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            id="font-size-input"
                            label="Size"
                            onChange={handleChangeFontSize}
                            value={fontSize}
                            style={{ width: '80px' }}
                            size="small"
                        />
                        <FontOptionList
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            id="font-size-input"
                            label="Font"
                            value={font}
                            onChangeValue={handleChangeFont}
                            style={{ width: '80px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Style"
                            id="font-style-input"
                            value={fontStyle}
                            onChange={handleChangeFontStyle}
                            size="small"
                            style={{ width: '80px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Height"
                            id="line-height-input"
                            value={lineHeight}
                            onChange={handleChangeLineHeight}
                            size="small"
                            style={{ width: '80px' }}
                        />
                        <FormControl sx={{ m: 1, minWidth: '10ch', marginRight: 0 }}>
                            <InputLabel id="align-label"> Text align</InputLabel>
                            <Select
                                labelId="align-label"
                                id="align-input"
                                defaultValue=""
                                onChange={handleChangeTextAlign}
                                value={textAlign ? textAlign : ""}
                                autoWidth
                                label="Align"
                                size="small"
                            >
                                <MenuItem value={'center'}>Center </MenuItem>
                                <MenuItem value={'left'}>Left</MenuItem>
                                <MenuItem value={'right'}>Right</MenuItem>
                                <MenuItem value={'justify'}>Justified</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: '10ch', marginRight: 0 }}>
                            <InputLabel id="position-label"> Position</InputLabel>
                            <Select
                                labelId="font-label"
                                id="postion-input"
                                defaultValue=""
                                onChange={handleChangeFloat}
                                value={float}
                                autoWidth
                                label="Position"
                                size="small"
                            >
                                <MenuItem value={'center'}>Center </MenuItem>
                                <MenuItem value={'left'}>Left</MenuItem>
                                <MenuItem value={'right'}>Right</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Letter Spacing"
                            id="letter-spacing-input"
                            value={letterSpacing}
                            onChange={handleChangeLetterSpacing}
                            size="small"
                            style={{ width: '80px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Word Spacing"
                            id="word-spacing-input"
                            value={wordSpacing}
                            onChange={handleChangeWordSpacing}
                            size="small"
                            style={{ width: '80px' }}
                        />
                        <MuiColorInput
                            format="hex"
                            value={backgroundColor}
                            onChange={handleChangeBackgroundColor}
                            size="small"
                            style={{ width: '100px' }}
                        />
                        <MuiColorInput
                            format="hex"
                            value={color}
                            onChange={handleChangeColor}
                            size="small"
                            style={{ width: '100px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Border"
                            id="border-input"
                            value={border}
                            onChange={handleChangeBorder}
                            size="small"
                            style={{ width: '100px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Border Radius"
                            id="border-radius-input"
                            value={borderRadius}
                            onChange={handleChangeBorderRadius}
                            size="small"
                            style={{ width: '80px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Margin"
                            id="margin-input"
                            value={margin}
                            onChange={handleChangeMargin}
                            size="small"
                            style={{ width: '80px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Padding"
                            id="padding-input"
                            value={padding}
                            onChange={handleChangePadding}
                            size="small"
                            style={{ width: '80px' }}
                        />
                        <WrapImageUploadForm
                            containerId={props.containerId}
                            data={wrapImage}
                            handleOnChange={(data) => {
                                handleOnModifiedWrapImage(data)
                            }}
                        />
                        <Button
                            sx={{ m: 1 }}
                            size="medium"
                            variant="outlined"
                            onClick={() => handleOnModified(false)}
                            endIcon={<Done />}
                        >Done</Button>
                        <Button
                            color={'warning'}
                            sx={{ m: 1 }}
                            size="medium"
                            variant="outlined"
                            onClick={() => handleOnModified(true)}
                            endIcon={<Cancel />}
                        >Cancel</Button>
                    </Toolbar>
                </AppBar>
            )}
        </Box>
    )
}