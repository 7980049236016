import {useState, useEffect} from "react";

import {
    Box,
    TextField,
    DialogContent,
    DialogContentText,
    DialogActions,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    FormControl,
    IconButton,
    List,
    ListSubheader,
    ListItem,
    ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction,
} from "@mui/material";
import {AddCircleOutlineOutlined, Cancel, Create, Delete, LocalOfferOutlined, Update,} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";

import {
    createEmployee,
    getEmployeeById,
    updateEmployee
} from "../../../client/employees/call";
import {toast} from "react-toastify";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";


// create employee form
export default function CreateEmployeeForm(props) {

    const [isLoading, setIsLoading] = useState(false);

    // create user inputs
    const [id, setId] = useState('');
    const [epfNumber, setEpfNumber] = useState('');
    const [nicNumber, setNicNumber] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [gender, setGender] = useState('');
    const [addresses, setAddresses] = useState([]);
    const [address, setAddress] = useState('');
    const [phones, setPhones] = useState([]);
    const [phone, setPhone] = useState('');

    useEffect(() => {
        if (props.isUpdate) {
            setIsLoading(true)
            getEmployeeById(
                result => {
                    setId(result.content.id)
                    setEpfNumber(result.content.epfNumber)
                    setNicNumber(result.content.nicNumber)
                    setFirstName(result.content.firstName)
                    setLastName(result.content.lastName)
                    setGender(result.content.gender)
                    setAddresses(result.content.address)
                    setPhones(result.content.phone)
                    setStatus(result.content.status)

                    setIsLoading(false)
                },
                error => {
                },
                props.employeeId,
            );
        }
    }, [])

    const [status, setStatus] = useState('');

    //--------------------------------------------------
    const handleChangeEpfNumber = (event) => {
        setEpfNumber(event.target.value);
    };
    const handleChangeNicNumber = (event) => {
        setNicNumber(event.target.value);
    };
    const handleChangeFirstName = (event) => {
        setFirstName(event.target.value);
    };
    const handleChangeLastName = (event) => {
        setLastName(event.target.value);
    };
    const handleChangeGender = (event) => {
        setGender(event.target.value);
    };
    const handleChangeAddress = (event) => {
        setAddress(event.target.value);
    };
    const handleChangePhone = (event) => {
        setPhone(event.target.value);
    };

    // validate user inputs
    const [firstNameValidity, setFirstNameValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateFirstName = () => {
        let validity = {};
        if (!firstName || firstName === '') {
            validity.isValid = false;
            validity.errorMessage = "FirstName cannot be empty"
            setFirstNameValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setFirstNameValidity(validity)
            return true
        }
    };
    
    const [genderValidity, setGenderValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateGender = () => {
        let validity = {};
        if (!gender || gender === '') {
            validity.isValid = false;
            validity.errorMessage = "Gender cannot be empty"
            setGenderValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setGenderValidity(validity)
            return true
        }
    };

    const [addressValidity, setAddressValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateAddress = () => {
        let validity = {};
        if (!address || address === '') {
            validity.isValid = false;
            validity.errorMessage = "Address cannot be empty"
            setAddressValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setAddressValidity(validity)
            return true
        }
    };

    const [phoneValidity, setPhoneValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validatePhone = () => {
        let validity = {};
        if (!phone || phone === '') {
            validity.isValid = false;
            validity.errorMessage = "Address cannot be empty"
            setPhoneValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setPhoneValidity(validity)
            return true
        }
    };

    const setInputsToDefaultOnFormSubmit = () => {
        setId('')
        setEpfNumber('')
        setNicNumber('')
        setFirstName('')
        setLastName('')
        setGender('')
        setAddresses([])
        setPhones([])
        setStatus('')
    }

    const validateInputFields = () => {
        let formValidators = []
        let isFormValid = true
        if (!validateFirstName()) {
            formValidators[0] = false
        }
        if (!validateGender()) {
            formValidators[2] = false
        }
        formValidators.map((val) => {
            if (!val) {
                isFormValid = false
            }
        })
        return isFormValid;
    }
    const createEmployeeFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        let employee = {
            epfNumber: epfNumber,
            nicNumber: nicNumber,
            firstName: firstName,
            lastName: lastName,
            gender: gender,
            address: addresses,
            phone: phones,
        };

        createEmployee(
            (result) => {
                toast(`Employee: ${employee.firstName} was created!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.createOrUpdatecallback(result.content);
            },
            employee,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    const updateEmployeeFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        let employee = {
            id: id,
            epfNumber: epfNumber,
            nicNumber: nicNumber,
            firstName: firstName,
            lastName: lastName,
            gender: gender,
            address: addresses,
            phone: phones,
            status: status
        };

        updateEmployee(
            (result) => {
                toast(`Employee: ${employee.firstName} was updated!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.createOrUpdatecallback(result.content);
            },
            employee,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    const addToAddresses = () => {
        if (!validateAddress()) {
            return
        }
        let temp = addresses.concat([address])
        setAddresses(temp)
        setAddress('')
    }

    const addToPhones = () => {
        if (!validatePhone()) {
            return
        }
        let temp = phones.concat([phone])
        setPhones(temp)
        setPhone('')
    }

    const removeAddressFromAddresses = (index) => {
        if (index > -1) { // only splice array when item is found
            addresses.splice(index, 1); // 2nd parameter means remove one item only
            setAddresses([...addresses]);
        }
    };

    const removePhoneFromPhones = (index) => {
        if (index > -1) { // only splice array when item is found
            phones.splice(index, 1); // 2nd parameter means remove one item only
            setPhones([...phones]);
        }
    };

    return (<Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1, width: '27ch'},
            }}
            noValidate
            autoComplete="off"
        >
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                >
                </DialogContentText>
                <div>
                    <TextField
                        required
                        id="epf-number-input"
                        label="EPF Number"
                        onChange={handleChangeEpfNumber}
                        value={epfNumber}
                        helperText={"Enter EPF Number here"}
                    />
                    <TextField
                        required
                        id="nic-number-input"
                        label="NIC Number"
                        onChange={handleChangeNicNumber}
                        value={nicNumber}
                        helperText={"Enter NIC Number here"}
                    />
                </div>
                <div>
                    <TextField
                        required
                        id="first-name-input"
                        label="First Name"
                        onChange={handleChangeFirstName}
                        value={firstName}
                        error={!firstNameValidity.isValid}
                        onBlur={validateFirstName}
                        helperText={(firstNameValidity.isValid) ? "Enter First name here" : firstNameValidity.errorMessage}
                    />
                    <TextField
                        required
                        id="last-name-input"
                        label="Last Name"
                        onChange={handleChangeLastName}
                        value={lastName}
                        helperText={"Enter last name here"}
                    />
                </div>
                <div>
                    <FormControl sx={{m: 1, minWidth: '20ch', marginRight: 0}} error={!genderValidity.isValid}>
                        <InputLabel id="gender-label">Gender</InputLabel>
                        <Select
                            labelId="gender-label"
                            id="gender-input"
                            defaultValue=""
                            value={gender}
                            onChange={handleChangeGender}
                            onBlur={validateGender}
                            error={!genderValidity.isValid}
                            autoWidth
                            label="Gender"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={'MALE'}>Male</MenuItem>
                            <MenuItem value={'FEMALE'}>Female</MenuItem>
                        </Select>
                        <FormHelperText>{genderValidity.isValid ? "Select the gender" : "Gender must not be empty"}</FormHelperText>
                    </FormControl>
                    <TextField
                        sx={{minWidth: '20ch'}}
                        required
                        id="address-input"
                        label="Address"
                        onChange={handleChangeAddress}
                        value={address}
                        error={!addressValidity.isValid}
                        onBlur={validateAddress}
                        helperText={(addressValidity.isValid)  ? "Enter address here" : addressValidity.errorMessage}
                    />
                    <IconButton
                        aria-label="expand row"
                        size="large"
                        sx={{ marginTop: 1 }}
                        onClick={addToAddresses}
                    >
                        <AddCircleOutlineOutlined fontSize={"large"} />
                    </IconButton>
                </div>
                <div>
                    <TextField
                        required
                        id="phone-input"
                        label="Phone number"
                        onChange={handleChangePhone}
                        value={phone}
                        error={!phoneValidity.isValid}
                        onBlur={validatePhone}
                        helperText={(phoneValidity.isValid) ? "Enter phone number here" : phoneValidity.errorMessage}
                    />
                    <IconButton
                        aria-label="expand row"
                        size="large"
                        sx={{ marginTop: 1 }}
                        onClick={addToPhones}
                    >
                        <AddCircleOutlineOutlined fontSize={"large"} />
                    </IconButton>
                </div>
                <div>
                    <div>
                        <List >
                            <ListSubheader>Addresses</ListSubheader>
                            {(addresses) && addresses.map((ad, index) => (
                                <ListItem key={index}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <LocalOfferOutlined />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={ad}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={() => removeAddressFromAddresses(index)} edge="end" aria-label="delete">
                                            <Delete />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                    <div>
                        <List >
                            <ListSubheader>Phones</ListSubheader>
                            {(phones) && phones.map((ph, index) => (
                                <ListItem key={index}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <LocalOfferOutlined />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={ph}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={() => removePhoneFromPhones(index)} edge="end" aria-label="delete">
                                            <Delete />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                {props.isUpdate ? (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={updateEmployeeFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Update/>}
                >Update</LoadingButton>) : (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={createEmployeeFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Create/>}
                >Create</LoadingButton>)}
                <LoadingButton
                    color={'warning'}
                    size="medium"
                    variant="outlined"
                    onClick={props.handleClose}
                    loading={false}
                    loadingPosition="end"
                    disabled={false}
                    endIcon={<Cancel/>}
                >Cancel</LoadingButton>
            </DialogActions>
        </Box>
    )
}