import * as React from "react";

import {useState, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {
    TableBody, TableCell, TableHead, TableRow, Icon, Card,
} from "@mui/material";
import {
    Edit, Delete, Download, Upload, AddLink,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import FloatingActionButtons from "../../ui/buttons/floatingActions";
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import IconButton from "@mui/material/IconButton";
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';

import {
    searchDeshana, uploadDeshana, deleteDeshana, downloadDeshana, loadDirectoryById,
} from '../../../client/deshana/call'
import PageLayout from "../../ui/layout/pageLayout";
import ActionMenu from "../../ui/menus/actionMenu";
import MainTable from "../../ui/table/mainTable";
import MainModalDialog from "../../ui/modal/mainModal";
import {downloadOctecStream} from "../../../utils/download";
import {humanFileSize} from "../../../utils/app-utils";
import MainBreadcrumbs from "../../ui/common/mainBreadcrumbs";
import UploadDeshanaForm from "./uploadDeshanaForm";
import CreateDirectoryForm from "./createDirectoryForm";
import CreateWebLinkForm from "./createWebLinkForm";
import Pagination from "../../ui/pagination/pagination";
import getMaterialIcon from "./fileTypeIconUtil"
import {toast} from "react-toastify";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export default function Deshana() {

    const navigate = useNavigate()
    const params = useParams()

    const [pagination, setPagination] = useState({})

    const [parentDirectoryId, setParentDirectoryId] = useState(params.parentId ? params.parentId : 'root');
    const [directoryToBeUpdated, setDirectoryToBeUpdated] = useState('');
    const [parentDirectory, setParentDirectory] = useState(null);
    const [webLink, setWebLink] = useState(null);

    // loading section
    const [isDeshanaListLoading, setIsDeshanListLoading] = useState(true);
    const [isParentDirectoryLoading, setIsParentDirectoryLoading] = useState(true);

    // deshana list
    const [deshanaList, setDeshanaList] = useState([]);
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    // major functions
    const loadDeshana = (page, rowsPerPage) => {
        setIsDeshanListLoading(true)
        searchDeshana(
            result => {
                setDeshanaList(result.content);
                setPagination(result.pagination);
                setIsDeshanListLoading(false)
            },
            page ? page : 1,
            rowsPerPage ? rowsPerPage : 10,
            null,
            parentDirectoryId,
        );
    }

    const {page, rowsPerPage} = pagination
    useEffect(loadDeshana, [page, parentDirectoryId, rowsPerPage])

    const loadParentDirectory = () => {
        setIsParentDirectoryLoading(true)
        loadDirectoryById(
            result => {
                setParentDirectory(result.content);
                setWebLink(result.content.webLink)
                setIsParentDirectoryLoading(false)
            },
            parentDirectoryId,
        );
    }
    useEffect(loadParentDirectory, [parentDirectoryId])

    const uploadDeshanaFile = (callback, errorCallback, progressCallback, file) => {
        uploadDeshana(
            () => {
                callback()
            },
            file,
            progress => {
                progressCallback(progress)
            },
            parentDirectoryId,
            err => {
                errorCallback()
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }


    const deleteDeshanaOrDirectory = (id) => {
        deleteDeshana((result) => {
                loadDeshana();
            },
            id,
            (error) => {
            }
        )
    }

    const downloadDeshanaFile = (id, fileName) => {
        downloadDeshana(result => {
                downloadOctecStream(result, fileName);
            },
            id,
            prog => {
            },
            err => {
            });
    }

    function extractBreadCrumbs() {
        let lbk = 0;
        if (!(parentDirectory.breadcrumbs === null)) {
            lbk = parentDirectory.breadcrumbs.length;
        }

        let lb = {
            'name': parentDirectory.name,
            'directoryId': parentDirectory.id,
            'key': lbk,
        };

        if (!(parentDirectory.breadcrumbs === null)) {
            let bcs = parentDirectory.breadcrumbs.concat(lb);
            setBreadcrumbs(bcs)
        } else {
            setBreadcrumbs(lbk)
        }
    }

    useEffect(() => {
        if (parentDirectory) {
            extractBreadCrumbs();
        }
    }, [parentDirectory])

    function handleOnSelectDirectory(id) {
        setParentDirectoryId(id)
        navigate(`/deshana/${id}`)
    }

    // modal related properties
    const handleModalClose = () => setModalOpen(false);

    const createDirectoryForm = (<CreateDirectoryForm
        isUpdate={false}
        handleClose={handleModalClose}
        parentDirectoryId={parentDirectoryId}
        breadcrumbs={breadcrumbs}
        refreshTable={loadDeshana}
    />);

    const updateDirectoryForm = (<CreateDirectoryForm
        isUpdate={true}
        directoryToBeUpdated={directoryToBeUpdated}
        handleClose={handleModalClose}
        parentDirectoryId={parentDirectoryId}
        breadcrumbs={breadcrumbs}
        refreshTable={loadDeshana}
    />);

    const createWebLinkForm = (<CreateWebLinkForm
        handleClose={handleModalClose}
        directoryId={parentDirectoryId}
        webLink={webLink}
    />);

    const uploadDeshanaForm = (<UploadDeshanaForm
        handleClose={handleModalClose}
        parentDirectoryId={parentDirectoryId}
        breadcrumbs={breadcrumbs}
        uploadDeshanaFile={uploadDeshanaFile}
        refreshTable={loadDeshana}
    />);

    const [modalOpen, setModalOpen] = useState(false);
    const [modelToOpen, setModelToOpen] = useState('');
    const [modelHeader, setModelHeader] = useState('');
    const handleModalOpen = (model) => {
        if (model === 'uploadDeshana') {
            setModelHeader("Upload Deshana")
            setModelToOpen(uploadDeshanaForm)
        } else if (model === 'createDirectory') {
            setModelHeader("Create Directory")
            setModelToOpen(createDirectoryForm)
        } else if (model === 'updateDeshana') {
            setModelHeader("Update Directory")
            setModelToOpen(updateDirectoryForm)
        } else if (model === 'createWebLink') {
            setModelHeader("Create/Update Web-Link")
            setModelToOpen(createWebLinkForm)
        }
        setModalOpen(true);
    }

    const floatingActionConfig = [
        {
            title: "Create Directory",
            icon: <AddIcon sx={{mr: 1}}/>,
            onClickAction: handleModalOpen,
            modelName: "createDirectory",
            visible: true,
        },
        {
            title: "Upload Deshana",
            icon: <Upload sx={{mr: 1}}/>,
            onClickAction: handleModalOpen,
            modelName: "uploadDeshana",
            visible: true,
        },
        {
            title: "Create Web-link",
            icon: <AddLink sx={{mr: 1}}/>,
            onClickAction: handleModalOpen,
            modelName: "createWebLink",
            visible: true,
        }
    ]

    const tableHeader = (<TableHead>
        <TableRow>
            <TableCell>
                Name
            </TableCell>
            <TableCell align="left">Created Date</TableCell>
            <TableCell align="left">Last Modified Date</TableCell>
            <TableCell align="left">File Size</TableCell>
            <TableCell align="left">Actions</TableCell>
        </TableRow>
    </TableHead>)

    const tableBody = (
        (deshanaList && deshanaList.length > 0) ? (<TableBody>
                {deshanaList.map((deshana, index) => (
                    <TableRow
                        key={index}
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                        <TableCell
                            component="th"
                            scope="row"
                        >

                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => deshana.elementType === 'DIR' ? handleOnSelectDirectory(deshana.id) : () => {
                                }}
                            >
                                {deshana.elementType === 'DIR' ?
                                    <FolderOutlinedIcon fontSize={"medium"}/> : getMaterialIcon(deshana.name)}
                            </IconButton>
                            <Icon>
                            </Icon>
                            <span
                                style={{cursor: "pointer"}}
                                onClick={() => deshana.elementType === 'DIR' ? handleOnSelectDirectory(deshana.id) : () => {
                                }}
                            >
                            {deshana.name}
                        </span>
                        </TableCell>
                        <TableCell align="left">{deshana.createdDate}</TableCell>
                        <TableCell align="left">{deshana.lastUpdatedTime}</TableCell>
                        <TableCell align="left">
                            {deshana.elementType === 'DIR' ? humanFileSize(deshana.directorySize) :
                                deshana.elementType === 'FILE' ? humanFileSize(deshana.deshanaFile.size) : <></>
                            }
                        </TableCell>
                        <TableCell align="left">
                            <ActionMenu>
                                <MenuItem onClick={() => {
                                    deleteDeshanaOrDirectory(deshana.id)
                                }}>
                                    <ListItemIcon>
                                        <Delete/>
                                    </ListItemIcon>
                                    <ListItemText>Delete</ListItemText>
                                    <Typography variant="body2" color="text.secondary">
                                    </Typography>
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    handleModalOpen("updateDeshana");
                                    setDirectoryToBeUpdated(deshana.id)
                                }}>
                                    <ListItemIcon>
                                        <Edit/>
                                    </ListItemIcon>
                                    <ListItemText>Update</ListItemText>
                                    <Typography variant="body2" color="text.secondary">
                                    </Typography>
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    downloadDeshanaFile(deshana.id, deshana.name)
                                }}>
                                    <ListItemIcon>
                                        <Download/>
                                    </ListItemIcon>
                                    <ListItemText>Download</ListItemText>
                                    <Typography variant="body2" color="text.secondary">
                                    </Typography>
                                </MenuItem>
                            </ActionMenu>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        ) : null
    )

    return (
        <PageLayout header="Deshana" sx={{}}>
            <Card sx={{padding: 1, margin: 0, border: '1px solid', borderColor: 'rgb(229,226,226)'}}>
                <FloatingActionButtons config={floatingActionConfig}/>
                <MainModalDialog
                    open={modalOpen}
                    handleClose={handleModalClose}
                    header={modelHeader}
                    body={modelToOpen}
                />
                <MainBreadcrumbs breadcrumbs={breadcrumbs} handleClick={handleOnSelectDirectory}/>
                <MainTable
                    iscontentLoading={isDeshanaListLoading}
                    tableHeader={tableHeader} tableBody={tableBody}
                />
                <Pagination
                    reloadData={(pageNumber, pageSize) => loadDeshana(pageNumber, pageSize)}
                    pagination={pagination}
                />
            </Card>
        </PageLayout>
    );
}