import {Box, Button, Paper, Stack, Typography} from "@mui/material";
import {Clear, SelectAll, UploadFile} from "@mui/icons-material";
import {LinearProgressBar} from "../progress/linearProgressBar";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import * as React from "react";
import {uploadFile} from "../../../client/fileUpload";
import AttachFileIcon from '@mui/icons-material/AttachFile';

export const FilesUploadForm = (props) => {

    const [filesProgress, setFilesProgress] = useState([])
    const [filesToBeUploaded, setFilesToBeUploaded] = useState([]);

    const [uploadedFileList, setUploadedFileList] = useState([]);
    const handleChangeFilesToBeUploaded = (event) => {
        let files = event.target.files
        setFilesToBeUploaded(files);
        let temp = []
        for (let i = 0; i < files.length; i++) {
            temp.push({"name": files[i].name, progress: 0})
        }
        setFilesProgress(temp);
    };

    const [isFilesToBeUploadedValid, setIsFilesToBeUploadedValid] = useState(false);

    useEffect(() => {
        if (filesToBeUploaded.length < 1) {
            setIsFilesToBeUploadedValid(false);
        } else {
            setIsFilesToBeUploadedValid(true)
        }
    }, [filesToBeUploaded])

    const cleanUp = () => {
        setFilesProgress([]);
        setFilesToBeUploaded([]);
        setUploadedFileList([])
    }

    const handleOnSubmit = () => {
        const path = props.path
        for (let i = 0; i < filesToBeUploaded.length; i++) {
            uploadFile(
                (data) => {
                    setUploadedFileList((up) => [...uploadedFileList, filesToBeUploaded[i].name])
                    props.handleOnUploadSuccess(data.content)
                    cleanUp()
                },
                filesToBeUploaded[i],
                prog => {
                    let temp = [...filesProgress]
                    temp[i].progress = prog
                    setFilesProgress([...temp])
                },
                path,
                props.containerId,
                err => {
                    if (err.validationFailures) {
                        err && err.validationFailures.map((e) => {
                            toast(`Error: ${e.code}`,
                                {
                                    closeOnClick: true,
                                    type: "error",
                                    theme: "light",
                                    icon: <ErrorOutlineIcon/>
                                });
                        })
                    } else {
                        toast(`Error: ${err}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    }
                }
            );
        }
    }

    return (
        <Box sx={{
            display: 'flex',
            '& .MuiTextField-root': {m: 1, width: '27ch',},
            justifyContent: 'center',
            alignItems: 'center',
            
        }}>
            <Box
                sx={{
                    display: 'flex',
                    '& .MuiTextField-root': {m: 1, width: '27ch',},
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid grey',
                    padding: '5px',
                    borderRadius: '5px',
                    marginRight: '5px',
                }}
            >
                <input
                    required
                    style={{display: 'none'}}
                    id="raised-button-file"
                    onChange={(event) => {
                        event.preventDefault()
                        console.log("unbilivable")
                        console.log(event)
                        handleChangeFilesToBeUploaded(event)
                    }}
                    onClick={() => cleanUp()}
                    type={"file"}
                    multiple
                />
                <label htmlFor="raised-button-file">
                    <Button variant="outlined" component="span" startIcon={<AttachFileIcon/>}>
                        Select
                    </Button>
                </label>
                <Button
                    sx={{marginLeft: "5px"}}
                    variant="outlined"
                    component="span"
                    startIcon={<UploadFile/>}
                    disabled={!isFilesToBeUploadedValid}
                    onClick={handleOnSubmit}
                >
                    Upload
                </Button>
                <Button
                    sx={{marginLeft: "5px"}}
                    variant="outlined"
                    component="span"
                    startIcon={<Clear/>}
                    disabled={!isFilesToBeUploadedValid}
                    onClick={cleanUp}
                >
                    Clear
                </Button>
            </Box>
            {
                filesProgress.map((item, index) => (
                    <Paper key={index} sx={{margin: '0.5rem 0', padding: '0.5rem'}}>
                        <Typography variant="caption" display="block" gutterBottom>
                            {item.name}
                        </Typography>
                        <Box sx={{width: '100%'}}>
                            <LinearProgressBar value={item.progress}/>
                        </Box>
                    </Paper>
                ))
            }
        </Box>
    )

}