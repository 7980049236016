import {useState, useEffect} from "react";

import {
    Box,
    TextField,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@mui/material";
import {Cancel, Create, Update,} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";


// create item category form
export default function CreateSubCategoryForm(props) {

    const [isLoading, setIsLoading] = useState(false);

    // create user inputs
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const [subCategories, setSubCategories] = useState([]);

    useEffect(() => {
        if (props.subCategory) {
            let subCat = {...props.subCategory}
            setId(subCat.id)
            setName(subCat.name)
            setDescription(subCat.description)
        }
    }, [])


    //--------------------------------------------------
    const handleChangeName = (event) => {
        setName(event.target.value);
    };
    const handleChangeDescription = (event) => {
        setDescription(event.target.value);
    };

    // validate user inputs
    const [nameValidity, setNameValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateName = () => {
        let validity = {};
        if (!name || name === '') {
            validity.isValid = false;
            validity.errorMessage = "Name cannot be empty"
            setNameValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setNameValidity(validity)
            return true
        }
    };

    const [descriptionValidity, setDescriptionValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateDescription = () => {
        let validity = {};
        if (!description || description === '') {
            validity.isValid = false;
            validity.errorMessage = "Description cannot be empty"
            setDescriptionValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setDescriptionValidity(validity)
            return true
        }
    };

    const setInputsToDefaultOnFormSubmit = () => {
        setId('')
        setName('')
        setDescription('')
    }

    const validateInputFields = () => {
        let formValidators = []
        let isFormValid = true
        if (!validateName()) {
            formValidators[0] = false
        }
        if (!validateDescription()) {
            formValidators[0] = false
        }
        formValidators.map((val) => {
            if (!val) {
                isFormValid = false
            }
        })
        return isFormValid;
    }
    const createOrUpdateSubCategoryFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        let subCat = {
            id: id? id: null,
            name: name,
            description: description,
        };

        if (props.subCategory) {
            props.updateSubCategory(subCat);
        } else {
            let temp = subCategories.concat(subCat)
            setSubCategories(temp);
        }
        setInputsToDefaultOnFormSubmit()
        setIsLoading(false)
    }

    useEffect(() => {
        if (!props.subCategory) {
            props.addSubCategories(subCategories);
        }
    }, [subCategories])

    return (<Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1, width: '27ch'},
            }}
            noValidate
            autoComplete="off"
        >
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                >
                </DialogContentText>
                <div>
                    <TextField
                        required
                        id="name-input"
                        label="Name"
                        onChange={handleChangeName}
                        value={name}
                        error={!nameValidity.isValid}
                        onBlur={validateName}
                        helperText={(nameValidity.isValid) ? "Enter name here" : nameValidity.errorMessage}
                    />
                    <TextField
                        required
                        id="description-input"
                        label="Description"
                        onChange={handleChangeDescription}
                        value={description}
                        error={!descriptionValidity.isValid}
                        onBlur={validateDescription}
                        helperText={(descriptionValidity.isValid) ? "Enter description here" : descriptionValidity.errorMessage}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                {props.isUpdate ? (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={createOrUpdateSubCategoryFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Update/>}
                >Update</LoadingButton>) : (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={createOrUpdateSubCategoryFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Create/>}
                >Create</LoadingButton>)}
                <LoadingButton
                    color={'warning'}
                    size="medium"
                    variant="outlined"
                    onClick={props.handleClose}
                    loading={false}
                    loadingPosition="end"
                    disabled={false}
                    endIcon={<Cancel/>}
                >Cancel</LoadingButton>
            </DialogActions>
        </Box>
    )
}