
import * as React from "react";

import { useState, useEffect } from "react";

import {
    TableBody, TableCell, TableHead, TableRow, Link, Icon,
} from "@mui/material";
import {
    Download,
} from "@mui/icons-material";
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import IconButton from "@mui/material/IconButton";
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';

import {
    deshanaSearchByWebLinkUsers, downloadDeshana, findDeshanaIdByWebLink
} from '../../../../client/deshana/call'
import PageLayout from "../../../ui/layout/pageLayout";
import ActionMenu from "../../../ui/menus/actionMenu";
import MainTable from "../../../ui/table/mainTable";
import { downloadOctecStream } from "../../../../utils/download";
import { humanFileSize } from "../../../../utils/app-utils";
import MainModalDialog from "../../../ui/modal/mainModal";
import WebLinkLoginForm from "./webLinkLoginForm";
import MainBreadcrumbs from "../../../ui/common/mainBreadcrumbs";
import { anLogin } from "../../../../client/authentication/auth";
import { getAnAccessToken } from "../../../../utils/token-store";
import Pagination from "../../../ui/pagination/pagination";

export default function DeshanaWebLink() {

    // modal related properties
    const [modalOpen, setModalOpen] = useState(true);
    const handleModalClose = () => setModalOpen(false);

    const [pagination, setPagination] = useState({})

    // loading section
    const [isDeshanaListLoading, setIsDeshanListLoading] = useState(true);

    // user list
    const [deshanaList, setDeshanaList] = useState([]);
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    const [webLinkId, setWebLinkId] = useState('');
    const [parentDirectoryId, setParentDirectoryId] = useState('');

    useEffect(() => {
        setWebLinkId(new URLSearchParams(window.location.search).get('webLId'))
    }, [])
    
    // major functions
    const loadDeshana = (page, rowsPerPage, id, callback) => {
        setIsDeshanListLoading(true)
        deshanaSearchByWebLinkUsers(
            result => {
                setDeshanaList(result.content);
                setPagination(result.pagination);
                setIsDeshanListLoading(false)
                handleModalClose()
                if (callback) {
                    callback()
                }
            },
            page ? page : 1,
            rowsPerPage ? rowsPerPage : 10,
            null,
            id? id: parentDirectoryId,
        );
    }

    const downloadDeshanaFile = (id, fileName) => {
        downloadDeshana(result => {
            downloadOctecStream(result, fileName);
        },
            id,
            prog => {
            },
            err => {
            });
    }

    const addItemToBreadCrumbs = (id, name) => {
        let lb = {
            'name': name,
            'directoryId': id,
            'index': breadcrumbs.length
        };

        let bcs = [...breadcrumbs].concat(lb);
        setBreadcrumbs((breadcrumbs) => [...breadcrumbs, lb])
    }

    const loginWithWebLink = (pass) => {
        
        let payload = {
            webLinkId: webLinkId,
            password: pass
        }

        findDeshanaIdByWebLink(
            result => {
                var token = getAnAccessToken();
                if(!token) {
                    anLogin(() => {
                        setParentDirectoryId(result.content.id)
                        addItemToBreadCrumbs(result.content.id, result.content.name )
                        loadDeshana(null, null, result.content.id)
                    })
                } else {
                    setParentDirectoryId(result.content.id)
                    addItemToBreadCrumbs(result.content.id, result.content.name )
                    loadDeshana(null, null, result.content.id)
                }
            },
            payload,
            error => {
            }
        )        
    }

    const onSelectDeshanaRow = (id, name) => {
        loadDeshana(null, null, id, () => {
            setParentDirectoryId(id)
            addItemToBreadCrumbs(id, name )
        })
    }
    
    const handleBreadCrumbItemClick = (id, index) => {
        loadDeshana(null, null, id, () => {
            setParentDirectoryId(id)
            let bcs = [...breadcrumbs].slice(0, index + 1);
            setBreadcrumbs(bcs)
        })
    }

    return (
        <PageLayout header="Deshana">
            <MainModalDialog
                open={modalOpen}
                handleClose={() => {}}
                header="Login"
                persist
                body={
                    <WebLinkLoginForm
                        handleClose={handleModalClose}
                        breadcrumbs={breadcrumbs}
                        loginWithWebLink={loginWithWebLink}
                    />
                    }
            />
            <MainBreadcrumbs breadcrumbs={breadcrumbs} handleClick={handleBreadCrumbItemClick} />
            <MainTable>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Name
                        </TableCell>
                        <TableCell align="left">Created Date</TableCell>
                        <TableCell align="left">Last Modified Date</TableCell>
                        <TableCell align="left">File Size</TableCell>
                        <TableCell align="left">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {deshanaList.map((deshana, index) => (
                        <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell
                                component="th"
                                scope="row"
                                onClick={() => onSelectDeshanaRow(deshana.id, deshana.name)}
                            >
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                >
                                    <FolderOutlinedIcon fontSize={"medium"} />
                                </IconButton>
                                <Icon>
                                </Icon>
                                <Link underline='none' component='button' variant='button'>
                                    {deshana.name}
                                </Link>
                            </TableCell>
                            <TableCell align="left">{deshana.createdDate}</TableCell>
                            <TableCell align="left">{deshana.lastUpdatedTime}</TableCell>
                            <TableCell align="left">
                                {deshana.elementType === 'DIR' ? humanFileSize(deshana.directorySize) :
                                    deshana.elementType === 'FILE' ? humanFileSize(deshana.deshanaFile.size) : <></>
                                }
                            </TableCell>
                            <TableCell align="left">
                                <ActionMenu >
                                    <MenuItem onClick={() => { downloadDeshanaFile(deshana.id, deshana.name) }}>
                                        <ListItemIcon>
                                            <Download />
                                        </ListItemIcon>
                                        <ListItemText>Download</ListItemText>
                                        <Typography variant="body2" color="text.secondary">
                                        </Typography>
                                    </MenuItem>
                                </ActionMenu>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </MainTable>
            <Pagination
                reloadData={(pageNumber, pageSize) => loadDeshana(pageNumber, pageSize)}
                pagination={pagination}
            />
        </PageLayout>
    );
}