import * as React from 'react';
import { useState, useEffect } from "react";

import { Chip, FormControl, Grid, IconButton, MenuItem, Select, Stack } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

export default function Pagination(props) {

    // pagination related properties
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        if (props.pagination) {
            setPageNumber(props.pagination.pageNumber);
            setTotalRecords(props.pagination.totalRecords);
        }
    }, [props.pagination])

    // pagination related functions
    const handleClickOnNextPage = () => {
        props.reloadData(pageNumber + 1, pageSize)
    };

    const handleChangePageSize = (event) => {
        const value = event.target.value
        setPageSize(value)
        props.reloadData(pageNumber, value)
    };

    const handleClickOnPreviousPage = () => {
        props.reloadData(pageNumber - 1, pageSize)
    };

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{marginTop:"10px"}}
        >
            <Stack direction="row" spacing={1}>
                <Chip label="Select Page Size" size='medium' />
                <FormControl size="small" sx={{ m: 1, minWidth: '12ch', }} >
                    <Select
                        sx={{ height: "32px", border: "none" }}
                        labelId="directory-user-role-label"
                        id="directory-user-role-input"
                        value={pageSize}
                        onChange={handleChangePageSize}
                        autoWidth
                    >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={75}>75</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                    </Select>
                </FormControl>
                <Chip label={`${(pageNumber * pageSize) - (pageSize - 1)} - ${(totalRecords < pageSize) ? totalRecords : pageNumber * pageSize} of ${totalRecords}`} size="medium" variant="" />
                <IconButton
                    onClick={handleClickOnPreviousPage}
                    disabled={pageNumber <= 1}
                    aria-label="add an alarm"
                    sx={{ height: "32px", width: "32px" }}>
                    <ArrowBackIos sx={{ fontSize: "17px" }} />
                </IconButton>
                <IconButton
                    onClick={handleClickOnNextPage}
                    disabled={totalRecords <= (pageNumber * pageSize)}
                    aria-label="add an alarm"
                    sx={{ height: "32px", width: "32px" }}>
                    <ArrowForwardIos sx={{ fontSize: "17px" }} />
                </IconButton>
            </Stack>
        </Grid>
    );
}