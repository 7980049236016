import * as React from 'react';
import {forwardRef} from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import LoginForm from '../../pages/login/loginForm';
import {Grid} from "@mui/material";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  return (
    <div>
      <Dialog
        fullScreen
        open={props.open}
        onClose={null}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography fontFamily={"Oswald"} align={'center'} variant="h4" component="div" sx={{ flexGrow: 1 }}>
              Pasadika Admin Portal - Login
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '80vh' }}
        >

          <Grid item xs={1}>
            <LoginForm onLoginSuccess={props.onSuccess}/>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
