import {useState, useEffect} from "react";

import {
    Box, TextField, DialogContent, DialogContentText, DialogActions,
} from "@mui/material";
import {Cancel, Update,} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";

import {
    passwordChange
} from '../../../../client/users/call'
import {toast} from "react-toastify";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";


// change password form
export default function ChangePasswordForm(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    // change password user inputs
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const handleChangeOldPassword = (event) => {
        setOldPassword(event.target.value);
    };
    const handleChangeNewPassword = (event) => {
        setNewPassword(event.target.value);
    };

    const [oldPasswordValidity, setOldPasswordValidity] = useState(0);
    const validateOldPassword = (event) => {
        let passwordPattern = /(?=.*[!@#$%^&*])/;
        if (!oldPassword || oldPassword === '' || !passwordPattern.test(oldPassword)) {
            setOldPasswordValidity(1);
        } else {
            setOldPasswordValidity(2);
        }
    };

    const [newPasswordValidity, setNewPasswordValidity] = useState(0);
    const validatePasswordConfirm = (event) => {
        let passwordPattern = /(?=.*[!@#$%^&*])/;
        if (!newPassword || newPassword === '' || !passwordPattern.test(newPassword)) {
            setNewPasswordValidity(1);
        } else {
            setNewPasswordValidity(2);
        }
    };

    const checkFormValid = () => {
        if ((oldPasswordValidity === 2)
            && (newPasswordValidity === 2)) {
            setIsFormValid(true)
        } else {
            setIsFormValid(false)
        }
    }

    useEffect(() => {
            checkFormValid()
        },
        [oldPasswordValidity, newPasswordValidity]
    )

    const setInputsToDefaultOnFormSubmit = () => {
        setOldPassword('')
        setNewPassword('')
    }

    const updatePasswordFunc = () => {
        setIsLoading(true)
        let data = {
            currentPassword: oldPassword,
            newPassword: newPassword,
        };
        passwordChange(
            data,
            () => {
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
            },
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    return (<Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1, width: '27ch'},
            }}
            noValidate
            autoComplete="off"
        >
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                >
                </DialogContentText>
                <div>
                    <TextField
                        required
                        id="old-password-input"
                        label="Old Password"
                        type="password"
                        value={oldPassword}
                        onChange={handleChangeOldPassword}
                        error={oldPasswordValidity === 1}
                        onClick={() => setOldPasswordValidity(0)}
                        onBlur={validateOldPassword}
                        helperText={(oldPasswordValidity === 1) ? "Enter your old password here" : "Invalid password"}
                    />
                    <TextField
                        required
                        id="new-password-input"
                        label="New Confirm"
                        type="password"
                        onChange={handleChangeNewPassword}
                        value={newPassword}
                        error={newPasswordValidity === 1}
                        onClick={() => setNewPasswordValidity(0)}
                        onBlur={validatePasswordConfirm}
                        helperText={(newPasswordValidity === 1) ? "Enter valid password here" : "Invalid password"}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={updatePasswordFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    disabled={!isFormValid}
                    endIcon={<Update/>}
                >Update</LoadingButton>

                <LoadingButton
                    color={'warning'}
                    size="medium"
                    variant="outlined"
                    onClick={props.handleClose}
                    loading={false}
                    loadingPosition="end"
                    disabled={false}
                    endIcon={<Cancel/>}
                >Cancel</LoadingButton>
            </DialogActions>
        </Box>
    )
}