import {useState, useEffect} from "react";

import {
    Box,
    TextField,
    DialogContent,
    DialogContentText,
    DialogActions, TableHead, TableRow, TableCell, TableBody, IconButton, CardContent, Card,
} from "@mui/material";
import {Cancel, Create, Delete, Edit, Update,} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";

import {
    createItemCategory,
    getItemCategoryById,
    updateItemCategory
} from "../../../../client/item-categories/call";
import AddIcon from "@mui/icons-material/Add";
import CreateSubCategoryForm from "./createSubCategoryForm";
import MainModalDialog from "../../../ui/modal/mainModal";
import Typography from "@mui/material/Typography";
import FloatingActionButtons from "../../../ui/buttons/floatingActions";
import MainTable from "../../../ui/table/mainTable";
import {toast} from "react-toastify";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

// create item category form
export default function CreateItemCategoryForm(props) {

    const [isLoading, setIsLoading] = useState(false);

    // create user inputs
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [type, setType] = useState('');
    const [subCategories, setSubCategories] = useState([]);

    const [modalOpen, setModalOpen] = useState(false);
    const [modelToOpen, setModelToOpen] = useState('');
    const [modelHeader, setModelHeader] = useState('');
    const [subCategoryTobeUpdated, setSubCategoryTobeUpdated] = useState(null);

    useEffect(() => {
        if (props.isUpdate) {
            setIsLoading(true)
            getItemCategoryById(
                result => {
                    setId(result.content.id)
                    setName(result.content.name)
                    setDescription(result.content.description)
                    setType(result.content.type)
                    setSubCategories(result.content.subCategories? result.content.subCategories: [])
                    setStatus(result.content.status)

                    setIsLoading(false)
                },
                error => {
                },
                props.itemCategoryId,
            );
        }
    }, [])

    const [status, setStatus] = useState('');

    //--------------------------------------------------
    const handleChangeName = (event) => {
        setName(event.target.value);
    };
    const handleChangeDescription = (event) => {
        setDescription(event.target.value);
    };

    // validate user inputs
    const [nameValidity, setNameValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateName = () => {
        let validity = {};
        if (!name || name === '') {
            validity.isValid = false;
            validity.errorMessage = "Name cannot be empty"
            setNameValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setNameValidity(validity)
            return true
        }
    };

    const [descriptionValidity, setDescriptionValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateDescription = () => {
        let validity = {};
        if (!description || description === '') {
            validity.isValid = false;
            validity.errorMessage = "Description cannot be empty"
            setDescriptionValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setDescriptionValidity(validity)
            return true
        }
    };

    const setInputsToDefaultOnFormSubmit = () => {
        setId('')
        setName('')
        setDescription('')
        setSubCategories([])
        setStatus('')
    }

    const validateInputFields = () => {
        let formValidators = []
        let isFormValid = true
        if (!validateName()) {
            formValidators[0] = false
        }
        if (!validateDescription()) {
            formValidators[0] = false
        }
        formValidators.map((val) => {
            if (!val) {
                isFormValid = false
            }
        })
        return isFormValid;
    }
    const createItemCategoryFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        let itemCategory = {
            name: name,
            description: description,
            type: type,
            subCategories: subCategories,
        };

        createItemCategory(
            (result) => {
                toast(`Item: ${itemCategory.name} was created!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.createOrUpdatecallback(result.content);
            },
            itemCategory,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    const updateItemCategoryFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        let itemCategory = {
            id: id,
            name: name,
            description: description,
            type: type,
            subCategories: subCategories,
            status: status
        };

        updateItemCategory(
            (result) => {
                toast(`Item category: ${itemCategory.name} was updated!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.createOrUpdatecallback(result.content);
            },
            itemCategory,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    function deleteSubCategory(index) {
        if (index > -1) { // only splice array when item is found
            subCategories.splice(index, 1); // 2nd parameter means remove one item only
            setSubCategories([...subCategories]);
        }
    }

    // Sub category table header
    const subCatTableHeader = (<TableHead>
        <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="left">Description</TableCell>
            <TableCell align="left">Action Items</TableCell>
        </TableRow>
    </TableHead>)

    // Sub-categories table bod
    const subCatTableBody = (
        (subCategories && subCategories.length > 0) ? (<TableBody>
                {subCategories.map((subCat, index) => (
                    <TableRow
                        key={index}
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                        <TableCell align="left">{subCat.name}</TableCell>
                        <TableCell align="left">{subCat.description}</TableCell>
                        <TableCell align="left">
                            <IconButton
                                sx={{marginRight: '10px'}}
                                onClick={() => {
                                    deleteSubCategory(index)
                                }}>
                                <Delete/>
                            </IconButton>
                            <IconButton onClick={() => {
                                setSubCategoryTobeUpdated(index)
                            }}>
                                <Edit/>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        ) : null
    )

    const handleModalOpen = (model) => {
        if (model === 'createSubCategory') {
            setModelHeader("Create Sub-Category")
            setModelToOpen(createSubCategoryForm)
        } else if (model === 'updateSubCategory') {
            setModelHeader("Update Sub-Category")
            setModelToOpen(updateSubCategoryForm)
        }
        setModalOpen(true);
    }
    const handleModalClose = () => {
        setModalOpen(false);
        setSubCategoryTobeUpdated(null) // not a best practice
    }

    const createSubCategoryForm = (<CreateSubCategoryForm
        isUpdate={false}
        handleClose={handleModalClose}
        addSubCategories={(subCats) => {
            let temp = subCategories.concat(subCats)
            setSubCategories(temp);
        }}
    />);

    useEffect(() => {
        if (subCategoryTobeUpdated !== null) {
            handleModalOpen("updateSubCategory");
        }
    }, [subCategoryTobeUpdated])

    const updateSubCategoryForm = (<CreateSubCategoryForm
        isUpdate={true}
        handleClose={handleModalClose}
        subCategory={subCategories? subCategories[subCategoryTobeUpdated]: null}
        updateSubCategory={(subCat) => {
            let temp = [...subCategories]
            temp[subCategoryTobeUpdated] = subCat
            setSubCategories(temp);
            handleModalClose()
        }}
    />);

    const subCatFloatingActionConfig = [
        {
            title: "Add Sub-Category",
            icon: <AddIcon sx={{mr: 1}}/>,
            onClickAction: handleModalOpen,
            modelName: "createSubCategory",
            visible: true,
        }
    ]

    return (<Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1, width: '27ch'},
            }}
            noValidate
            autoComplete="off"
        >
            <MainModalDialog
                size={'sm'}
                open={modalOpen}
                handleClose={handleModalClose}
                header={modelHeader}
                body={modelToOpen}
            />
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                >
                </DialogContentText>
                <div>
                    <TextField
                        required
                        id="name-input"
                        label="Name"
                        onChange={handleChangeName}
                        value={name}
                        error={!nameValidity.isValid}
                        onBlur={validateName}
                        helperText={(nameValidity.isValid) ? "Enter name here" : nameValidity.errorMessage}
                    />
                    <TextField
                        required
                        id="description-input"
                        label="Description"
                        onChange={handleChangeDescription}
                        value={description}
                        error={!descriptionValidity.isValid}
                        onBlur={validateDescription}
                        helperText={(descriptionValidity.isValid) ?  "Enter description here" : descriptionValidity.errorMessage}
                    />
                </div>
                <Card sx={{display: 'block', marginTop: '10px'}}>
                    <Box sx={{display: 'block', flexDirection: 'column'}}>
                        <CardContent>
                            <Typography variant="h6" component="div">
                                Sub-Categories
                            </Typography>
                            <FloatingActionButtons config={subCatFloatingActionConfig}/>
                            <MainTable
                                iscontentLoading={isLoading}
                                tableHeader={subCatTableHeader} tableBody={subCatTableBody}
                            />
                        </CardContent>
                    </Box>
                </Card>
            </DialogContent>
            <DialogActions>
                {props.isUpdate ? (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={updateItemCategoryFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Update/>}
                >Update</LoadingButton>) : (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={createItemCategoryFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Create/>}
                >Create</LoadingButton>)}
                <LoadingButton
                    color={'warning'}
                    size="medium"
                    variant="outlined"
                    onClick={props.handleClose}
                    loading={false}
                    loadingPosition="end"
                    disabled={false}
                    endIcon={<Cancel/>}
                >Cancel</LoadingButton>
            </DialogActions>
        </Box>
    )
}