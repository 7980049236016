import {
    AppBar,
    Box, Button,
    FormControl,
    IconButton, ImageList, ImageListItem, ImageListItemBar, InputLabel, MenuItem, Select, TextField,
    Toolbar,
    Typography
} from "@mui/material";
import { Cancel, Delete, Done, Edit } from "@mui/icons-material";
import { useState } from "react";
import { FilesUploadForm } from "../common/filesUploadForm";
import { FILE_PATH_CONST, FILES_BASE_URL } from "../../../utils/constants";
import { deleteFile } from "../../../client/fileUpload";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

export const CommonImagesUploadForm = (props) => {

    const [collapse, setCollapse] = useState(false);

    const [images, setImages] = useState(props.data.images);
    const [caption, setCaption] = useState(props.data.caption);
    const [borderShadow, setBorderShadow] = useState(props.data.borderShadow);
    const [position, setPosition] = useState(props.data.position);
    const [columns, setColumns] = useState(props.data.columns);
    const [gap, setGap] = useState(parseInt(props.data.gap));
    const [width, setWidth] = useState(props.data.width ? props.data.width : 400)

    const handleChangeBorderShadow = (event) => {
        setBorderShadow(event.target.value);
    };
    const handleChangeColumns = (event) => {
        setColumns(event.target.value);
    };

    const handleChangeGap = (event) => {
        setGap(parseInt(event.target.value))
    };

    const handleChangePosition = (event) => {
        setPosition(event.target.value);
    };
    const handleChangeCaption = (event) => {
        setCaption(event.target.value);
    };
    const handleChangeWidth = (event) => {
        setWidth(event.target.value);
    };

    const handleOnModified = (isCancel) => {
        if (!isCancel) {
            const temp = {
                id: props.data.id,
                images: images,
                caption: caption,
                borderShadow: borderShadow,
                position: position,
                columns: columns,
                gap: gap,
                type: "IMAGES",
                width: width,
            }
            props.handleOnFormSubmit(temp)
        }
        setCollapse(!collapse)
    }

    const onUploadSuccess = (data) => {
        setImages(current => [...current, data.file])
    }

    const deleteImage = (item, index) => {
        deleteFile(() => {
            images.splice(index, 1);
            setImages([...images]);
        },
            item,
            (error) => {
                console.log("file wasn't deleted")
            }
        )
    }

    return (
        <Box>
            <Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: position,
                        alignItems: position,
                    }}
                >
                    {images && images.length > 0 && (
                        <Box sx={{ width: width }}>
                            <ImageList variant="masonry" cols={columns ? columns : 1} gap={gap ? gap : 10}>
                                {images.map((item, index) => (
                                    <ImageListItem key={index}>
                                        <img
                                            style={{
                                                boxShadow: borderShadow,
                                                shapeOutside: `${FILES_BASE_URL}${item}`,
                                            }}
                                            src={`${FILES_BASE_URL}${item}`}
                                            srcSet={`${FILES_BASE_URL}${item}`}
                                            alt={`${FILES_BASE_URL}${item}`}
                                            loading="lazy"
                                        />
                                        <ImageListItemBar
                                            title={""}
                                            subtitle={""}
                                            actionIcon={
                                                <IconButton
                                                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                                    aria-label={`info about ${"test"}`}
                                                    onClick={() => deleteImage(item, index)}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            }
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </Box>

                    )}
                    {(!images || images.length === 0) && (
                        <Box>
                            <AddPhotoAlternateIcon sx={{ fontSize: 100 }} />
                            <Typography>No Image</Typography>
                        </Box>

                    )}

                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: position,
                        alignItems: position,
                    }}
                >
                    {images.length !== 0 && (
                        <Typography sx={{
                            fontSize: '1.2rem',
                            fontStyle: 'italic',
                        }}
                            color={"grey"}
                            fontFamily={"FMBindumathi"}
                        >
                            {caption}
                        </Typography>
                    )}
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <IconButton
                        size="large"
                        color="red"
                        onClick={() => setCollapse(!collapse)}
                    >
                        <Edit />
                    </IconButton>
                    <IconButton
                        size="large"
                        color="red"
                        disabled={images && images.length > 0}
                        onClick={props.handleOnDelete}
                    >
                        <Delete />
                    </IconButton>
                </Box>
            </Box>
            {collapse && (
                <AppBar position="fixed" color="inherit" sx={{
                    top: 'auto', bottom: 0, justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Toolbar>
                        <FilesUploadForm
                            containerId={props.containerId}
                            path={`${FILE_PATH_CONST.images}/${props.containerId}`}
                            handleOnUploadSuccess={onUploadSuccess}
                        />
                        <TextField
                            sx={{
                                '& .MuiOutlinedInput-input': { fontFamily: "FMSamantha" },
                            }}
                            id="caption-input"
                            onChange={handleChangeCaption}
                            value={caption}
                            size="small"
                        />
                        <FormControl sx={{ m: 1, minWidth: '27ch', marginRight: 0 }}>
                            <InputLabel id="position-label"> Position</InputLabel>
                            <Select
                                labelId="font-label"
                                id="postion-input"
                                defaultValue=""
                                onChange={handleChangePosition}
                                value={position}
                                autoWidth
                                label="Position"
                                size="small"
                                style={{ width: '150px' }}
                            >
                                <MenuItem value={'center'}>Center </MenuItem>
                                <MenuItem value={'left'}>Left</MenuItem>
                                <MenuItem value={'right'}>Right</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto' } }}
                            required
                            id="border-shadow-input"
                            label="Border shadow"
                            onChange={handleChangeBorderShadow}
                            value={borderShadow}
                            size="small"
                            style={{ width: '150px' }}
                        />
                        <TextField
                            type={'number'}
                            label="Columns"
                            id="columns-input"
                            onChange={handleChangeColumns}
                            value={columns}
                            size="small"
                            style={{ width: '150px' }}
                        />
                        <TextField
                            type={'number'}
                            id="gap-input"
                            label="Gap"
                            onChange={handleChangeGap}
                            value={gap}
                            size="small"
                            style={{ width: '150px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto' } }}
                            required
                            id="width-input"
                            label="Width"
                            onChange={handleChangeWidth}
                            value={width}
                            style={{ width: '100px' }}
                            size="small"
                        />
                        <Button
                            sx={{ m: 1 }}
                            size="medium"
                            variant="outlined"
                            onClick={() => handleOnModified(false)}
                            endIcon={<Done />}
                        >Done</Button>
                        <Button
                            color={'warning'}
                            sx={{ m: 1 }}
                            size="medium"
                            variant="outlined"
                            onClick={() => handleOnModified(true)}
                            endIcon={<Cancel />}
                        >Cancel</Button>
                    </Toolbar>
                </AppBar>
            )}
        </Box>
    )
}