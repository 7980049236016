import {useState, useEffect} from "react";

import {
    Box,
    TextField,
    DialogContent,
    DialogContentText,
    DialogActions, InputLabel, Select, MenuItem, FormHelperText, FormControl, Button,
} from "@mui/material";
import {Add, Cancel, Create, Update,} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";

import {
    createFoundation,
    loadFoundationById,
    updateFoundation
} from "../../../client/foundation/call";
import {toast} from "react-toastify";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CreateUpdatePageBodyForm from "../../ui/common/createUpdatePageBodyForm";
import MainModalDialog from "../../ui/modal/mainModal";
import {CreateThumbnailForm} from "../../ui/forms/CreateThumbnailForm";


// create item category form
export default function CreateFoundationForm(props) {

    const [isLoading, setIsLoading] = useState(false);

    // create user inputs
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [nameUnicode, setNameUnicode] = useState('');
    const [type, setType] = useState('');
    const [webPageContents, setWebPageContents] = useState([]);
    const [thumbnail, setThumbnail] = useState(null);

    // modal related properties
    const [modalOpen, setModalOpen] = useState(false);
    const [modelToOpen, setModelToOpen] = useState('');
    const [modelHeader, setModelHeader] = useState('');

    useEffect(() => {
        if (props.isUpdate) {
            setIsLoading(true)
            loadFoundationById(
                result => {
                    setId(result.content.id)
                    setName(result.content.name)
                    setNameUnicode(result.content.nameUnicode)
                    setType(result.content.type)
                    setStatus(result.content.status)
                    setThumbnail(result.content.thumbnail)
                    setWebPageContents(result.content.webPageContents ? result.content.webPageContents : [])

                    setIsLoading(false)
                },
                error => {
                },
                props.foundationId,
            );
        }
    }, [])

    const [status, setStatus] = useState('');

    //--------------------------------------------------
    const handleChangeName = (foundation) => {
        setName(foundation.target.value);
    };
    const handleChangeNameUnicode = (foundation) => {
        setNameUnicode(foundation.target.value);
    };
    const handleChangeType = (foundation) => {
        setType(foundation.target.value);
    };

    // validate user inputs
    const [nameValidity, setNameValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateName = () => {
        let validity = {};
        if (!name || name === '') {
            validity.isValid = false;
            validity.errorMessage = "Name cannot be empty"
            setNameValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setNameValidity(validity)
            return true
        }
    };
    
    const [nameUnicodeValidity, setNameUnicodeValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateNameUnicode = () => {
        let validity = {};
        if (!nameUnicode || nameUnicode === '') {
            validity.isValid = false;
            validity.errorMessage = "Name Unicode cannot be empty"
            setNameUnicodeValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setNameUnicodeValidity(validity)
            return true
        }
    };
    
    const [typeValidity, setTypeValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateType = () => {
        let validity = {};
        if (!type || type === '') {
            validity.isValid = false;
            validity.errorMessage = "Type cannot be empty"
            setTypeValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setTypeValidity(validity)
            return true
        }
    };
    
    
    const setInputsToDefaultOnFormSubmit = () => {
        setId('')
        setName('')
        setNameUnicode(null)
        setType(null)
        setStatus('')
    }

    const validateInputFields = () => {
        let formValidators = []
        let isFormValid = true
        if (!validateName()) {
            formValidators[0] = false
        }
        if (!validateNameUnicode()) {
            formValidators[1] = false
        }
        if (!validateType()) {
            formValidators[2] = false
        }
        
        formValidators.map((val) => {
            if (!val) {
                isFormValid = false
            }
        })
        return isFormValid;
    }
    const createFoundationFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        let foundation = {
            name: name,
            nameUnicode: nameUnicode,
            type: type,
        };

        createFoundation(
            (result) => {
                toast(`Foundation: ${foundation.name} was created!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.createOrUpdatecallback(result.content);
            },
            foundation,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    const updateFoundationFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        let foundation = {
            id: id,
            name: name,
            nameUnicode: nameUnicode,
            type: type,
            status: status
        };

        updateFoundation(
            (result) => {
                toast(`Foundation: ${foundation.name} was updated!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.createOrUpdatecallback(result.content);
            },
            foundation,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    const handleModalOpen = (model) => {
        if (model === 'createUpdatePageBody') {
            setModelHeader("Create/Update Page Body")
            setModelToOpen(createUpdatePageBodyForm)
        }
        setModalOpen(true);
    }
    const handleModalClose = () => setModalOpen(false);

    const createUpdatePageBodyForm = (<CreateUpdatePageBodyForm
        containers={webPageContents}
        parentType={"FOUNDATION"}
        isUpdate={false}
        handleClose={handleModalClose}
        parentId={id}
        createOrUpdatecallback={() => {
            handleModalClose()
        }}
    />);

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1, width: '27ch'},
            }}
            noValidate
            autoComplete="off"
        >
            <MainModalDialog
                open={modalOpen}
                handleClose={handleModalClose}
                header={modelHeader}
                body={modelToOpen}
                size={'large'}
            />
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                >
                </DialogContentText>
                <div>
                    <TextField
                        sx={{'& input': {fontFamily: 'FMSamantha'}}}
                        required
                        id="name-input"
                        label="Name"
                        onChange={handleChangeName}
                        value={name}
                        error={!nameValidity.isValid}
                        onBlur={validateName}
                        helperText={(nameValidity.isValid) ? "Enter name here" : nameValidity.errorMessage}
                    />
                    <TextField
                        required
                        id="name-unicode-input"
                        label="Name Unicode"
                        onChange={handleChangeNameUnicode}
                        value={nameUnicode}
                        error={!nameUnicodeValidity.isValid}
                        onBlur={validateNameUnicode}
                        helperText={(nameUnicodeValidity.isValid) ? "Enter name unicode here" : nameUnicodeValidity.errorMessage}
                    />
                    <FormControl sx={{m: 1, minWidth: '27ch', marginRight: 0}} error={!typeValidity.isValid}>
                        <InputLabel id="type-label"> Type</InputLabel>
                        <Select
                            labelId="type-label"
                            id="type-input"
                            defaultValue=""
                            value={type}
                            onChange={handleChangeType}
                            error={!typeValidity.isValid}
                            onBlur={validateType}
                            autoWidth
                            label=" Type"
                        >
                            <MenuItem value={''}>None </MenuItem>
                            <MenuItem value={'SOS'}>ශාසනික සේවා  පදනම් </MenuItem>
                            <MenuItem value={'SHS'}>සමාජ සේවා  පදනම්</MenuItem>
                        </Select>
                        <FormHelperText>{(typeValidity.isValid) ? "Enter type here" : typeValidity.errorMessage}</FormHelperText>
                    </FormControl>
                </div>
                <div>
                    <Button
                        sx={{marginRight: 2}}
                        size="medium"
                        variant="outlined"
                        onClick={() => {handleModalOpen("createUpdatePageBody")}}
                        loadingPosition="end"
                        endIcon={<Add/>}
                    >Add/Update Web Page</Button>
                </div>
                <CreateThumbnailForm
                    thumbnail={thumbnail}
                    parentType={"FOUNDATION"}
                    parentId={id}
                />
            </DialogContent>

            <DialogActions>
                {props.isUpdate ? (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={updateFoundationFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Update/>}
                >Update</LoadingButton>) : (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={createFoundationFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Create/>}
                >Create</LoadingButton>)}
                <LoadingButton
                    color={'warning'}
                    size="medium"
                    variant="outlined"
                    onClick={props.handleClose}
                    loading={false}
                    loadingPosition="end"
                    disabled={false}
                    endIcon={<Cancel/>}
                >Cancel</LoadingButton>
            </DialogActions>
        </Box>
    )
}