import * as React from "react";

import { Box, Paper, Typography } from "@mui/material";

const PageLayout = (props) => {
    return (
        <Box sx={{ margin: 0, width: '100%', overflow: 'hidden', backgroundColor: 'rgb(255,255,255)'}}>
            <Typography m={1} variant="h5" fontWeight={'bold'}>
                {props.header}
            </Typography>
            <Box sx={{ width: '100%', overflow: 'hidden', margin: 0 }}>
                {props.children}
            </Box>
        </Box>
    )
}

export default PageLayout;