import * as React from 'react';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import {Fade, Popper} from "@mui/material";
import {useState} from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";

export default function ActionMenu(props) {

    const [actionOpen, setActionOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleActionClick = (event) => {
        setAnchorEl(event.currentTarget);
        setActionOpen((previousOpen) => !previousOpen);
    };

    const canBeOpen = actionOpen && Boolean(anchorEl);
    const id = canBeOpen ? 'transition-popper' : undefined;

    return (
        <>
            <IconButton aria-describedby={id} edge="end" aria-label="delete" onClick={handleActionClick}
                        onBlur={handleActionClick}>
                <MoreVertIcon/>
            </IconButton>
            <Popper sx={{zIndex:'1000'}} id={id} open={actionOpen} anchorEl={anchorEl} transition>
                {({TransitionProps}) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper sx={{width: 200, maxWidth: '100%'}}>
                            <MenuList>
                                {props.children}
                            </MenuList>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </>

    );
}