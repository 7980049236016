
const BFF_CONFIGS = {
    baseUrl: process.env.REACT_APP_BASE_URL,
    bffContextPath: process.env.REACT_APP_CONTEXT_PATH,
};

const OAUTH_CONFIGS = {
    clientId: process.env.REACT_APP_CLIENT_ID,
    clientSecret: process.env.REACT_APP_CLIENT_SECRET,
};

export { BFF_CONFIGS, OAUTH_CONFIGS };
