import {
    Box, Button, Card, CardActionArea, CardContent, CardMedia,
    Collapse, FormControl, FormHelperText,
    IconButton, InputLabel, MenuItem, Select, TextField,
    Typography
} from "@mui/material";
import {Cancel, Delete, Done, Edit} from "@mui/icons-material";
import {useState} from "react";
import {WrapImageUploadForm} from "./wrapImageUploadForm";
import {FontOptionList} from "../common/fontOptionList";

export const CommonCardFormattingForm = (props) => {

    const [collapse, setCollapse] = useState(false);

    const [text, setText] = useState(props.data.text);
    const [fontSize, setFontSize] = useState(props.data.fontSize);
    const [color, setColor] = useState(props.data.color);
    const [font, setFont] = useState(props.data.font);
    const [position, setPosition] = useState(props.data.position);
    const [alignment, setAlignment] = useState(props.data.alignment);
    const [wrapImage, setWrapImage] = useState(props.data.wrapImage)

    const handleChangeFontSize = (event) => {
        setFontSize(event.target.value);
    };

    const handleChangeColor = (event) => {
        setColor(event.target.value);
    };

    const handleChangeFont = (event) => {
        setFont(event.target.value);
    };
    const handleChangePosition = (event) => {
        setPosition(event.target.value);
    };
    const handleChangeAlignment = (event) => {
        setAlignment(event.target.value);
    };

    const handleChangeText = (event) => {
        setText(event.target.value);
    };

    const handleOnModifiedWrapImage = (data) => {
        setWrapImage(data);
    };

    const handleOnModified = (isCancel) => {
        if (!isCancel) {
            const temp = {
                id: props.data.id,
                text: text,
                font: font,
                fontSize: fontSize,
                color: color,
                position: position,
                alignment: alignment,
                wrapImage: wrapImage,
                type: props.data.type,
            }
            props.handleOnFormSubmit(temp)
        }
        setCollapse(!collapse)
    }

    return (
        <Box>
            <Box sx={{display: {xs: 'none', md: 'flex', justifyContent: position, marginTop: '1rem'}}}>
                <Card sx={{maxWidth: 345}}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="140"
                            image="/static/images/cards/contemplative-reptile.jpg"
                            alt="green iguana"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Lizard
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Lizards are a widespread group of squamate reptiles, with over 6,000
                                species, ranging across all continents except Antarctica
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
                <IconButton
                    size="large"
                    color="red"
                    onClick={() => setCollapse(!collapse)}
                >
                    <Edit/>
                </IconButton>
                <IconButton
                    size="large"
                    color="red"
                    onClick={props.handleOnDelete}
                >
                    <Delete/>
                </IconButton>
            </Box>
            <Collapse in={collapse} timeout="auto" unmountOnExit>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': {m: 1, width: '27ch'},
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                    }}
                    noValidate
                    autoComplete="off"

                >
                    <div>
                        <TextField
                            sx={{
                                '& .MuiOutlinedInput-input': {fontFamily: font, padding: '.5rem'},
                                minWidth: '90%'
                            }}
                            multiline
                            rows={5}
                            required
                            id="height-input"
                            onChange={handleChangeText}
                            value={text}
                        />
                        <TextField
                            sx={{'& input': {fontFamily: 'Roboto'}}}
                            required
                            id="size-input"
                            label="Size"
                            onChange={handleChangeFontSize}
                            value={fontSize}
                            helperText={"Enter font size here"}
                        />
                        <FontOptionList value={font} onChangeValue={handleChangeFont}/>
                        <FormControl sx={{m: 1, minWidth: '27ch', marginRight: 0}}>
                            <InputLabel id="position-label"> Position</InputLabel>
                            <Select
                                labelId="font-label"
                                id="postion-input"
                                defaultValue=""
                                onChange={handleChangePosition}
                                value={position}
                                autoWidth
                                label="Position"
                            >
                                <MenuItem value={'center'}>Center </MenuItem>
                                <MenuItem value={'left'}>Left</MenuItem>
                                <MenuItem value={'right'}>Right</MenuItem>
                            </Select>
                            <FormHelperText>"Enter position here"</FormHelperText>
                        </FormControl>
                        <FormControl sx={{m: 1, minWidth: '27ch', marginRight: 0}}>
                            <InputLabel id="position-label"> Text align</InputLabel>
                            <Select
                                labelId="font-label"
                                id="align-input"
                                defaultValue=""
                                onChange={handleChangeAlignment}
                                value={alignment ? alignment : ""}
                                autoWidth
                                label="Text align"
                            >
                                <MenuItem value={'center'}>Center </MenuItem>
                                <MenuItem value={'left'}>Left</MenuItem>
                                <MenuItem value={'right'}>Right</MenuItem>
                                <MenuItem value={'justify'}>Justified</MenuItem>
                            </Select>
                            <FormHelperText>"Enter text align here"</FormHelperText>
                        </FormControl>
                        <TextField
                            sx={{'& input': {fontFamily: 'Roboto'}}}
                            required
                            id="color-input"
                            label="color"
                            onChange={handleChangeColor}
                            value={color}
                            helperText={"Enter height here"}
                        />
                    </div>
                    <WrapImageUploadForm
                        containerId={props.containerId}
                        data={wrapImage}
                        handleOnChange={(data) => {
                            handleOnModifiedWrapImage(data)
                        }}
                    />
                    <Box sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                    }}>
                        <Button
                            sx={{m: 1}}
                            size="medium"
                            variant="outlined"
                            onClick={() => handleOnModified(false)}
                            endIcon={<Done/>}
                        >Done</Button>
                        <Button
                            color={'warning'}
                            sx={{m: 1}}
                            size="medium"
                            variant="outlined"
                            onClick={() => handleOnModified(true)}
                            endIcon={<Cancel/>}
                        >Cancel</Button>
                    </Box>
                </Box>
            </Collapse>
        </Box>
    )
}