import {BFF_CONFIGS} from "../../config";
import {BFF_ENDPOINTS} from "../../utils/constants";
import {_delete, _get, _get_open, _post, _put} from "../fetch-api";

export const searchEvents = (
    callback,
    errorCallback,
    pageNumber,
    pageSize,
    status,
    keyword,
    foundationId,
    locationId,
    eventStatus,
    sortProperty
) => {
  const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.searchEvents}`;
  const params = {
    pageNumber,
    pageSize,
    status,
    keyword,
    foundationId,
    locationId,
    eventStatus,
    sortProperty: sortProperty? sortProperty: 'createdDate',
  };
  _get(callback, endpointURL, params, null, errorCallback);
};

export const createEvent = (callback, data, errCallBack) => {
  const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.createEvent}`;
  _post(callback, endpointURL, data, {}, errCallBack);
};

export const updateEvent = (callback, body, errCallBack) => {
  const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.updateEvent}`;
  _put(callback, endpointURL, body, errCallBack);
};

export const deleteEventById = (callback, id, errCallBack) => {
  const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.deleteEventById}/${id}`;
  _delete(callback, endpointURL, errCallBack);
};

export const getEventById = (callback, id, errorCallback) => {
  const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.loadEventById}/${id}`;
  _get(callback, endpointURL, {}, null, errorCallback);
};

export const getEventSuggestions = (callback, foundationId, locationId) => {
  const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.loadEventSuggestions}`;
  const params = {
    foundationId: foundationId,
    locationId: locationId,
  };
  _get_open(callback, endpointURL, params);
};