import {
    Box,
    Card,
    CardHeader,
    FormControl,
    FormHelperText, Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {Stats} from "../../../../ui/common/reports/stats";
import {ChartContainer} from "../../common/chartContainer";
import ReactECharts from "echarts-for-react";
import {useEffect, useState} from "react";
import {loadLocationsSuggestions} from "../../../../../client/location/call";
import * as moment from "moment/moment";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {
    getAssociateSuggestions,
    getItemCategoryById,
    getItemCategorySuggestions
} from "../../../../../client/item-categories/call";
import {getExpensesGraphData} from "../../../../../client/reports/finance";

export const ExpensesByLocation = (props) => {


    const [graphData, setGraphData] = useState(null);
    const [groupedData, setGroupedData] = useState(null);
    const [summary, setSummary] = useState(null);

    const option = {
        color: ["#da0120"],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#283b56'
                }
            }
        },
        xAxis: {
            type: 'category',
            data: graphData ? graphData.map((point) => {
                return point.id;
            }) : []
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: graphData ? graphData.map((point) => {
                    return point.totalExpense;
                }) : [],
                type: 'bar'
            },
            {
                data: graphData ? graphData.map((point) => {
                    return point.totalExpense;
                }) : [],
                type: 'line',
                smooth: true,
                color: ["#0158da"],
            }
        ],
    };

    const groupedOption = {
        grid: {
            left: 'right', // Adjust the percentage value to position the chart as desired
            containLabel: true, // Optional, set to true if your chart contains labels
            bottom: '1%',
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            left: 'left'
        },
        height: '350ch',
        border: '1px',
        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 5,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 40,
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: groupedData ? groupedData.map((data) => {
                    return {value: data.totalExpense, name: data.id}
                }) : [],
            }
        ]
    };

    const [transactionDateFrom, setTransactionDateFrom] = useState(moment(new Date().setDate(new Date().getDate() - 30)).format('YYYY-MM-DD'));
    const [transactionDateTo, setTransactionDateTo] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [location, setLocation] = useState({});
    const [category, setCategory] = useState({});
    const [subCategory, setSubCategory] = useState({});
    const [associate, setAssociate] = useState({});

    const [stepValue, setStepValue] = useState("Daily");

    const [categorySuggestions, setCategorySuggestions] = useState(['']);
    const [subCategorySuggestions, setSubCategorySuggestions] = useState(['']);
    const [associateSuggestions, setAssociateSuggestions] = useState(['']);

    const [locationSuggestions, setLocationSuggestions] = useState(['']);

    useEffect(() => {
        loadLocationSuggestionsFunc()
        loadCategorySuggestions()
    }, [])

    useEffect(() => {
            let params = {
                stepValue: stepValue ? stepValue : "",
                monasteryId: location ? location.id : "",
                categoryId: category ? category.id : '',
                associateId: associate ? associate.id : '',
                subCategoryId: subCategory ? subCategory.id : '',
                transactionDateFrom: transactionDateFrom,
                transactionDateTo: transactionDateTo,
            }
            getExpensesGraphData((result) => {
                    setGraphData(result.content ? result.content.graphData : null);
                    setSummary(result.content ? result.content.summary : null);
                    setGroupedData(result.content ? result.content.groupedData : null);
                },
                () => {
                },
                params
            )
        },
        [transactionDateFrom, transactionDateTo, location, category, subCategory, associate, stepValue])

    const handleChangeTransactionDateFrom = (value) => {
        let val = null
        if (value) {
            val = value.$d
            setTransactionDateFrom(moment(val).format('YYYY-MM-DD'));
        } else {
            setTransactionDateFrom(null)
        }
    };
    const handleChangeTransactionDateTo = (value) => {
        let val = null
        if (value) {
            val = value.$d
            setTransactionDateTo(moment(val).format('YYYY-MM-DD'));
        } else {
            setTransactionDateTo(null)
        }
    };
    const handleChangeLocation = (event) => {
        setLocation(locationSuggestions.find((loc) => loc.id === event.target.value));
    };

    const handleChangeCategory = (event) => {
        setCategory(categorySuggestions.find((cat) => cat.id === event.target.value));
        setSubCategory({})
        setAssociate({})
    };


    const handleChangeAssociate = (event) => {
        setAssociate(associateSuggestions.find((asscte) => asscte.id === event.target.value));
    };

    const handleChangeSubCategory = (event) => {
        setSubCategory(subCategorySuggestions.find((cat) => cat.id === event.target.value));
        setAssociate({})
    };

    const handleChangeStepValue = (event) => {
        setStepValue(event.target.value);
    };

    useEffect(() => {
        if (category && category.id) {
            getItemCategoryById((result) => {
                    setSubCategorySuggestions(result.content ? result.content.subCategories : [])
                },
                (error) => {
                },
                category.id
            );
            if (category.type && (category.type !== '')) {
                loadAssociateSuggestionsFunc(category.type);
            }
        }
    }, [category])

    function loadLocationSuggestionsFunc() {
        loadLocationsSuggestions(result => {
            setLocationSuggestions(result.content);
        });
    }

    function loadCategorySuggestions() {
        getItemCategorySuggestions(result => {
            setCategorySuggestions(result.content);
        });
    }

    function loadAssociateSuggestionsFunc(type) {
        getAssociateSuggestions(result => {
                setAssociateSuggestions(result.content);
            },
            type
        );
    }

    useEffect(() => {
        loadLocationSuggestionsFunc()
    }, [])


    return (
        <Card sx={{
            backgroundColor: '#ffffff',
            margin: '1%',
            border: '2px solid ' +
                '#effff4'
        }}>
            <CardHeader
                title="Expense by Location"
            />
            <ChartContainer
            >
                <ReactECharts style={{height: "400px"}} option={groupedOption}/>
            </ChartContainer>
            <Box component="form"
                 sx={{
                     '& .MuiTextField-root': {m: 1, width: '25ch'},
                 }}
                 noValidate
                 autoComplete="off">
                <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            type='date'
                            id="transaction-date-from-input"
                            label="Transaction Date From"
                            inputFormat="YYYY-MM-DD"
                            value={transactionDateFrom}
                            onChange={handleChangeTransactionDateFrom}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    helperText={"Enter transaction date from here"}
                                />
                            }

                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            type='date'
                            id="transaction-date-to-input"
                            label="Transaction Date To"
                            inputFormat="YYYY-MM-DD"
                            value={transactionDateTo}
                            onChange={handleChangeTransactionDateTo}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    helperText={"Enter transaction date to here"}
                                />
                            }

                        />
                    </LocalizationProvider>
                    <FormControl sx={{m: 1, minWidth: '25ch'}}>
                        <InputLabel id="location-label">Location</InputLabel>
                        <Select
                            labelId="location-label"
                            id="location-input"
                            defaultValue=""
                            value={location ? location.id ? location.id : '' : ""}
                            onChange={handleChangeLocation}
                            autoWidth
                            label="Location"
                        >
                            <MenuItem key={"none"} value="">
                                <em>None</em>
                            </MenuItem>
                            {(locationSuggestions) && locationSuggestions.map((location, index) => (
                                <MenuItem key={index} value={location.id}>{location.nameUnicode}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: '25ch', marginRight: 0}}>
                        <InputLabel id="category-label">Item Category</InputLabel>
                        <Select
                            labelId="category-label"
                            id="category-input"
                            defaultValue=""
                            value={category ? category.id ? category.id : '' : ''}
                            onChange={handleChangeCategory}
                            label="Category"
                        >
                            <MenuItem key={"none"} value="">
                                <em>None</em>
                            </MenuItem>
                            {(categorySuggestions) && categorySuggestions.map((category, index) => (
                                <MenuItem key={index} value={category.id}>{category.name}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{"Select a category"}</FormHelperText>
                    </FormControl>
                    {category && category.subCategories && category.subCategories.length > 0 && (
                        <FormControl sx={{m: 1, minWidth: '27ch', marginRight: 0}}>
                            <InputLabel id="sub-category-label">Item sub-category</InputLabel>
                            <Select
                                labelId="sub-category-label"
                                id="sub-category-input"
                                defaultValue=""
                                value={subCategory ? subCategory.id ? subCategory.id : '' : ''}
                                onChange={handleChangeSubCategory}
                                label="Sub-Category"
                            >
                                <MenuItem key={"none"} value="">
                                    <em>None</em>
                                </MenuItem>
                                {(subCategorySuggestions) && subCategorySuggestions.map((subCategory, index) => (
                                    <MenuItem key={index} value={subCategory.id}>{subCategory.name}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{"Select a sub category"}</FormHelperText>
                        </FormControl>
                    )}
                    {category && category.type && category.type !== '' && (
                        <FormControl sx={{m: 1, minWidth: '27ch', marginRight: 0}}>
                            <InputLabel id="associate-label">{category.type.toLowerCase()}</InputLabel>
                            <Select
                                labelId="associate-label"
                                id="associate-input"
                                defaultValue=""
                                value={associate ? associate.id ? associate.id : '' : ''}
                                onChange={handleChangeAssociate}
                                label={`${category.type.toLowerCase()}`}
                            >
                                <MenuItem key={"none"} value="">
                                    <em>None</em>
                                </MenuItem>
                                {(associateSuggestions) && associateSuggestions.map((associate, index) => (
                                    <MenuItem key={index} value={associate.id}>{associate.identifier}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{`Select ${category.type.toLowerCase()}`}</FormHelperText>
                        </FormControl>
                    )}
                </div>
            </Box>
            <Grid
                justifyContent="space-around"
                container
                direction="row"
                spacing={{xs: 0,}}
                alignItems="flex-start"
            >
                <Grid
                    item
                    xs={3}
                    height={"100%"}
                >

                    <Stats
                        header={"Total Expenses"}
                        value={summary ? summary.sum : ""}
                        unit={"Rs"}
                    />
                </Grid>
                <Grid
                    item
                    xs={3}
                    height={"100%"}
                >
                    <Stats
                        header={"Number of transactions"}
                        value={summary ? summary.count : ""}
                        unit={""}
                    />
                </Grid>
            </Grid>
            <ChartContainer
            >
                <FormControl sx={{m: 1, minWidth: '27ch', marginRight: 0}}>
                    <InputLabel id="step-value-label">Step Value</InputLabel>
                    <Select
                        labelId="step-value-label"
                        id="step-value-input"
                        defaultValue=""
                        value={stepValue}
                        onChange={handleChangeStepValue}
                        label="step-value"
                    >
                        <MenuItem value={"Daily"}>Daily</MenuItem>
                        <MenuItem value={"Weekly"}>Weekly</MenuItem>
                        <MenuItem value={"Monthly"}>Monthly</MenuItem>
                        <MenuItem value={"Yearly"}>Yearly</MenuItem>
                    </Select>
                    <FormHelperText>{"Select a step value"}</FormHelperText>
                </FormControl>
                <ReactECharts style={{margin: '1%', borderRadius: '50px'}} option={option}/>
            </ChartContainer>
        </Card>
    )
}