
import DescriptionIcon from '@mui/icons-material/Description';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

const extractFileTypeByExtension = (fileName) => {
    return fileName.split('.').pop();
}

const getMaterialIcon = (fileName) => {
    let icon = <DescriptionIcon fontSize={"medium"}  />
    switch (extractFileTypeByExtension(fileName)) {
        case "mp3":
            icon = <LibraryMusicIcon fontSize={"medium"} />
            break;
        case "pdf":
            icon = <PictureAsPdfIcon fontSize={"medium"} />
            break;
        case "wav":
            icon = <LibraryMusicIcon fontSize={"medium"} />
            break;
        case "docx":
            icon = <FormatAlignJustifyIcon fontSize={"medium"} />
            break;
        case "doc":
            icon = <FormatAlignJustifyIcon fontSize={"medium"} />
            break;
        case "xlsx":
            icon = <PictureAsPdfIcon fontSize={"medium"} />
            break;
        case "xls":
            icon = <PictureAsPdfIcon fontSize={"medium"} />
    }
    return icon;
}

export default getMaterialIcon;