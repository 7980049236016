import {useState, useEffect} from "react";

import {
    AppBar,
    Box, Button, IconButton,
} from "@mui/material";
import {PageContentWrapContainer} from "../container/pageContentWrapContainer";
import {AddCircleOutlineOutlined} from "@mui/icons-material";
import {generateUUID} from "../../../utils/app-utils";
import {
    createContentWrapContainer,
    deleteContentWrapContainerById,updatePageContainerOrder
} from "../../../client/contentWrapContainers/call";
import {toast} from "react-toastify";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import EditContentToolBox from "./editContentToolBox";


export default function CreateUpdatePageBodyForm(props) {

    const [pageContentWrapContainers, setPageContentWrapContainers] = useState(props.containers);

    const handleOnDeleteContainer = (id) => {
        const tempList = pageContentWrapContainers.filter(item => item !== id);
        let metaData = {
            parentId: props.parentId,
            parentType: props.parentType,
            orderedList: tempList,
        }
        deleteContentWrapContainerById((data) => {
                setPageContentWrapContainers(tempList)
                toast(`Container: ${id} was deleted!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
            }, id,
            metaData,
            (err) => {
                toast(`Error: ${err}`,
                    {
                        closeOnClick: true,
                        type: "error",
                        theme: "light",
                        icon: <ErrorOutlineIcon/>
                    });
            })
    }

    const sampleSettings = {
        "transition": "fade-left",
        "width": "80%",
        "height": "",
        "position": "left",
        "backgroundColor": "",
        "borderShadow": `0px 2px 10px rgba(0, 62, 200, 0.5)`,
        "backgroundImage": `conic-gradient(from 50deg at 50% 50%,
                    rgba(242, 245, 246, 0.08),
                    rgba(255, 97, 0, 0.08),
                    rgba(0, 255, 55, 0.08),
                    rgba(139, 150, 254, 0.08),
                    rgba(246, 47, 214, 0.08),
                    rgba(255, 133, 0, 0.08),
                    rgba(1, 246, 216, 0.08),
                    rgba(152, 250, 0, 0.08),
                    rgba(154, 0, 252, 0.08))`,
    }
    const sampleContents = [
        {
            "id": generateUUID(),
            "type": "TEXT",
            "color": "#181818",
            "font": "FMDerana",
            "position": "right",
            "fontSize": "30px",
            "text": "mdidÈl fjí wvúh fj; Tnj idorfhka ms<s.ksuq",
        },]
    const handleAddContainer = (index) => {
        const temp = {
            "id": generateUUID(),
            "parentId": props.parentId,
            "parentType": props.parentType,
            "settings": sampleSettings,
            "contents": sampleContents,
        }
        let tempList = [...pageContentWrapContainers]
        tempList.splice(index, 0, temp.id)
        temp.orderedList = tempList
        createContentWrapContainer(
            (data) => {
                setPageContentWrapContainers(tempList)
                toast(`Container: ${temp.id} was created!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
            },
            temp,
            (err) => {
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            })
    }

    const handleOnMoveContainer = (id, fromIndex, toIndex) => {
        let tempList = [...pageContentWrapContainers]
        let temp = tempList[fromIndex]
        tempList.splice(fromIndex, 1)
        tempList.splice(toIndex, 0, temp)
        let metaData = {
            parentId: props.parentId,
            parentType: props.parentType,
            orderedList: tempList,
        }
        updatePageContainerOrder((data) => {
                setPageContentWrapContainers(tempList)
            },
            metaData,
            (error) => {

            })
    }

    return (
        <Box>
            <AppBar position="sticky" sx={{
                backgroundColor: props.hasToBeSaved ? "red" : "orange",
                margin: 0,
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: "center",
                    padding: '1rem'
                }}>
                    <p style={{
                        fontSize: '1.5rem',
                        margin: '0 1rem 0 0',
                    }}>
                        Create/Update Page Body
                    </p>
                </Box>
            </AppBar>
            {pageContentWrapContainers && pageContentWrapContainers.map((container, index) => (
                <Box key={container}>
                    <PageContentWrapContainer
                        containerIndex={index}
                        containerId={container}
                        parentId={props.parentId}
                        parentType={props.parentType}
                        isLastElement={pageContentWrapContainers.length === (index + 1)}
                        isFirstElement={index === 0}
                        onMove={(toIndex) => {
                            handleOnMoveContainer(container, index, toIndex)
                        }}
                        onDelete={() => {
                            handleOnDeleteContainer(container)
                        }}
                    />
                    <Box textAlign='center'>
                        <IconButton
                            aria-label="expand row"
                            size="large"
                            sx={{marginTop: 1}}
                            onClick={() => handleAddContainer(index + 1)}
                        >
                            <AddCircleOutlineOutlined fontSize={"large"}/>
                        </IconButton>
                    </Box>
                </Box>
            ))}
            {pageContentWrapContainers.length === 0 && (
                <Box textAlign='center'>
                    <IconButton
                        aria-label="expand row"
                        size="large"
                        sx={{marginTop: 1}}
                        onClick={() => handleAddContainer(pageContentWrapContainers.length)}
                    >
                        <AddCircleOutlineOutlined fontSize={"large"}/>
                    </IconButton>
                </Box>
            )}
        </Box>
    );
}