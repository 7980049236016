import PageLayout from "../../../ui/layout/pageLayout";
import {
    Card,
    MenuItem, TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {deleteVoucherById, searchVouchers} from "../../../../client/vouchers/call";
import ActionMenu from "../../../ui/menus/actionMenu";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import CreateVoucherForm from "../vouchers/createVoucherForm";
import AddIcon from "@mui/icons-material/Add";
import FloatingActionButtons from "../../../ui/buttons/floatingActions";
import MainModalDialog from "../../../ui/modal/mainModal";
import MainTable from "../../../ui/table/mainTable";
import Pagination from "../../../ui/pagination/pagination";
import {toast} from "react-toastify";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Swal from "sweetalert2";
import {formatAmount} from "../../../../utils/app-utils";

export default function Vouchers() {

    const navigate = useNavigate()
    const params = useParams()

    const [pagination, setPagination] = useState({})

    // loading section
    const [isVouchersLoading, setIsVouchersLoading] = useState(true);

    // vouchers list
    const [vouchersList, setVouchersList] = useState([]);

    function loadVouchers(page, rowsPerPage) {
        searchVouchers(
            result => {
                setVouchersList(result.content);
                setPagination(result.pagination);
                setIsVouchersLoading(false)
            },
            err => {
                setIsVouchersLoading(false)
            },
            page ? page : pagination.pageNumber,
            rowsPerPage ? rowsPerPage : pagination.pageSize,
            null,
            null,
            null
        );
    }

    const {page, rowsPerPage} = pagination
    useEffect(() => {
        loadVouchers()
    }, [page, rowsPerPage])

    // modal related properties
    const [modalOpen, setModalOpen] = useState(false);
    const [modelToOpen, setModelToOpen] = useState('');
    const [modelHeader, setModelHeader] = useState('');
    const [voucherTobeUpdated, setVoucherTobeUpdated] = useState('');

    function deleteVouchersFunc(voucherId) {
        deleteVoucherById(data => {
                toast(`Voucher was deleted!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                loadVouchers();
            },
            voucherId,
            error => {
                toast(`Error: ${error}`,
                    {
                        closeOnClick: true,
                        type: "error",
                        theme: "light",
                        icon: <ErrorOutlineIcon/>
                    });
            })
    }

    const tableHeader = (<TableHead>
        <TableRow>
            <TableCell align="left">Voucher Number</TableCell>
            <TableCell align="left">Description</TableCell>
            <TableCell align="left">Issued Date</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell align="left">Created Date</TableCell>
            <TableCell align="left">Action Items</TableCell>
        </TableRow>
    </TableHead>)

    const tableBody = (
        (vouchersList && vouchersList.length > 0) ? (<TableBody>
                {vouchersList.map((voucher, index) => (
                    <TableRow
                        key={index}
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                        <TableCell align="left">{voucher.voucherId}</TableCell>
                        <TableCell align="left">{voucher.description}</TableCell>
                        <TableCell align="left">{voucher.issuedDate}</TableCell>
                        <TableCell align="left">{formatAmount(voucher.amount)}</TableCell>
                        <TableCell align="left">{voucher.createdDate}</TableCell>
                        <TableCell align="left" onClick={() => {
                            setVoucherTobeUpdated(voucher.id)
                        }}>
                            <ActionMenu>
                                <MenuItem onClick={() => {
                                    showDeleteConfirmation(voucher.id, voucher.voucherId)
                                }}>
                                    <ListItemIcon>
                                        <Delete/>
                                    </ListItemIcon>
                                    <ListItemText>Delete</ListItemText>
                                    <Typography variant="body2" color="text.secondary">
                                    </Typography>
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    handleModalOpen("updateVoucher")
                                }}>
                                    <ListItemIcon>
                                        <Edit/>
                                    </ListItemIcon>
                                    <ListItemText>Update</ListItemText>
                                    <Typography variant="body2" color="text.secondary">
                                    </Typography>
                                </MenuItem>
                            </ActionMenu>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        ) : null
    )

    const handleModalOpen = (model) => {
        if (model === 'createVoucher') {
            setModelHeader("Create Voucher")
            setModelToOpen(createVoucherForm)
        } else if (model === 'updateVoucher') {
            setModelHeader("Update Voucher")
            setModelToOpen(updateVoucherForm)
        }
        setModalOpen(true);
    }
    const handleModalClose = () => setModalOpen(false);

    const createVoucherForm = (<CreateVoucherForm
        isUpdate={false}
        handleClose={handleModalClose}
        voucherId={null}
        createOrUpdatecallback={(data) => {
            loadVouchers()
            handleModalClose()
        }}
    />);

    const updateCallback = () => {
        loadVouchers();
        setModalOpen(false)
    }
    const updateVoucherForm = (<CreateVoucherForm
        isUpdate={true}
        handleClose={handleModalClose}
        voucherId={voucherTobeUpdated}
        createOrUpdatecallback={(data) => {
            updateCallback()
            handleModalClose()
        }}
    />);

    const floatingActionConfig = [
        {
            title: "Create",
            icon: <AddIcon sx={{mr: 1}}/>,
            onClickAction: handleModalOpen,
            modelName: "createVoucher",
            visible: true,
        }
    ]

    const showDeleteConfirmation = (pid, itmId) => {
        Swal.fire({
            icon: 'warning',
            title: `${itmId}`,
            text: `Are you sure you want to delete this item?`,
            showConfirmButton: true,
            showCancelButton: true,
            footer: 'Please double check before accepting this action'
        }).then((value) => {
            if (value.isConfirmed) {
                deleteVouchersFunc(pid)
            }
        })
    }


    return (
        <PageLayout header="Vouchers">
            <Card sx={{padding: 1, margin: 0, border: '1px solid', borderColor: 'rgb(229,226,226)'}}>
                <FloatingActionButtons config={floatingActionConfig}/>
                <MainModalDialog
                    size={'md'}
                    open={modalOpen}
                    handleClose={handleModalClose}
                    header={modelHeader}
                    body={modelToOpen}
                />
                <MainTable
                    iscontentLoading={isVouchersLoading}
                    tableHeader={tableHeader} tableBody={tableBody}
                />
                <Pagination
                    reloadData={(pageNumber, pageSize) => loadVouchers(pageNumber, pageSize)}
                    pagination={pagination}
                />
            </Card>
        </PageLayout>
    );

}