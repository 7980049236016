import {useState, useEffect, useContext} from "react";

import {
    Box,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    FormControl,
    DialogContent,
    DialogContentText,
    DialogActions,
    IconButton,
    TableHead,
    TableRow, TableCell, TableBody, CardActions, CardContent, Card, Grid, Switch, FormControlLabel, Autocomplete,
} from "@mui/material";
import {
    AddCircleOutlineOutlined,
    Cancel,
    Create,
    Delete,
    Edit,
    Error,
    Update,
} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";

import {createPayment, getPaymentById, updatePayment, getNextUniqueId} from "../../../../client/payments/call";
import {loadLocationsSuggestions} from "../../../../client/location/call";
import {loadFoundationsSuggestions} from "../../../../client/foundation/call";
import {getEventSuggestions} from "../../../../client/events/call";
import {getVendorSuggestions} from "../../../../client/vendors/call";
import {getVoucherSuggestions} from "../../../../client/vouchers/call";
import Typography from "@mui/material/Typography";
import CreateItemEntryForm from "./createItemEntryForm";
import MainTable from "../../../ui/table/mainTable";
import FloatingActionButtons from "../../../ui/buttons/floatingActions";
import AddIcon from "@mui/icons-material/Add";
import MainModalDialog from "../../../ui/modal/mainModal";
import CreateDiscountForm from "../discounts/createDiscountForm";
import Button from "@mui/material/Button";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import * as moment from "moment/moment";
import CreateVendorForm from "../vendors/createVendorForm";
import CreateEventForm from "../../events/createEventForm";
import CreateVoucherForm from "../vouchers/createVoucherForm";
import {
    getAssociateSuggestions,
    getItemCategoryById,
    getItemCategorySuggestions
} from "../../../../client/item-categories/call";
import CreateCategoryForm from "../itemCategories/createItemCategoryForm";
import {toast} from "react-toastify";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {allowSpecialPayments, formatAmount} from "../../../../utils/app-utils";
import {AppContext} from "../../../../context/appContext";


// create item form
export default function CreatePaymentForm(props) {

    const { auth } = useContext(AppContext)

    const [isLoading, setIsLoading] = useState(false);

    // create user inputs
    const [id, setId] = useState(''); // DB reference id
    const [billNo, setBillNo] = useState(''); // Vendor's payment id
    const [paymentId, setPaymentId] = useState(''); // Pasadika reference id
    const [description, setDescription] = useState('');
    const [paymentType, setPaymentType] = useState('PAYMENT');
    const [transactionDate, setTransactionDate] = useState(null);
    const [amount, setAmount] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [discount, setDiscount] = useState({});
    const [vendor, setVendor] = useState({});
    const [voucher, setVoucher] = useState({});
    const [location, setLocation] = useState({});
    const [foundation, setFoundation] = useState({});
    const [event, setEvent] = useState({});
    const [items, setItems] = useState([]);
    const [category, setCategory] = useState({});
    const [subCategory, setSubCategory] = useState({});
    const [associate, setAssociate] = useState({});
    const [tobeReviewed, setTobeReviewed] = useState(false);
    const [isSpecial, setIsSpecial] = useState(false);
    const [reviewComment, setReviewComment] = useState(false);

    const [vendorSuggestions, setVendorSuggestions] = useState(['']);
    const [voucherSuggestions, setVoucherSuggestions] = useState(['']);
    const [locationSuggestions, setLocationSuggestions] = useState(['']);
    const [foundationSuggestions, setFoundationSuggestions] = useState(['']);
    const [eventSuggestions, setEventSuggestions] = useState(['']);
    const [categorySuggestions, setCategorySuggestions] = useState(['']);
    const [subCategorySuggestions, setSubCategorySuggestions] = useState(['']);
    const [associateSuggestions, setAssociateSuggestions] = useState(['']);

    const [modalOpen, setModalOpen] = useState(false);
    const [modelToOpen, setModelToOpen] = useState('');
    const [modelHeader, setModelHeader] = useState('');
    const [itemEntryTobeUpdated, setItemEntryTobeUpdated] = useState(null);

    useEffect(() => {
        loadVendorSuggestionsFunc()
        loadVoucherSuggestionsFunc()
        loadLocationSuggestionsFunc()
        loadFoundationSuggestionsFunc()
        loadEventSuggestionsFunc()
        loadCategorySuggestions()
        if (props.isUpdate) {
            setIsLoading(true);
            getPaymentById(
                result => {
                    setId(result.content.id)
                    setBillNo(result.content.billNo)
                    setPaymentId(result.content.paymentId)
                    setDescription(result.content.description)
                    setPaymentType(result.content.paymentType)
                    setAmount(result.content.amount)
                    setCategory(result.content.category)
                    setSubCategory(result.content.subCategory)
                    setAssociate(result.content.associate)
                    setTransactionDate(result.content.transactionDate)
                    setVendor(result.content.vendor)
                    setDiscount(result.content.discount)
                    setVoucher(result.content.voucher)
                    setLocation(result.content.monastery)
                    setFoundation(result.content.foundation)
                    setEvent(result.content.event)
                    setItems(result.content.items)
                    setStatus(result.content.status)
                    setTobeReviewed(result.content.tobeReviewed)
                    setIsSpecial(result.content.isSpecial)
                    setReviewComment(result.content.reviewComment)

                    setIsLoading(false)
                },
                error => {
                },
                props.paymentId,
            );
        } else {
            getNextUniqueId((data) => {
                setPaymentId(data.content)
            }, () => {
            })
        }
    }, [])

    function loadVendorSuggestionsFunc() {
        getVendorSuggestions(result => {
            setVendorSuggestions(result.content);
        });
    }

    function loadVoucherSuggestionsFunc() {
        getVoucherSuggestions(result => {
            setVoucherSuggestions(result.content);
        });
    }

    function loadLocationSuggestionsFunc() {
        loadLocationsSuggestions(result => {
            setLocationSuggestions(result.content);
        });
    }

    function loadFoundationSuggestionsFunc() {
        loadFoundationsSuggestions(result => {
            setFoundationSuggestions(result.content);
        });
    }

    function loadEventSuggestionsFunc() {
        getEventSuggestions(result => {
            setEventSuggestions(result.content);
        });
    }

    function loadCategorySuggestions() {
        getItemCategorySuggestions(result => {
            setCategorySuggestions(result.content);
        });
    }

    const [status, setStatus] = useState('');

    //--------------------------------------------------

    const handleChangeVendor = (event, newValue) => {
        setVendor(newValue);
    };

    const handleChangeBillNo = (event) => {
        setBillNo(event.target.value);
    };

    const handleChangeDescription = (event) => {
        setDescription(event.target.value);
    };

    const handleChangePaymentType = (event) => {
        setPaymentType(event.target.value);
    };

    const handleChangeReviewComment = (event) => {
        setReviewComment(event.target.value);
    };

    const handleChangeTransactionDate = (value) => {
        let val = null
        if (value) {
            val = value.$d
            setTransactionDate(moment(val).format('YYYY-MM-DD'));
        } else {
            setTransactionDate(null)
        }
        validateTransactionDate(val)
    };

    const handleChangeVoucher = (event, newValue) => {
        setVoucher(newValue);
    };

    const handleChangeLocation = (event) => {
        setLocation(locationSuggestions.find((mnstry) => mnstry.id === event.target.value));
    };
    const handleChangeFoundation = (event) => {
        setFoundation(foundationSuggestions.find((fnd) => fnd.id === event.target.value));
    };
    const handleChangeEvent = (event, newValue) => {
        setEvent(newValue);
    };

    const handleChangeAmount = (event) => {
        const sanitizedValue = event.target.value.replace(/,/g, '');
        setAmount(sanitizedValue);
    }
    const handleChangeCategory = (event) => {
        setCategory(categorySuggestions.find((cat) => cat.id === event.target.value));
    };

    const handleChangeAssociate = (event) => {
        setAssociate(associateSuggestions.find((asscte) => asscte.id === event.target.value));
    };

    const handleChangeSubCategory = (event) => {
        setSubCategory(subCategorySuggestions.find((cat) => cat.id === event.target.value));
    };

    const handleChangeTobeReviewed = () => {
        setTobeReviewed(!tobeReviewed);
    };

    const handleChangeIsSpecial = () => {
        setIsSpecial(!isSpecial);
    };

    useEffect(() => {
        if (category && category.id) {
            getItemCategoryById((result) => {
                    setSubCategorySuggestions(result.content ? result.content.subCategories : [])
                },
                (error) => {
                },
                category.id
            );
            if (category.type && (category.type !== '')) {
                loadAssociateSuggestionsFunc(category.type);
            }
        }
    }, [category])

    function loadAssociateSuggestionsFunc(type) {
        getAssociateSuggestions(result => {
                setAssociateSuggestions(result.content);
            },
            type
        );
    }


    // validate user inputs
    const [amountValidity, setAmountValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateAmount = () => {
        let validity = {};
        if (!amount || amount === '') {
            validity.isValid = false;
            validity.errorMessage = "Amount cannot be empty!"
            setAmountValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setAmountValidity(validity)
            return true
        }
    };

    const [categoryValidity, setCategoryValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateCategory = () => {
        let validity = {};
        if (!category || (category && !category.id)) {
            validity.isValid = false;
            validity.errorMessage = "Item category cannot be empty"
            setCategoryValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setCategoryValidity(validity)
            return true
        }
    };

    const [subCategoryValidity, setSubCategoryValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateSubCategory = () => {
        let validity = {};
        if (category && category.subCategories && category.subCategories.length > 0) {
            if (!subCategory || (subCategory && !subCategory.id)) {
                validity.isValid = false;
                validity.errorMessage = "Item sub category cannot be empty";
                setSubCategoryValidity(validity);
                return false;
            } else {
                validity.isValid = true;
                validity.errorMessage = "";
                setSubCategoryValidity(validity);
                return true;
            }
        } else {
            return true
        }
    };

    const [associateValidity, setAssociateValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateAssociate = () => {
        let validity = {};
        if (category && category.type && category.type !== '') {
            let typ = category.type;
            if (!associate || (associate && !associate.id)) {
                validity.isValid = false;
                validity.errorMessage = `${typ.toLowerCase()} cannot be empty`;
                setAssociateValidity(validity);
                return false;
            } else {
                validity.isValid = true;
                validity.errorMessage = "";
                setAssociateValidity(validity);
                return true;
            }
        } else {
            return true;
        }
    };

    const [billNoValidity, setBillNoValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateBillNo = () => {
        let validity = {};
        if (!billNo || billNo === '') {
            validity.isValid = false;
            validity.errorMessage = "Bill number cannot be empty"
            setBillNoValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setBillNoValidity(validity)
            return true
        }
    };

    const [transactionDateValidity, setTransactionDateValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateTransactionDate = () => {
        let validity = {};
        if (!transactionDate || transactionDate === '') {
            validity.isValid = false;
            validity.errorMessage = "Transaction date cannot be empty!"
            setTransactionDateValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setTransactionDateValidity(validity)
            return true
        }
    };

    const [vendorValidity, setVendorValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateVendor = () => {
        let validity = {};
        if (!vendor || (vendor && !vendor.id)) {
            validity.isValid = false;
            validity.errorMessage = "Vendor cannot be empty"
            setVendorValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setVendorValidity(validity)
            return true
        }
    };

    const [voucherValidity, setVoucherValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateVoucher = () => {
        let validity = {};
        if (!voucher || (voucher && !voucher.id)) {
            validity.isValid = false;
            validity.errorMessage = "Voucher cannot be empty"
            setVoucherValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setVoucherValidity(validity)
            return true
        }
    };

    const [itemsValidity, setItemsValidity] = useState({
        isValid: true,
        errorMessage: ''
    })

    const validateItems = () => {
        let validity = {};
        if (paymentType === "BILL") {
            if (!items || (items && items.length === 0)) {
                validity.isValid = false;
                validity.errorMessage = "Payment should have at least one item entry!";
                setItemsValidity(validity);
                return false;
            } else {
                validity.isValid = true;
                validity.errorMessage = "";
                setItemsValidity(validity);
                return true;
            }
        } else {
            return true
        }
    };

    // validate user inputs
    const [reviewCommentValidity, setReviewCommentValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateReviewComment = () => {
        let validity = {};
        if (tobeReviewed && (!reviewComment || reviewComment === '')) {
            validity.isValid = false;
            validity.errorMessage = "Review comment cannot be empty!"
            setReviewCommentValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setReviewCommentValidity(validity)
            return true
        }
    };

    const setInputsToDefaultOnFormSubmit = () => {
        setId('')
        setBillNo('')
        setPaymentId('')
        setDescription('')
        setPaymentType('')
        setAmount(0.0)
        setCategory({})
        setSubCategory({})
        setAssociate({})
        setTransactionDate(null)
        setDiscount({})
        setVendor({})
        setVoucher({})
        setLocation({})
        setFoundation({})
        setEvent({})
        setItems([])
        setStatus('')
        setTobeReviewed(false)
        setIsSpecial(false)
        setReviewComment('')
    }

    const validateInputFields = () => {
        let formValidators = []
        let isFormValid = true
        if (!validateBillNo()) {
            formValidators[0] = false
        }
        if (!validateTransactionDate()) {
            formValidators[1] = false
        }
        if (!validateVendor()) {
            formValidators[2] = false
        }
        if (!validateVoucher()) {
            formValidators[3] = false
        }
        if (!validateItems()) {
            formValidators[4] = false
        }
        if (!validateCategory()) {
            formValidators[5] = false
        }
        if (!validateAmount()) {
            formValidators[6] = false
        }
        if (!validateSubCategory()) {
            formValidators[7] = false
        }
        if (!validateAssociate()) {
            formValidators[8] = false
        }
        if (!validateReviewComment()) {
            formValidators[9] = false
        }
        formValidators.map((val) => {
            if (!val) {
                isFormValid = false
            }
        })
        return isFormValid;
    }
    const createPaymentFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true);
        let payment = {
            billNo: billNo,
            paymentId: paymentId,
            description: description,
            paymentType: paymentType,
            amount: amount,
            categoryId: category ? category.id : '',
            associateId: associate ? associate.id : '',
            subCategoryId: subCategory ? subCategory.id : '',
            transactionDate: transactionDate,
            voucherId: voucher.id,
            vendorId: vendor.id,
            monasteryId: location ? location.id : "",
            foundationId: foundation ? foundation.id : "",
            eventId: event ? event.id : "",
            discountId: discount ? discount.id : '',
            items: items,
            tobeReviewed: tobeReviewed,
            isSpecial: isSpecial,
            reviewComment: reviewComment,
        };

        createPayment(
            (result) => {
                toast(`Payment: ${payment.paymentId} was created!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.createOrUpdatecallback(result.content);
            },
            payment,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    const updatePaymentFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        let payment = {
            id: id,
            billNo: billNo,
            paymentId: paymentId,
            description: description,
            paymentType: paymentType,
            amount: amount,
            categoryId: category ? category.id : '',
            subCategoryId: subCategory ? subCategory.id : '',
            associateId: associate ? associate.id : '',
            transactionDate: transactionDate,
            voucherId: voucher.id,
            vendorId: vendor.id,
            monasteryId: location ? location.id : "",
            foundationId: foundation ? foundation.id : "",
            eventId: event ? event.id : "",
            discountId: discount ? discount.id : '',
            items: items,
            status: status,
            tobeReviewed: tobeReviewed,
            isSpecial: isSpecial,
            reviewComment: reviewComment,
        };

        updatePayment(
            (result) => {
                toast(`Payment: ${payment.paymentId} was updated!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.createOrUpdatecallback(result.content);
            },
            payment,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    function deleteItemEntry(index) {
        if (index > -1) { // only splice array when item is found
            items.splice(index, 1); // 2nd parameter means remove one item only
            setItems([...items]);
        }
    }

    // item entry table header
    const tableHeader = (<TableHead>
        <TableRow>
            <TableCell>Item Name</TableCell>
            <TableCell align="left">Description</TableCell>
            <TableCell align="left">Unit</TableCell>
            <TableCell align="left">Quantity</TableCell>
            <TableCell align="left">Unit Price</TableCell>
            <TableCell align="left">Amount</TableCell>
            <TableCell align="left">Action Items</TableCell>
        </TableRow>
    </TableHead>)

    // item entry table bocy
    const tableBody = (
        (items && items.length > 0) ? (<TableBody>
                {items.map((itemEntry, index) => (
                    <TableRow
                        key={index}
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                        <TableCell align="left">{itemEntry.name}</TableCell>
                        <TableCell align="left">{itemEntry.description}</TableCell>
                        <TableCell align="left">{itemEntry.unit}</TableCell>
                        <TableCell align="left">{formatAmount(itemEntry.quantity)}</TableCell>
                        <TableCell align="left">{formatAmount(itemEntry.unitPrice)}</TableCell>
                        <TableCell align="left">{formatAmount(itemEntry.amount)}</TableCell>
                        <TableCell align="left">
                            <IconButton
                                sx={{marginRight: '10px'}}
                                onClick={() => {
                                    deleteItemEntry(index)
                                }}>
                                <Delete/>
                            </IconButton>
                            <IconButton onClick={() => {
                                setItemEntryTobeUpdated(index)
                            }}>
                                <Edit/>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        ) : null
    )

    const handleModalOpen = (model) => {
        if (model === 'createItemEntry') {
            setModelHeader("Create ItemEntry")
            setModelToOpen(createItemEntryForm)
        } else if (model === 'updateItemEntry') {
            setModelHeader("Update Item Entry")
            setModelToOpen(updateItemEntryForm)
        } else if (model === 'createDiscount') {
            setModelHeader("Create Discount")
            setModelToOpen(createDiscountForm)
        } else if (model === 'updateDiscount') {
            setModelHeader("Update Discount")
            setModelToOpen(updateDiscountForm)
        } else if (model === 'createVendor') {
            setModelHeader("Create Vendor")
            setModelToOpen(createVendorForm)
        } else if (model === 'createEvent') {
            setModelHeader("Create Project/Event")
            setModelToOpen(createEventForm)
        } else if (model === 'createVoucher') {
            setModelHeader("Create Voucher")
            setModelToOpen(createVoucherForm)
        } else if (model === 'createCategory') {
            setModelHeader("Create Item Category")
            setModelToOpen(createCategoryForm)
        }
        setModalOpen(true);
    }
    const handleModalClose = () => {
        setModalOpen(false);
        setItemEntryTobeUpdated(null) // not a best practice
    }

    const createItemEntryForm = (<CreateItemEntryForm
        isUpdate={false}
        handleClose={handleModalClose}
        addItemEntries={(itemEntries) => {
            let temp = items.concat(itemEntries)
            setItems(temp);
            validateItems()
        }}
    />);

    useEffect(() => {
        if (itemEntryTobeUpdated !== null) {
            handleModalOpen("updateItemEntry");
        }
    }, [itemEntryTobeUpdated])

    const updateItemEntryForm = (<CreateItemEntryForm
        isUpdate={true}
        handleClose={handleModalClose}
        itemEntry={items[itemEntryTobeUpdated]}
        updateItemEntry={(itemEntry) => {
            let temp = [...items]
            temp[itemEntryTobeUpdated] = itemEntry
            setItems(temp);
            validateItems()
            handleModalClose()
        }}
    />);

    const createDiscountForm = (<CreateDiscountForm
        isUpdate={false}
        handleClose={handleModalClose}
        discountId={null}
        createOrUpdatecallback={(disc) => {
            setDiscount(disc)
            handleModalClose()
        }}
    />);

    const updateDiscountForm = (<CreateDiscountForm
        isUpdate={true}
        handleClose={handleModalClose}
        discountId={discount ? discount.id ? discount.id : '' : ''}
        createOrUpdatecallback={(disc) => {
            setDiscount(disc)
            handleModalClose()
        }}
    />);

    const createVendorForm = (<CreateVendorForm
        isUpdate={false}
        handleClose={handleModalClose}
        vendorId={null}
        createOrUpdatecallback={(vend) => {
            let temp = [...vendorSuggestions]
            temp.push(vend)
            setVendorSuggestions(temp)
            handleModalClose()
            setVendor(vend)
        }}
    />);

    const createEventForm = (<CreateEventForm
        isUpdate={false}
        handleClose={handleModalClose}
        eventId={null}
        createOrUpdatecallback={(evnt) => {
            let temp = [...eventSuggestions]
            temp.push(evnt)
            setEventSuggestions(temp)
            handleModalClose()
            setEvent(evnt)
        }}
    />);

    const createVoucherForm = (<CreateVoucherForm
        isUpdate={false}
        handleClose={handleModalClose}
        voucherId={null}
        createOrUpdatecallback={(vouch) => {
            let temp = [...voucherSuggestions]
            temp.push(vouch)
            setVoucherSuggestions(temp)
            handleModalClose()
            setVoucher(vouch)
        }}
    />);

    const createCategoryForm = (<CreateCategoryForm
        isUpdate={false}
        handleClose={handleModalClose}
        categoryId={null}
        createOrUpdatecallback={(cat) => {
            let temp = [...categorySuggestions]
            temp.push(cat)
            setCategorySuggestions(temp)
            handleModalClose()
            setCategory(cat)
        }}
    />);

    const floatingActionConfig = [
        {
            title: "Add Item",
            icon: <AddIcon sx={{mr: 1}}/>,
            onClickAction: handleModalOpen,
            modelName: "createItemEntry",
            visible: true,
        }
    ]

    return (<Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1, width: '27ch'},
                backgroundColor: tobeReviewed ? '#ff5c42' : isSpecial ? 'rgba(255,145,0,0.8)' : '',

            }}
            noValidate
            autoComplete="off"
        >
            <MainModalDialog
                size={'sm'}
                open={modalOpen}
                handleClose={handleModalClose}
                header={modelHeader}
                body={modelToOpen}
            />
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                >
                </DialogContentText>
                {auth && allowSpecialPayments(auth.authorities) && (
                    <div>
                        <FormControlLabel control={
                            <Switch checked={isSpecial}
                                    onChange={handleChangeIsSpecial}
                                    aria-label={"Special payment"}
                                    defaultChecked color="primary"/>
                        }
                                          label={"Special payment"}
                        />
                    </div>
                )}
                <div>
                    <Typography sx={{marginLeft: "15px", color: "green"}} variant="h6" component="div">
                        Pasadika Payment Number: <span style={{color: "red"}}>{paymentId}</span>
                    </Typography>
                </div>
                <div>
                    <FormControl sx={{m: 1, minWidth: '27ch', marginRight: 0}}>
                        <InputLabel id="payment-type-label">Payment Type</InputLabel>
                        <Select
                            labelId="payment-type-label"
                            id="payment-type-input"
                            defaultValue=""
                            value={paymentType}
                            onChange={handleChangePaymentType}
                            autoWidth
                            label="Payment Type"
                        >
                            <MenuItem value={'PAYMENT'}>Payment</MenuItem>
                            <MenuItem value={'BILL'}>Bill</MenuItem>
                        </Select>
                        <FormHelperText>Select the payment type</FormHelperText>
                    </FormControl>
                    <TextField
                        required
                        id="billNo-input"
                        label="Vendor bill Number"
                        defaultValue=''
                        onChange={handleChangeBillNo}
                        value={billNo}
                        error={!billNoValidity.isValid}
                        onBlur={validateBillNo}
                        helperText={(billNoValidity.isValid) ? "Enter bill number here" : billNoValidity.errorMessage}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            type='date'
                            id="transaction-date-input"
                            label="Transaction Date"
                            inputFormat="YYYY-MM-DD"
                            value={transactionDate}
                            onChange={handleChangeTransactionDate}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    helperText={(transactionDateValidity.isValid) ? "Enter transaction date here" : transactionDateValidity.errorMessage}
                                    error={!transactionDateValidity.isValid}
                                    onBlur={validateTransactionDate}
                                />
                            }

                        />
                    </LocalizationProvider>
                    <TextField
                        required
                        id="amount-input"
                        label="Amount"
                        defaultValue=''
                        onChange={handleChangeAmount}
                        value={formatAmount(amount)}
                        error={!amountValidity.isValid}
                        onBlur={validateAmount}
                        helperText={(amountValidity.isValid) ? "Enter amount here" : amountValidity.errorMessage}
                    />
                    <TextField
                        id="description-input"
                        label="Description"
                        defaultValue=''
                        onChange={handleChangeDescription}
                        value={description}
                        helperText={"Enter description here"}
                    />
                    <FormControl sx={{minWidth: '32ch', m: 0}}>
                        <Autocomplete
                            id="vendor-input"
                            value={vendor}
                            onChange={handleChangeVendor}
                            getOptionLabel={(option) => typeof option.name === 'string'
                            || option.name instanceof String ? option.name : ""}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            disablePortal
                            options={vendorSuggestions}
                            sx={{m:0}}
                            renderInput={(params) =>
                                <TextField {...params}
                                           label="Vendor"
                                           sx={{m:0, minWidth: '32ch'}}
                                           onBlur={validateVendor}
                                           error={!vendorValidity.isValid}
                                           helperText={vendorValidity.isValid ? "Select the vendor" : "Vendor must not be empty"}
                                />}
                        />
                    </FormControl>
                    <IconButton
                        aria-label="expand row"
                        size="large"
                        sx={{marginTop: 1, marginRight: 0, marginLeft: 0}}
                        onClick={() => {
                            handleModalOpen("createVendor")
                        }}
                    >
                        <AddCircleOutlineOutlined fontSize={"large"}/>
                    </IconButton>
                </div>
                <div>
                    <FormControl sx={{minWidth: '45ch', m: 0}}>
                        <Autocomplete
                            id="voucher-input"
                            value={voucher}
                            onChange={handleChangeVoucher}
                            getOptionLabel={(option) => typeof option.description === 'string'
                            || option.description instanceof String ? option.description : ""}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            disablePortal
                            options={voucherSuggestions}
                            sx={{m:0}}
                            renderInput={(params) =>
                                <TextField {...params}
                                           label="Voucher"
                                           sx={{m:0, minWidth: '45ch'}}
                                           onBlur={validateVoucher}
                                           error={!voucherValidity.isValid}
                                           helperText={voucherValidity.isValid ? "Select the voucher" : "Voucher must not be empty"}
                                />}
                        />
                    </FormControl>
                    <IconButton
                        aria-label="expand row"
                        size="large"
                        sx={{marginTop: 1, marginRight: 2, marginLeft: 0}}
                        onClick={() => {
                            handleModalOpen("createVoucher")
                        }}
                    >
                        <AddCircleOutlineOutlined fontSize={"large"}/>
                    </IconButton>
                    <FormControl sx={{m: 1, minWidth: '27ch'}}>
                        <InputLabel id="location-label">Location</InputLabel>
                        <Select
                            labelId="location-label"
                            id="location-input"
                            defaultValue=""
                            value={location ? location.id ? location.id : '' : ""}
                            onChange={handleChangeLocation}
                            autoWidth
                            label="Location"
                        >
                            <MenuItem key={"none"} value="">
                                <em>None</em>
                            </MenuItem>
                            {(locationSuggestions) && locationSuggestions.map((location, index) => (
                                <MenuItem key={index} value={location.id}>{location.nameUnicode}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{"Select a location"}</FormHelperText>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: '27ch'}}>
                        <InputLabel id="foundation-label">Foundation</InputLabel>
                        <Select
                            labelId="foundation-label"
                            id="foundation-input"
                            defaultValue=""
                            value={foundation ? foundation.id ? foundation.id : '' : ""}
                            onChange={handleChangeFoundation}
                            autoWidth
                            label="Foundation"
                        >
                            <MenuItem key={"none"} value="">
                                <em>None</em>
                            </MenuItem>
                            {(foundationSuggestions) && foundationSuggestions.map((foundation, index) => (
                                <MenuItem key={index} value={foundation.id}>{foundation.nameUnicode}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{"Select a foundation"}</FormHelperText>
                    </FormControl>
                </div>
                <div>
                    <FormControl sx={{minWidth: '45ch', m: 0}}>
                        <Autocomplete
                            id="event-input"
                            value={event}
                            onChange={handleChangeEvent}
                            getOptionLabel={(option) => typeof option.title === 'string'
                            || option.title instanceof String ? option.title : ""}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            disablePortal
                            options={eventSuggestions}
                            sx={{m:0}}
                            renderInput={(params) =>
                                <TextField {...params}
                                           label="Event"
                                           sx={{m:0, minWidth: '45ch'}}
                                           helperText={"Select the event if available"}
                                />}
                        />
                    </FormControl>
                    <IconButton
                        aria-label="expand row"
                        size="large"
                        sx={{marginTop: 1, marginRight: 2, marginLeft: 0}}
                        onClick={() => {
                            handleModalOpen("createEvent")
                        }}
                    >
                        <AddCircleOutlineOutlined fontSize={"large"}/>
                    </IconButton>
                    <FormControl sx={{m: 1, minWidth: '27ch', marginRight: 0}} error={!categoryValidity.isValid}>
                        <InputLabel id="category-label">Item Category</InputLabel>
                        <Select
                            labelId="category-label"
                            id="category-input"
                            defaultValue=""
                            value={category ? category.id ? category.id : '' : ''}
                            onChange={handleChangeCategory}
                            error={!categoryValidity.isValid}
                            onBlur={validateCategory}
                            label="Category"
                        >
                            <MenuItem key={"none"} value="">
                                <em>None</em>
                            </MenuItem>
                            {(categorySuggestions) && categorySuggestions.map((category, index) => (
                                <MenuItem key={index} value={category.id}>{category.name}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{categoryValidity.isValid ? "Select a category" : categoryValidity.errorMessage}</FormHelperText>
                    </FormControl>
                    <IconButton
                        aria-label="expand row"
                        size="large"
                        sx={{marginTop: 1, marginRight: 2, marginLeft: 0}}
                        onClick={() => {
                            handleModalOpen("createCategory")
                        }}
                    >
                        <AddCircleOutlineOutlined fontSize={"large"}/>
                    </IconButton>
                    {category && category.subCategories && category.subCategories.length > 0 && (
                        <FormControl sx={{m: 1, minWidth: '27ch', marginRight: 0}} error={!subCategoryValidity.isValid}>
                            <InputLabel id="sub-category-label">Item SubCategory</InputLabel>
                            <Select
                                labelId="sub-category-label"
                                id="sub-category-input"
                                defaultValue=""
                                value={subCategory ? subCategory.id ? subCategory.id : '' : ''}
                                onChange={handleChangeSubCategory}
                                error={!subCategoryValidity.isValid}
                                onBlur={validateSubCategory}
                                label="Sub-Category"
                            >
                                <MenuItem key={"none"} value="">
                                    <em>None</em>
                                </MenuItem>
                                {(subCategorySuggestions) && subCategorySuggestions.map((subCategory, index) => (
                                    <MenuItem key={index} value={subCategory.id}>{subCategory.name}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{subCategoryValidity.isValid ? "Select a sub category" : subCategoryValidity.errorMessage}</FormHelperText>
                        </FormControl>
                    )}
                </div>
                <div>
                    {category && category.type && category.type !== '' && (
                        <FormControl sx={{m: 1, minWidth: '27ch', marginRight: 0}} error={!associateValidity.isValid}>
                            <InputLabel id="associate-label">{category.type.toLowerCase()}</InputLabel>
                            <Select
                                labelId="associate-label"
                                id="associate-input"
                                defaultValue=""
                                value={associate ? associate.id ? associate.id : '' : ''}
                                onChange={handleChangeAssociate}
                                error={!associateValidity.isValid}
                                onBlur={validateAssociate}
                                label={`${category.type.toLowerCase()}`}
                            >
                                <MenuItem key={"none"} value="">
                                    <em>None</em>
                                </MenuItem>
                                {(associateSuggestions) && associateSuggestions.map((associate, index) => (
                                    <MenuItem key={index} value={associate.id}>{associate.identifier}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{associateValidity.isValid ? `Select ${category.type.toLowerCase()}` : associateValidity.errorMessage}</FormHelperText>
                        </FormControl>
                    )}
                </div>
                <Card sx={{display: 'block'}}>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <CardContent>
                            <Typography variant="h6" component="div">
                                Discount
                            </Typography>
                            {(discount && discount.id) ? (
                                    <>
                                        <Typography sx={{mb: 1.5}} color="text.secondary">
                                            {formatAmount(discount.amount)}
                                        </Typography>
                                        <CardActions>
                                            <Button
                                                onClick={() => {
                                                    setDiscount({})
                                                }}
                                                variant="outlined" startIcon={<Delete/>}>
                                                Delete Discount
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    handleModalOpen("updateDiscount")
                                                }}
                                                variant="outlined" startIcon={<Edit/>}>
                                                Update Discount
                                            </Button>
                                        </CardActions>
                                    </>
                                ) :
                                (
                                    <Button
                                        onClick={() => {
                                            handleModalOpen("createDiscount")
                                        }}
                                        variant="outlined" startIcon={<AddIcon/>}>
                                        Add Discount
                                    </Button>
                                )}
                        </CardContent>
                    </Box>
                </Card>
                <Card sx={{display: 'block', marginTop: 2}}>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <CardContent>
                            <Typography variant="h6" component="div">
                                Review Status
                            </Typography>
                            <div>
                                <FormControlLabel control={
                                    <Switch checked={tobeReviewed}
                                            onChange={handleChangeTobeReviewed}
                                            aria-label={tobeReviewed ? "Pending for review" : "No need a review"}
                                            defaultChecked color="primary"/>
                                }
                                                  label={tobeReviewed ? "Pending for review" : "No need a review"}
                                />
                            </div>
                            <div>
                                {tobeReviewed ? (
                                    <TextField
                                        required
                                        id="review-comment-input"
                                        label="Review Comment"
                                        defaultValue=''
                                        onChange={handleChangeReviewComment}
                                        value={reviewComment}
                                        error={!reviewCommentValidity.isValid}
                                        onBlur={validateReviewComment}
                                        helperText={(reviewCommentValidity.isValid) ? "Enter review comment here" : reviewCommentValidity.errorMessage}
                                    />
                                ) : <></>}
                            </div>
                        </CardContent>
                    </Box>
                </Card>
                {paymentType === 'BILL' && (
                    <Card sx={{display: 'block', marginTop: '10px'}}>
                        <Box sx={{display: 'block', flexDirection: 'column'}}>
                            <CardContent>
                                <Typography variant="h6" component="div">
                                    Item records
                                </Typography>

                                {!itemsValidity.isValid && (
                                    <Grid item xs={5} sx={{color: 'red', marginTop: '10px', marginBottom: '10px'}}
                                          style={{display: "flex", gap: ".3rem"}}>
                                        <Error/>
                                        <Typography sx={{margin: '2px'}} variant="body2" component="div">
                                            {itemsValidity.errorMessage}
                                        </Typography>

                                    </Grid>
                                )}
                                <FloatingActionButtons config={floatingActionConfig}/>
                                <MainTable
                                    iscontentLoading={isLoading}
                                    tableHeader={tableHeader} tableBody={tableBody}
                                />
                            </CardContent>
                        </Box>
                    </Card>
                )}
            </DialogContent>
            <DialogActions>
                {props.isUpdate ? (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={updatePaymentFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Update/>}
                >Update</LoadingButton>) : (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={createPaymentFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Create/>}
                >Create</LoadingButton>)}
                <LoadingButton
                    color={'warning'}
                    size="medium"
                    variant="outlined"
                    onClick={props.handleClose}
                    loading={false}
                    loadingPosition="end"
                    disabled={false}
                    endIcon={<Cancel/>}
                >Cancel</LoadingButton>
            </DialogActions>
        </Box>
    )
}