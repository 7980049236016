import PageLayout from "../../../ui/layout/pageLayout";
import {Fragment, useEffect, useState} from "react";
import {deletePaymentById, getPaymentById, searchPayments} from "../../../../client/payments/call";
import {
    Box, Card, CircularProgress,
    Collapse, Grid,
    IconButton,
    MenuItem,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import ActionMenu from "../../../ui/menus/actionMenu";
import ListItemIcon from "@mui/material/ListItemIcon";
import {Delete, Edit, Search} from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import CreatePaymentForm from "../payments/createPaymentForm";
import AddIcon from "@mui/icons-material/Add";
import FloatingActionButtons from "../../../ui/buttons/floatingActions";
import MainModalDialog from "../../../ui/modal/mainModal";
import MainTable from "../../../ui/table/mainTable";
import Pagination from "../../../ui/pagination/pagination";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {tableCollapseStyles} from "./style";
import PaymentSearchForm from "./paymentSearchForm";
import {toast} from "react-toastify";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import Swal from 'sweetalert2'
import {formatAmount} from "../../../../utils/app-utils";

export default function Payments() {

    const [pagination, setPagination] = useState({})

    const [searchFormCollapse, setSearchFormCollapse] = useState(false)

    // loading section
    const [isPaymentsLoading, setIsPaymentsLoading] = useState(true);

    // payments list
    const [paymentsList, setPaymentsList] = useState([]);

    const [searchParams, setSearchParams] = useState({});

    function loadPayments(page, rowsPerPage, params) {
        setIsPaymentsLoading(true)
        searchPayments(
            result => {
                setPaymentsList(result.content);
                setPagination(result.pagination);
                setIsPaymentsLoading(false)
            },
            err => {
                setIsPaymentsLoading(false)
            },
            page ? page : pagination.pageNumber,
            rowsPerPage ? rowsPerPage : pagination.pageSize,
            null,
            params ? params : searchParams,
        );
    }

    const {page, rowsPerPage} = pagination
    useEffect(loadPayments, [page, rowsPerPage])

    // modal related properties
    const [modalOpen, setModalOpen] = useState(false);
    const [modelToOpen, setModelToOpen] = useState('');
    const [modelHeader, setModelHeader] = useState('');
    const [paymentTobeUpdated, setPaymentTobeUpdated] = useState('');

    function deletePaymentsFunc(paymentId) {
        deletePaymentById(data => {
                toast(`Payment was deleted!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                loadPayments();
            },
            paymentId,
            error => {
                toast(`Error: ${error}`,
                    {
                        closeOnClick: true,
                        type: "error",
                        theme: "light",
                        icon: <ErrorOutlineIcon/>
                    });
            })
    }

    const tableHeader = (<TableHead>
        <TableRow>
            <TableCell>Collapse</TableCell>
            <TableCell>Payment Type</TableCell>
            <TableCell>Pasadika Payment No</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell align="left">Transaction Date</TableCell>
            <TableCell align="left">Created Date</TableCell>
            <TableCell align="left">Action Items</TableCell>
        </TableRow>
    </TableHead>)

    function Row(props) {
        const classes = tableCollapseStyles()
        const {row} = props;
        const [open, setOpen] = useState(false);
        const [payment, setPayment] = useState({});
        const [isPaymentLoading, setIsPaymentLoading] = useState(false);


        useEffect(() => {
            if (open && !payment.id) {
                setIsPaymentLoading(true);
                getPaymentById(
                    result => {
                        setPayment(result.content)
                        setIsPaymentLoading(false)
                    },
                    error => {
                        setIsPaymentLoading(false)
                    },
                    props.row.id,
                );
            }
        }, [open])

        const thead = (<TableHead>
            <TableRow>
                <TableCell>Item Name</TableCell>
                <TableCell align="left">Description</TableCell>
                <TableCell align="left">Unit</TableCell>
                <TableCell align="left">Quantity</TableCell>
                <TableCell align="left">Unit Price</TableCell>
                <TableCell align="left">Amount</TableCell>
            </TableRow>
        </TableHead>)

        const tbody = (
            (row && row.items && row.items.length > 0) ? (<TableBody>
                    {row.items.map((itemEntry, index) => (
                        <TableRow
                            key={index}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell align="left">{itemEntry.name}</TableCell>
                            <TableCell align="left">{itemEntry.description}</TableCell>
                            <TableCell align="left">{itemEntry.unit}</TableCell>
                            <TableCell align="left">{formatAmount(itemEntry.quantity)}</TableCell>
                            <TableCell align="left">{formatAmount(itemEntry.unitPrice)}</TableCell>
                            <TableCell align="left">{formatAmount(itemEntry.amount)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            ) : null
        )

        return (
            <Fragment>
                <TableRow sx={{
                    '& > *': {borderBottom: 'unset'},
                    backgroundColor: row.tobeReviewed ? '#ff5c42' : row.isSpecial ? 'rgba(255,145,0,0.8)' : '',
                }}>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                        </IconButton>
                    </TableCell>
                    <TableCell align="left">{row.paymentType}</TableCell>
                    <TableCell align="left">{row.paymentId}</TableCell>
                    <TableCell align="left">{formatAmount(row.amount)}</TableCell>
                    <TableCell align="left">{row.transactionDate}</TableCell>
                    <TableCell align="left">{row.createdDate}</TableCell>
                    <TableCell align="left" onClick={() => {
                        setPaymentTobeUpdated(row.id)
                    }}>
                        <ActionMenu>
                            <MenuItem onClick={() => {
                                showDeleteConfirmation(row.id, row.paymentId)
                            }}>
                                <ListItemIcon>
                                    <Delete/>
                                </ListItemIcon>
                                <ListItemText>Delete</ListItemText>
                                <Typography variant="body2" color="text.secondary">
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={() => {
                                handleModalOpen("updatePayment")
                            }}>
                                <ListItemIcon>
                                    <Edit/>
                                </ListItemIcon>
                                <ListItemText>Update</ListItemText>
                                <Typography variant="body2" color="text.secondary">
                                </Typography>
                            </MenuItem>
                        </ActionMenu>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.collapseRow}>
                    <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={7}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            {isPaymentLoading ? (
                                <>
                                    <Box className={classes.spinnerOuter}>
                                        <Box className={classes.spinnerInner}>
                                            <CircularProgress/>
                                        </Box>
                                    </Box>
                                </>
                            ) : (
                                <>
                                    {payment.id && (
                                        <>
                                            <Box className={classes.dataContainerBox}>
                                                <Grid
                                                    container spacing={{xs: 2, md: 3}}
                                                    columns={{xs: 4, sm: 8, md: 12}}>
                                                    <Grid item xs={2} sm={4} md={4}>
                                                        <Typography align="left" variant="body2" gutterBottom
                                                                    component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Payment Type:</span> {payment.paymentType}
                                                        </Typography>
                                                    </Grid>
                                                    {payment.billNo && (
                                                        <Grid item xs={2} sm={4} md={4}>
                                                            <Typography align="left" variant="body2" gutterBottom
                                                                        component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Payment No:</span> {payment.billNo}
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    {payment.description && (
                                                        <Grid item xs={2} sm={4} md={4}>
                                                            <Typography align="left" variant="body2" gutterBottom
                                                                        component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Description:</span> {payment.description}
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    <Grid item xs={2} sm={4} md={4}>
                                                        <Typography align="left" variant="body2" gutterBottom
                                                                    component="div">
                                                            <span className={classes.collapseDataKey}>Pasadika Payment No:</span> {payment.paymentId}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={4} md={4}>
                                                        <Typography align="left" variant="body2" gutterBottom
                                                                    component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Total Amount:</span> {formatAmount(payment.amount)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={4} md={4}>
                                                        <Typography align="left" variant="body2" gutterBottom
                                                                    component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Transaction Date:</span> {payment.transactionDate}
                                                        </Typography>
                                                    </Grid>
                                                    {payment.vendor && (
                                                        <Grid item xs={2} sm={4} md={4}>
                                                            <Typography align="left" variant="body2" gutterBottom
                                                                        component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Vendor:</span> {payment.vendor.name}
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    {payment.discount && (
                                                        <Grid item xs={2} sm={4} md={4}>
                                                            <Typography align="left" variant="body2" gutterBottom
                                                                        component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Discount:</span> {formatAmount(payment.discount.amount)}
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    {payment.voucher && (
                                                        <Grid item xs={2} sm={4} md={4}>
                                                            <Typography align="left" variant="body2" gutterBottom
                                                                        component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Voucher Number:</span> {payment.voucher.voucherId}
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    {payment.monastery && (
                                                        <Grid item xs={2} sm={4} md={4}>
                                                            <Typography align="left" variant="body2" gutterBottom
                                                                        component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Location:</span> {payment.monastery.nameUnicode}
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    {payment.foundation && (
                                                        <Grid item xs={2} sm={4} md={4}>
                                                            <Typography align="left" variant="body2" gutterBottom
                                                                        component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Foundation:</span> {payment.foundation.nameUnicode}
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    {payment.event && (
                                                        <Grid item xs={2} sm={4} md={4}>
                                                            <Typography align="left" variant="body2" gutterBottom
                                                                        component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Event:</span> {payment.event.title}
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    {payment.category && (
                                                        <Grid item xs={2} sm={4} md={4}>
                                                            <Typography align="left" variant="body2" gutterBottom
                                                                        component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Category:</span> {payment.category.name }
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    {payment.subCategory && (
                                                        <Grid item xs={2} sm={4} md={4}>
                                                            <Typography align="left" variant="body2" gutterBottom
                                                                        component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Sub-Category:</span> {payment.subCategory.name}
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    {payment.associate && (<Grid item xs={2} sm={4} md={4}>
                                                            <Typography align="left" variant="body2" gutterBottom
                                                                        component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>{payment.associate.type.toLowerCase()}:</span> {payment.associate.identifier}
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    <Grid item xs={2} sm={4} md={4}>
                                                        <Typography align="left" variant="body2" gutterBottom
                                                                    component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Created Date:</span> {payment.createdDate}
                                                        </Typography>
                                                    </Grid>
                                                    {payment.updatedDate && (
                                                        <Grid item xs={2} sm={4} md={4}>
                                                            <Typography align="left" variant="body2" gutterBottom
                                                                        component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Updated date:</span> {payment.updatedDate}
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    {payment.createdUser && (
                                                        <Grid item xs={2} sm={4} md={4}>
                                                            <Typography align="left" variant="body2" gutterBottom
                                                                        component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Created User:</span> {payment.createdUser.userName}
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    {payment.lastModifiedUser && (
                                                        <Grid item xs={2} sm={4} md={4}>
                                                            <Typography align="left" variant="body2" gutterBottom
                                                                        component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Updated User:</span> {payment.lastModifiedUser.userName}
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Box>
                                            {payment.paymentType === 'BILL' && (
                                                <Card className={classes.collapseTableCard}>
                                                    <Box className={classes.collapseTableCardContainerBox}>
                                                        <Typography align="center" variant="h6" gutterBottom
                                                                    component="div">
                                                            Item Entries
                                                        </Typography>
                                                        <MainTable
                                                            iscontentLoading={false}
                                                            colspan={6}
                                                            tableHeader={thead} tableBody={tbody}
                                                        />
                                                    </Box>
                                                </Card>
                                            )}
                                        </>
                                    )}
                                </>
                            )}

                        </Collapse>
                    </TableCell>
                </TableRow>
            </Fragment>
        );
    }

    const tableBody = (
        (paymentsList && paymentsList.length > 0) ? (
            <TableBody>
                {paymentsList.map((payment, index) => (
                    <Row
                        key={index}
                        row={payment}
                    >
                    </Row>
                ))}
            </TableBody>
        ) : null
    )

    const handleModalOpen = (model) => {
        if (model === 'createPayment') {
            setModelHeader("Create Payment")
            setModelToOpen(createPaymentForm)
        } else if (model === 'updatePayment') {
            setModelHeader("Update Payment")
            setModelToOpen(updatePaymentForm)
        }
        setModalOpen(true);
    }
    const handleModalClose = () => setModalOpen(false);
    const handleSearchFormCollapseClose = () => setSearchFormCollapse(false);
    const handleSearchFormCollapseOpen = () => setSearchFormCollapse(true);

    const createPaymentForm = (
        <CreatePaymentForm
            isUpdate={false}
            handleClose={handleModalClose}
            paymentId={null}
            createOrUpdatecallback={(data) => {
                loadPayments()
                handleModalClose()
            }}
        />
    );

    const updatePaymentForm = (
        <CreatePaymentForm
            isUpdate={true}
            handleClose={handleModalClose}
            paymentId={paymentTobeUpdated}
            createOrUpdatecallback={(data) => {
                loadPayments()
                handleModalClose()
            }
            }
        />
    );

    const floatingActionConfig = [
        {
            title: "Create",
            icon: <AddIcon sx={{mr: 1}}/>,
            onClickAction: handleModalOpen,
            modelName: "createPayment",
            visible: true,
        },
        {
            title: "Search",
            icon: <Search sx={{mr: 1}}/>,
            onClickAction: handleSearchFormCollapseOpen,
            visible: !searchFormCollapse
        }
    ]

    const showDeleteConfirmation = (pid, itmId) => {
        Swal.fire({
            icon: 'warning',
            title: `${itmId}`,
            text: `Are you sure you want to delete this item?`,
            showConfirmButton: true,
            showCancelButton: true,
            footer: 'Please double check before accepting this action'
        }).then((value) => {
            if (value.isConfirmed) {
                deletePaymentsFunc(pid)
            }
        })
    }


    return (
        <PageLayout header="Payments">
            <Collapse in={searchFormCollapse} timeout="auto" unmountOnExit>
                <PaymentSearchForm
                    searchPayments={(params) => {
                        setSearchParams(params)
                        loadPayments(null, null, params)
                    }}
                    closeForm={handleSearchFormCollapseClose}
                />
            </Collapse>
            <Card sx={{padding: 1, margin: 0, border: '1px solid', borderColor: 'rgb(229,226,226)'}}>
                <FloatingActionButtons config={floatingActionConfig}/>
                <MainModalDialog
                    size={'lg'}
                    open={modalOpen}
                    handleClose={handleModalClose}
                    header={modelHeader}
                    body={modelToOpen}
                />
                <MainTable
                    iscontentLoading={isPaymentsLoading}
                    colspan={7}
                    tableHeader={tableHeader} tableBody={tableBody}
                />
                <Pagination
                    reloadData={(pageNumber, pageSize) => loadPayments(pageNumber, pageSize)}
                    pagination={pagination}
                />
            </Card>
        </PageLayout>
    );
}