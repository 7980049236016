import * as React from 'react';

import { useState, useEffect } from "react";

import {
    Box, TextField, Stack, Alert, Link, DialogContent, DialogContentText, DialogActions,
} from "@mui/material";

import {Cancel, Create,
} from "@mui/icons-material";

import { LoadingButton } from "@mui/lab";

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { createWebLink } from "../../../client/deshana/call";
import { generateUUID } from "../../../utils/app-utils";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import * as moment  from 'moment';


// create web link form
export default function CreateWebLinkForm(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    const [linkId, setLinkId] = useState('');
    const [directoryId, setDirectoryId] = useState('');
    const [createdDate, setCreatedDate] = useState('');

    // create directory user inputs
    const [expireDate, setExpireDate] = useState(null);
    const handleChangeExpireDate = (value) => {
        setExpireDate(moment(value.$d).format('YYYY-MM-DD'));
    };
    const [expireDateValidity, setExpireDateValidity] = useState(0);
    const validateExpireDate = () => {
        if (!expireDate || expireDate === '') {
            setExpireDateValidity(1);
        } else {
            setExpireDateValidity(2);
        }
    };

    const [password, setPassword] = useState('');
    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    };
    const [passwordValidity, setPasswordValidity] = useState(0);
    const validatePassword = (event) => {
        const value = event.target.value
        if (!value || value === '') {
            setPasswordValidity(1);
        } else {
            setPasswordValidity(2);
        }
    };

    const checkFormValid = () => {
        if ((expireDateValidity === 2) && (passwordValidity === 2)) {
            setIsFormValid(true)
        } else {
            setIsFormValid(false)
        }
    }

    useEffect(() => {
        checkFormValid()
    },
        [expireDateValidity, passwordValidity]
    )

    useState(() => {
        setDirectoryId(props.directoryId)

        if (props.webLink && props.webLink.id) {
            setLinkId(props.webLink.id)
            setPassword(props.webLink.password)
            setCreatedDate(props.webLink.createdDate)
            setExpireDate(props.webLink.expireDate)

        } else {
            setLinkId(generateUUID())
        }
    }, [])

    const createWebLinkFun = () => {
        let webLink = {
            id: linkId,
            expireDate: expireDate,
            password: password,
        };
        setIsLoading(true)
        createWebLink(
            () => {
                setIsLoading(false)
            },
            webLink,
            directoryId,
            err => {
                setIsLoading(false)
            }
        );
    }

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '27ch' },
            }}
            noValidate
            autoComplete="off"
        >
        <DialogContent dividers={true}>
            <DialogContentText
                id="scroll-dialog-description"
                tabIndex={-1}
            >
            </DialogContentText>
            <div>
                <Stack sx={{ width: '100%', margin: '0 7px 10px 8px' }} spacing={2}>
                    <Alert
                        // action={
                        //     <Button color="inherit" size="small">
                        //         COPY
                        //     </Button>
                        // }
                        variant='outlined'
                    >
                        Web Link: <Link target='_blank' underline="none" href={`${window.location.protocol}//${window.location.host}/web-link?webLId=${linkId}`} >
                            {window.location.protocol}//{window.location.host}/weblink?webLId={linkId}</Link>
                    </Alert>
                    <Alert
                        variant='outlined'
                    >
                        Created Date: {createdDate}
                    </Alert>
                    </Stack>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            disablePast
                            type='date'
                            label="Expire date"
                            inputFormat="YYYY-MM-DD"
                            value={expireDate}
                            onChange={(value) => {handleChangeExpireDate(value); validateExpireDate()}}
                            onClick={() => setExpireDateValidity(0)}
                            renderInput={(params) => <TextField {...params} helperText="hello" />}
                            error={(expireDateValidity === 1)}
                        />
                    </LocalizationProvider>

                <TextField
                    id="outlined-required"
                    label="Password"
                    type="text"
                    value={password}
                    onChange={handleChangePassword}
                    error={passwordValidity === 1}
                    onClick={() => setPasswordValidity(0)}
                    onBlur={validatePassword}
                    helperText={(passwordValidity === 1) ? "Enter valid password here" : "Invalid password"}
                />
            </div>
        </DialogContent>
        <DialogActions>
            <LoadingButton
                size="medium"
                variant="outlined"
                onClick={createWebLinkFun}
                loading={isLoading}
                loadingPosition="end"
                disabled={!isFormValid}
                endIcon={<Create />}
            >{(props.webLink && props.webLink.id)? "Update" : "Create"}</LoadingButton>
            <LoadingButton
                size="medium"
                variant="outlined"
                onClick={props.handleClose}
                loading={false}
                loadingPosition="end"
                disabled={false}
                endIcon={<Cancel />}
            >Cancel</LoadingButton>
        </DialogActions>
    </Box>
    )
}