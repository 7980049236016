import {useContext, useState} from 'react';

import {AccountCircle} from "@mui/icons-material";
import {Box, IconButton, Menu, MenuItem, Typography} from "@mui/material";

import {logout} from "../../../client/authentication/auth";
import {AppContext} from "../../../context/appContext";

export default function LoginMenu(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const {auth} = useContext(AppContext)

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setAnchorEl(null);
        logout(() => {
        })
        props.onClickLogoutButton()
    };

    return (
        <div>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
            >
                <AccountCircle fontSize={"large"}/>
                <Typography variant={"subtitle1"}> &nbsp;{auth.user_name}</Typography>
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
            </Menu>
        </div>
    )
}