import {useState, useEffect} from "react";

import {
    Box,
    TextField,
    FormControl,
    DialogContent,
    DialogContentText,
    DialogActions, IconButton, Autocomplete,
} from "@mui/material";
import {AddCircleOutlineOutlined, Cancel, Create, Update,} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";

import {getItemSuggestions,} from "../../../../client/items/call";
import CreateItemForm from "../items/createItemForm";
import MainModalDialog from "../../../ui/modal/mainModal";
import {formatAmount} from "../../../../utils/app-utils";

// create item form
export default function CreateItemEntryForm(props) {

    const [isLoading, setIsLoading] = useState(false);

    // item related states
    const [itemEntries, setItemEntries] = useState([]);
    const [item, setItem] = useState({});
    const [description, setDescription] = useState('');
    const [unit, setUnit] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [unitPrice, setUnitPrice] = useState(0.00);
    const [amount, setAmount] = useState(0.00);

    const [modalOpen, setModalOpen] = useState(false);
    const [modelToOpen, setModelToOpen] = useState('');
    const [modelHeader, setModelHeader] = useState('');

    const [itemSuggestions, setItemSuggestions] = useState(['']);

    useEffect(() => {
        loadItemSuggestionsFunc()
    }, [])

    useEffect(() => {
        if (props.itemEntry) {
            setDescription(props.itemEntry.description)
            setUnit(props.itemEntry.unit)
            setQuantity(props.itemEntry.quantity)
            setUnitPrice(props.itemEntry.unitPrice)
            setAmount(props.itemEntry.amount)
        }
    }, [itemSuggestions, props.itemEntry])

    function loadItemSuggestionsFunc() {
        getItemSuggestions(result => {
            setItemSuggestions(result.content);
            if (props.itemEntry) {
                setItem(result.content.find((itm) => itm.id === props.itemEntry.id))
            }
        });
    }

    const handleChangeItem = (event, newValue) => {
        setItem(newValue);
    };
    const handleChangeDescription = (event) => {
        setDescription(event.target.value);
    };
    const handleChangeUnit = (event) => {
        setUnit(event.target.value);
    };
    const handleChangeQuantity = (event) => {
        setQuantity(event.target.value);
    };
    const handleChangeUnitPrice = (event) => {
        setUnitPrice(event.target.value);
    };
    const handleChangeAmount = (event) => {
        const sanitizedValue = event.target.value.replace(/,/g, '');
        setAmount(sanitizedValue);
    }

    const [itemValidity, setItemValidity] = useState({
        isValid: true,
        errorMessage: ''
    });
    const validateItem = () => {
        let validity = {};
        if (!item || (!item.id || item.id === '')) {
            validity.isValid = false;
            validity.errorMessage = "Item cannot be empty!"
            setItemValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setItemValidity(validity)
            return true
        }
    };

    const [descriptionValidity, setDescriptionValidity] = useState({
        isValid: true,
        errorMessage: ''
    });
    const validateDescription = () => {
        let validity = {};
        if (!description || description === '') {
            validity.isValid = false;
            validity.errorMessage = "Description cannot be empty!"
            setDescriptionValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setDescriptionValidity(validity)
            return true
        }
    };

    const [unitValidity, setUnitValidity] = useState({
        isValid: true,
        errorMessage: ''
    });
    const validateUnit = () => {
        let validity = {};
        if (!unit || unit === '') {
            validity.isValid = false;
            validity.errorMessage = "Unit cannot be empty!"
            setUnitValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setUnitValidity(validity)
            return true
        }
    };

    const [quantityValidity, setQuantityValidity] = useState({
        isValid: true,
        errorMessage: ''
    });
    const validateQuantity = () => {
        let validity = {};
        if (!quantity || quantity <= 0) {
            validity.isValid = false;
            validity.errorMessage = "Quantity should be greater than 0"
            setQuantityValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setQuantityValidity(validity)
            return true
        }
    };

    const [unitPriceValidity, setUnitPriceValidity] = useState({
        isValid: true,
        errorMessage: ''
    });
    const validateUnitPrice = () => {
        let validity = {};
        if (!unitPrice || unitPrice <= 0.0) {
            validity.isValid = false;
            validity.errorMessage = "Unit price should be greater than 0!"
            setUnitPriceValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setUnitPriceValidity(validity)
            return true
        }
    };

    const [amountValidity, setAmountValidity] = useState({
        isValid: true,
        errorMessage: ''
    });
    const validateAmount = (event) => {
        let validity = {};
        if (!amount || amount === '') {
            validity.isValid = false;
            validity.errorMessage = "Amount should be greater than 0!"
            setAmountValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setAmountValidity(validity)
            return true
        }
    };


    const validateInputFields = () => {
        let formValidators = []
        let isFormValid = true
        if (!validateItem()) {
            formValidators[0] = false
        }
        if (!validateDescription()) {
            formValidators[1] = false
        }
        if (!validateUnit()) {
            formValidators[2] = false
        }
        if (!validateQuantity()) {
            formValidators[3] = false
        }
        if (!validateUnitPrice()) {
            formValidators[4] = false
        }
        if (!validateAmount()) {
            formValidators[5] = false
        }
        formValidators.map((val) => {
            if (!val) {
                isFormValid = false
            }
            return ""
        })
        return isFormValid;
    }

    const setInputsToDefaultOnFormSubmit = () => {
        setItem({})
        setDescription('')
        setUnit('')
        setQuantity(0)
        setUnitPrice(0.0)
        setAmount(0.0)
    }

    const createOrUpdateItemEntryFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        let itemEntry = {
            id: item.id,
            name: item.name,
            description: description,
            unit: unit,
            quantity: quantity,
            unitPrice: unitPrice,
            amount: amount,
        };
        if (props.itemEntry) {
            props.updateItemEntry(itemEntry);
        } else {
            let temp = itemEntries.concat(itemEntry)
            setItemEntries(temp);

        }
        setIsLoading(false);
        setInputsToDefaultOnFormSubmit()
    }

    useEffect(() => {
        if (!props.itemEntry) {
            props.addItemEntries(itemEntries);
        }
    }, [itemEntries])

    const handleModalOpen = (model) => {
        if (model === 'createItem') {
            setModelHeader("Create Item")
            setModelToOpen(createItemForm)
        }
        setModalOpen(true);
    }
    const handleModalClose = () => {
        setModalOpen(false);
    }

    const createItemForm = (<CreateItemForm
        isUpdate={false}
        handleClose={handleModalClose}
        itemId={null}
        createOrUpdatecallback={(itm) => {
            let temp = [...itemSuggestions]
            temp.push(itm)
            setItemSuggestions(temp)
            handleModalClose()
            setItem(itm)
        }}
    />);

    return (<Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1, width: '27ch'},
            }}
            noValidate
            autoComplete="off"
        >
            <MainModalDialog
                size={'sm'}
                open={modalOpen}
                handleClose={handleModalClose}
                header={modelHeader}
                body={modelToOpen}
            />
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                >
                </DialogContentText>
                <div>
                    <FormControl sx={{minWidth: '45ch', m: 0}}>
                        <Autocomplete
                            id="item-input"
                            value={item}
                            onChange={handleChangeItem}
                            getOptionLabel={(option) => typeof option.name === 'string'
                            || option.name instanceof String ? option.name : ""}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            disablePortal
                            options={itemSuggestions}
                            sx={{m:0}}
                            renderInput={(params) =>
                                <TextField {...params}
                                           label="Item"
                                           sx={{m:0, minWidth: '45ch'}}
                                           onBlur={validateItem}
                                           error={!itemValidity.isValid}
                                           helperText={itemValidity.isValid ? "Select the item" : "Item must not be empty"}
                                />}
                        />
                    </FormControl>
                    <IconButton
                        aria-label="expand row"
                        size="large"
                        sx={{ marginTop: 1, marginRight: 2, marginLeft: 0 }}
                        onClick={() => {
                            handleModalOpen("createItem")
                        }}
                    >
                        <AddCircleOutlineOutlined fontSize={"large"} />
                    </IconButton>
                    <TextField
                        required
                        id="description-input"
                        defaultValue=""
                        label="Description"
                        value={description}
                        onChange={handleChangeDescription}
                        error={!descriptionValidity.isValid}
                        onBlur={validateDescription}
                        helperText={(descriptionValidity.isValid) ? "Enter description here" : itemValidity.errorMessage}
                    />
                </div>
                <div>
                    <TextField
                        required
                        id="unit-input"
                        defaultValue=""
                        label="Unit"
                        value={unit ? unit : ""}
                        onChange={handleChangeUnit}
                        error={!unitValidity.isValid}
                        onBlur={validateUnit}
                        helperText={(descriptionValidity.isValid) ? "Enter description here" : itemValidity.errorMessage}
                    />
                    <TextField
                        required
                        id="quantity-input"
                        defaultValue="0"
                        label="Quantity"
                        onChange={handleChangeQuantity}
                        value={quantity}
                        error={!quantityValidity.isValid}
                        onBlur={validateQuantity}
                        helperText={(quantityValidity.isValid) ? "Enter quantity here" : itemValidity.errorMessage}
                    />
                </div>
                <div>
                    <TextField
                        required
                        id="unitPrice-input"
                        defaultValue="0.00"
                        label="UnitPrice"
                        onChange={handleChangeUnitPrice}
                        value={unitPrice}
                        error={!unitPriceValidity.isValid}
                        onBlur={validateUnitPrice}
                        helperText={(unitPriceValidity.isValid) ? "Enter unitPrice here" : itemValidity.errorMessage}
                    />
                    <TextField
                        required
                        id="amount-input"
                        label="Amount"
                        onChange={handleChangeAmount}
                        value={formatAmount(amount)}
                        error={!amountValidity.isValid}
                        onBlur={validateAmount}
                        helperText={(amountValidity.isValid) ? "Enter amount here" : itemValidity.errorMessage}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                {props.isUpdate ? (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={createOrUpdateItemEntryFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Update/>}
                >Update</LoadingButton>) : (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={createOrUpdateItemEntryFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Create/>}
                >Create</LoadingButton>)}
                <LoadingButton
                    color={'warning'}
                    size="medium"
                    variant="outlined"
                    onClick={props.handleClose}
                    loading={false}
                    loadingPosition="end"
                    disabled={false}
                    endIcon={<Cancel/>}
                >Cancel</LoadingButton>
            </DialogActions>
        </Box>
    )
}