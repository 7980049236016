import {useState, useEffect} from "react";

import {
    Box,
    TextField,
    DialogContent,
    DialogContentText,
    DialogActions, MenuItem, Select, FormHelperText, InputLabel, FormControl,
} from "@mui/material";
import {Cancel, Create, Update,} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";

import {
    createAsset,
    getAssetById,
    updateAsset,
    getNextUniqueId
} from "../../../../client/assets/call";
import Typography from "@mui/material/Typography";
import {toast} from "react-toastify";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {formatAmount} from "../../../../utils/app-utils";


// create asset form
export default function CreateAssetForm(props) {

    const [isLoading, setIsLoading] = useState(false);

    // create user inputs
    const [id, setId] = useState('');
    const [assetId, setAssetId] = useState('');
    const [description, setDescription] = useState('');
    const [type, setType] = useState('');
    const [value, setValue] = useState(0.0);
    const [vehicleNumber, setVehicleNumber] = useState('');

    useEffect(() => {
        if (props.isUpdate) {
            setIsLoading(true)
            getAssetById(
                result => {
                    setId(result.content.id)
                    setValue(result.content.value)
                    setAssetId(result.content.assetId)
                    setDescription(result.content.description)
                    setType(result.content.type)
                    setVehicleNumber(result.content.vehicleNumber)
                    setStatus(result.content.status)

                    setIsLoading(false)
                },
                error => {
                },
                props.assetId,
            );
        } else {
            getNextUniqueId((data) => {
                setAssetId(data.content)
            }, () => {
            })
        }
    }, [])

    const [status, setStatus] = useState('');

    //--------------------------------------------------
    const handleChangeDescription = (event) => {
        setDescription(event.target.value);
    };

    const handleChangeValue = (event) => {
        const sanitizedValue = event.target.value.replace(/,/g, '');
        setValue(sanitizedValue);
    };

    const handleChangeType = (event) => {
        setType(event.target.value);
    };
    
    const handleChangeVehicleNumber = (event) => {
        setVehicleNumber(event.target.value);
    };

    // validate user inputs
    const [valueValidity, setValueValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateValue = () => {
        let validity = {};
        if (!value || value === '') {
            validity.isValid = false;
            validity.errorMessage = "Value cannot be empty"
            setValueValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setValueValidity(validity)
            return true
        }
    };

    const [assetIdValidity, setAssetIdValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateAssetId = () => {
        let validity = {};
        if (!assetId || assetId === '') {
            validity.isValid = false;
            validity.errorMessage = "AssetId cannot be empty"
            setAssetIdValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setAssetIdValidity(validity)
            return true
        }
    };

    const [descriptionValidity, setDescriptionValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateDescription = () => {
        let validity = {};
        if (!description || description === '') {
            validity.isValid = false;
            validity.errorMessage = "Description cannot be empty"
            setDescriptionValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setDescriptionValidity(validity)
            return true
        }
    };

    const [typeValidity, setTypeValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateType = () => {
        let validity = {};
        if (!type || type === '') {
            validity.isValid = false;
            validity.errorMessage = "Type cannot be empty"
            setTypeValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setTypeValidity(validity)
            return true
        }
    };

    const [vehicleNumberValidity, setVehicleNumberValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateVehicleNumber = () => {
        let validity = {};
        if (type === 'VEHICLE' && (!vehicleNumber || vehicleNumber === '')) {
            validity.isValid = false;
            validity.errorMessage = "Vehicle number cannot be empty"
            setVehicleNumberValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setVehicleNumberValidity(validity)
            return true
        }
    };

    const setInputsToDefaultOnFormSubmit = () => {
        setId('')
        setAssetId('')
        setDescription('')
        setType('')
        setValue('')
        setVehicleNumber('')
        setStatus('')
    }

    const validateInputFields = () => {
        let formValidators = []
        let isFormValid = true
        if (!validateAssetId()) {
            formValidators[0] = false
        }
        if (!validateDescription()) {
            formValidators[1] = false
        }
        if (!validateType()) {
            formValidators[2] = false
        }
        if (!validateValue()) {
            formValidators[3] = false
        }
        if (!validateVehicleNumber()) {
            formValidators[3] = false
        }
        formValidators.map((val) => {
            if (!val) {
                isFormValid = false
            }
        })
        return isFormValid;
    }
    const createAssetFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        let asset = {
            assetId: assetId,
            description: description,
            type: type,
            value: value,
            vehicleNumber: vehicleNumber,
        };

        createAsset(
            (result) => {
                toast(`Asset: ${asset.assetId} was created!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.createOrUpdatecallback(result.content);
            },
            asset,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    const updateAssetFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        let asset = {
            id: id,
            assetId: assetId,
            description: description,
            type: type,
            value: value,
            vehicleNumber: vehicleNumber,
            status: status
        };

        updateAsset(
            (result) => {
                toast(`Asset: ${asset.name} was updated!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.createOrUpdatecallback(result.content);
            },
            asset,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    return (<Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1, width: '27ch'},
            }}
            noValidate
            autoComplete="off"
        >
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                >
                </DialogContentText>
                <div>
                    <Typography sx={{marginLeft: "15px", color: "green"}} variant="h6" component="div">
                        Pasadika Asset ID: <span style={{color: "red"}}>{assetId}</span>
                    </Typography>
                </div>
                <div>
                    <TextField
                        required
                        id="value-input"
                        label="Value"
                        onChange={handleChangeValue}
                        value={formatAmount(value)}
                        error={!valueValidity.isValid}
                        onBlur={validateValue}
                        helperText={(valueValidity.isValid) ? "Enter value here" : valueValidity.errorMessage}
                    />
                    <TextField
                        required
                        id="description-input"
                        label="Description"
                        onChange={handleChangeDescription}
                        value={description}
                        error={!descriptionValidity.isValid}
                        onBlur={validateDescription}
                        helperText={(descriptionValidity.isValid) ? "Enter description here" : descriptionValidity.errorMessage}
                    />
                </div>
                <div>
                    <FormControl sx={{m: 1, minWidth: '27ch', marginRight: 0}} error={!typeValidity.isValid}>
                        <InputLabel id="type-label">Type</InputLabel>
                        <Select
                            labelId="type-label"
                            id="type-input"
                            defaultValue=""
                            value={type}
                            onChange={handleChangeType}
                            onBlur={validateType}
                            error={!typeValidity.isValid}
                            autoWidth
                            label="Asset Type"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={'VEHICLE'}>Vehicle</MenuItem>
                            <MenuItem value={'LAND'}>Land</MenuItem>
                            <MenuItem value={'BUILDING'}>Building</MenuItem>
                        </Select>
                        <FormHelperText>{typeValidity.isValid ? "Select the type" : "Type must not be empty"}</FormHelperText>
                    </FormControl>
                    {type === 'VEHICLE' && (
                        <TextField
                            required
                            id="vehicle-number-input"
                            label="Vehicle-number"
                            onChange={handleChangeVehicleNumber}
                            value={vehicleNumber}
                            error={!vehicleNumberValidity.isValid}
                            onBlur={validateVehicleNumber}
                            helperText={(vehicleNumberValidity.isValid) ? "Enter vehicle number here" : vehicleNumberValidity.errorMessage}
                        />
                    )}                    
                </div>
            </DialogContent>
            <DialogActions>
                {props.isUpdate ? (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={updateAssetFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Update/>}
                >Update</LoadingButton>) : (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={createAssetFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Create/>}
                >Create</LoadingButton>)}
                <LoadingButton
                    color={'warning'}
                    size="medium"
                    variant="outlined"
                    onClick={props.handleClose}
                    loading={false}
                    loadingPosition="end"
                    disabled={false}
                    endIcon={<Cancel/>}
                >Cancel</LoadingButton>
            </DialogActions>
        </Box>
    )
}