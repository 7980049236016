import React from 'react';
import { makeStyles } from '@mui/styles';

export const tableCollapseStyles = makeStyles({
    root: {},
    spinnerOuter: {margin: '20px', display: 'flex',},
    spinnerInner: {marginLeft: '45%', display: 'block', flexDirection: 'column'},
    spinner: {},
    collapseRow: {backgroundColor: '#b4cbba'},
    dataContainerBox: {margin: "15px", display: 'flex',},
    collapseDataKey: {fontWeight: 'bolder'},
    collapseTableCard: {display: 'block', marginTop: '10px', borderRadius: '5px', marginBottom: '15px'},
    collapseTableCardContainerBox: {display: 'block', flexDirection: 'column'},
});