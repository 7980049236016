import {useState, useEffect} from "react";

import {
    Box,
    TextField,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@mui/material";
import {Cancel, Create, Update,} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";

import {
    createAuthority,
    getAuthorityById,
    updateAuthority,
} from "../../../../client/authorities/call";
import {toast} from "react-toastify";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";


// create authority form
export default function CreateAuthorityForm(props) {

    const [isLoading, setIsLoading] = useState(false);

    // create user inputs
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [code, setCode] = useState('');

    useEffect(() => {
        if (props.isUpdate) {
            setIsLoading(true)
            getAuthorityById(
                result => {
                    setId(result.content.id)
                    setName(result.content.name)
                    setCode(result.content.code)
                    setStatus(result.content.status)

                    setIsLoading(false)
                },
                error => {
                },
                props.authorityId,
            );
        }
    }, [])

    const [status, setStatus] = useState('');

    //--------------------------------------------------
    const handleChangeName = (event) => {
        setName(event.target.value);
    };
    
    const handleChangeCode = (event) => {
        setCode(event.target.value);
    };

    // validate user inputs
    const [nameValidity, setNameValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateName = () => {
        let validity = {};
        if (!name || name === '') {
            validity.isValid = false;
            validity.errorMessage = "Name cannot be empty"
            setNameValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setNameValidity(validity)
            return true
        }
    };
    
    const [codeValidity, setCodeValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateCode = () => {
        let validity = {};
        if (!code || code === '') {
            validity.isValid = false;
            validity.errorMessage = "Code cannot be empty"
            setCodeValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setCodeValidity(validity)
            return true
        }
    };

    const setInputsToDefaultOnFormSubmit = () => {
        setId('')
        setName('')
        setCode('')
        setStatus('')
    }

    const validateInputFields = () => {
        let formValidators = []
        let isFormValid = true
        if (!validateName()) {
            formValidators[0] = false
        }
        if (!validateCode()) {
            formValidators[1] = false
        }
        formValidators.map((val) => {
            if (!val) {
                isFormValid = false
            }
        })
        return isFormValid;
    }
    const createAuthorityFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        let authority = {
            name: name,
            code: code,
        };

        createAuthority(
            (result) => {
                toast(`User authority: ${authority.code} was created!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.createOrUpdatecallback(result.content);
            },
            authority,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    const updateAuthorityFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        let authority = {
            id: id,
            name: name,
            code: code,
            status: status
        };

        updateAuthority(
            (result) => {
                toast(`User authority: ${authority.code} was updated!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.createOrUpdatecallback(result.content);
            },
            authority,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    return (<Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1, width: '27ch'},
            }}
            noValidate
            autoComplete="off"
        >
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                >
                </DialogContentText>
                <div>
                    <TextField
                        required
                        id="name-input"
                        label="Name"
                        onChange={handleChangeName}
                        value={name}
                        error={!nameValidity.isValid}
                        onBlur={validateName}
                        helperText={(nameValidity.isValid) ? "Enter name here" : nameValidity.errorMessage}
                    />
                    <TextField
                        required
                        id="code-input"
                        label="Code"
                        onChange={handleChangeCode}
                        value={code}
                        error={!codeValidity.isValid}
                        onBlur={validateCode}
                        helperText={(codeValidity.isValid) ? "Enter code here" : codeValidity.errorMessage}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                {props.isUpdate ? (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={updateAuthorityFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Update/>}
                >Update</LoadingButton>) : (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={createAuthorityFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Create/>}
                >Create</LoadingButton>)}
                <LoadingButton
                    color={'warning'}
                    size="medium"
                    variant="outlined"
                    onClick={props.handleClose}
                    loading={false}
                    loadingPosition="end"
                    disabled={false}
                    endIcon={<Cancel/>}
                >Cancel</LoadingButton>
            </DialogActions>
        </Box>
    )
}