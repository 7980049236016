import './assets/css/App.css';
import * as React from "react";

import MainLayout from "./components/ui/layout/mainLayout";

function App() {

    return (
        <div className="App">
            <MainLayout/>
        </div>
    );
}

export default App;
