import * as React from 'react';

import {useState, useEffect} from "react";

import {
    Box, TextField, DialogContent, DialogContentText, DialogActions,
} from "@mui/material";

import {
    Create,
} from "@mui/icons-material";

import {LoadingButton} from "@mui/lab";


// create web link form
export default function WebLinkLoginForm(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    const [password, setPassword] = useState('');
    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    };
    const [passwordValidity, setPasswordValidity] = useState(0);
    const validatePassword = (event) => {
        const value = event.target.value
        if (!value || value === '') {
            setPasswordValidity(1);
        } else {
            setPasswordValidity(2);
        }
    };

    const checkFormValid = () => {
        if ((passwordValidity === 2)) {
            setIsFormValid(true)
        } else {
            setIsFormValid(false)
        }
    }

    useEffect(() => {
            checkFormValid()
        },
        [passwordValidity]
    )

    const loginWithWebLink = () => {
        props.loginWithWebLink(password);
    };

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1, width: '27ch'},
            }}
            noValidate
            autoComplete="off"
        >
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                >
                </DialogContentText>
                <div>
                    <TextField
                        id="outlined-required"
                        label="Password"
                        type="text"
                        value={password}
                        onChange={handleChangePassword}
                        error={passwordValidity === 1}
                        onClick={() => setPasswordValidity(0)}
                        onBlur={validatePassword}
                        helperText={(passwordValidity === 1) ? "Enter valid password here" : "Invalid password"}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={loginWithWebLink}
                    loading={isLoading}
                    loadingPosition="end"
                    disabled={!isFormValid}
                    endIcon={<Create/>}
                >Login</LoadingButton>
            </DialogActions>
        </Box>
    )
}