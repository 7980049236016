import {useState, useEffect} from "react";

import {
    Box,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    FormControl,
    DialogContent,
    DialogContentText,
    DialogActions, IconButton,
} from "@mui/material";
import {AddCircleOutlineOutlined, Cancel, Create, Update,} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";

import {
    createItem,
    getItemById,
    updateItem,
} from "../../../../client/items/call";
import {getItemCategorySuggestions} from "../../../../client/item-categories/call";
import CreateItemCategoryForm from "../itemCategories/createItemCategoryForm";
import MainModalDialog from "../../../ui/modal/mainModal";
import {toast} from "react-toastify";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";


// create item form
export default function CreateItemForm(props) {

    const [isLoading, setIsLoading] = useState(false);

    // create user inputs
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [itemCategory, setItemCategory] = useState({});

    const [modalOpen, setModalOpen] = useState(false);
    const [modelToOpen, setModelToOpen] = useState('');
    const [modelHeader, setModelHeader] = useState('');

    const [itemCategorySuggestions, setItemCategorySuggestions] = useState(['']);

    useEffect(() => {
        loadItemCategorySuggestions()
        if (props.isUpdate) {
            setIsLoading(true)
            getItemById(
                result => {
                    setId(result.content.id)
                    setName(result.content.name)
                    setDescription(result.content.description)
                    setItemCategory(result.content.category)
                    setStatus(result.content.status)

                    setIsLoading(false)
                },
                error => {
                },
                props.itemId,
            );
        }
    }, [])

    function loadItemCategorySuggestions() {
        getItemCategorySuggestions(result => {
            setItemCategorySuggestions(result.content);
        });
    }

    const [status, setStatus] = useState('');

    //--------------------------------------------------
    const handleChangeName = (event) => {
        setName(event.target.value);
    };
    const handleChangeDescription = (event) => {
        setDescription(event.target.value);
    };

    const handleChangeItemCategory = (event) => {
        setItemCategory(itemCategorySuggestions.find((cat) => cat.id === event.target.value));
    };

    // validate user inputs
    const [nameValidity, setNameValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateName = () => {
        let validity = {};
        if (!name || name === '') {
            validity.isValid = false;
            validity.errorMessage = "Name cannot be empty"
            setNameValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setNameValidity(validity)
            return true
        }
    };

    const [descriptionValidity, setDescriptionValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateDescription = () => {
        let validity = {};
        if (!description || description === '') {
            validity.isValid = false;
            validity.errorMessage = "Description cannot be empty"
            setDescriptionValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setDescriptionValidity(validity)
            return true
        }
    };

    const [itemCategoryValidity, setItemCategoryValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateItemCategory = () => {
        let validity = {};
        if (!itemCategory || (itemCategory && !itemCategory.id)) {
            validity.isValid = false;
            validity.errorMessage = "Item category cannot be empty"
            setItemCategoryValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setItemCategoryValidity(validity)
            return true
        }
    };

    const setInputsToDefaultOnFormSubmit = () => {
        setName('')
        setDescription('')
        setItemCategory({})
        setStatus('')
    }

    const validateInputFields = () => {
        let formValidators = []
        let isFormValid = true
        if (!validateName()) {
            formValidators[0] = false
        }
        if (!validateDescription()) {
            formValidators[1] = false
        }
        if (!validateItemCategory()) {
            formValidators[2] = false
        }
        formValidators.map((val) => {
            if (!val) {
                isFormValid = false
            }
        })
        return isFormValid;
    }


    const createItemFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        let item = {
            name: name,
            description: description,
            categoryId: itemCategory ? itemCategory.id : '',
        };

        createItem(
            (result) => {
                toast(`Item: ${item.name} was created!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.createOrUpdatecallback(result.content);
            },
            item,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    const updateItemFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        let item = {
            id: id,
            name: name,
            description: description,
            categoryId: itemCategory ? itemCategory.id : '',
            status: status
        };

        updateItem(
            (result) => {
                toast(`Item: ${item.name} was updated!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.createOrUpdatecallback(result.content);
            },
            item,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    const handleModalOpen = (model) => {
        if (model === 'createItemCategory') {
            setModelHeader("Create Item Category")
            setModelToOpen(createItemCategoryForm)
        }
        setModalOpen(true);
    }
    const handleModalClose = () => {
        setModalOpen(false);
    }

    const createItemCategoryForm = (<CreateItemCategoryForm
        isUpdate={false}
        handleClose={handleModalClose}
        itemCategoryId={null}
        createOrUpdatecallback={(cat) => {
            let temp = [...itemCategorySuggestions]
            temp.push(cat)
            setItemCategorySuggestions(temp)
            handleModalClose()
            setItemCategory(cat)
        }}
    />);

    return (<Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1, width: '27ch'},
            }}
            noValidate
            autoComplete="off"
        >
            <MainModalDialog
                size={'sm'}
                open={modalOpen}
                handleClose={handleModalClose}
                header={modelHeader}
                body={modelToOpen}
            />
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                >
                </DialogContentText>
                <div>
                    <TextField
                        required
                        id="name-input"
                        label="Name"
                        onChange={handleChangeName}
                        value={name}
                        error={!nameValidity.isValid}
                        onBlur={validateName}
                        helperText={(nameValidity.isValid) ? "Enter name here" : nameValidity.errorMessage}
                    />
                    <TextField
                        required
                        id="description-input"
                        label="Description"
                        onChange={handleChangeDescription}
                        value={description}
                        error={!descriptionValidity.isValid}
                        onBlur={validateDescription}
                        helperText={(descriptionValidity.isValid) ? "Enter description here" : descriptionValidity.errorMessage}
                    />
                </div>
                <div>
                    <FormControl sx={{m: 1, minWidth: '27ch', marginRight: 0}} error={!itemCategoryValidity.isValid}>
                        <InputLabel id="item-category-label">Item Category</InputLabel>
                        <Select
                            labelId="item-category-label"
                            id="item-category-input"
                            defaultValue=""
                            value={itemCategory ? itemCategory.id ? itemCategory.id : '' : ''}
                            onChange={handleChangeItemCategory}
                            error={!itemCategoryValidity.isValid}
                            onBlur={validateItemCategory}
                            label="Item Category"
                        >
                            <MenuItem key={"none"} value="">
                                <em>None</em>
                            </MenuItem>
                            {(itemCategorySuggestions) && itemCategorySuggestions.map((category, index) => (
                                <MenuItem key={index} value={category.id}>{category.name}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{itemCategoryValidity.isValid ? "Select a item category" : itemCategoryValidity.errorMessage}</FormHelperText>
                    </FormControl>
                    <IconButton
                        aria-label="expand row"
                        size="large"
                        sx={{ marginTop: 1, marginRight: 2, marginLeft: 0 }}
                        onClick={() => {
                            handleModalOpen("createItemCategory")
                        }}
                    >
                        <AddCircleOutlineOutlined fontSize={"large"} />
                    </IconButton>
                </div>
            </DialogContent>
            <DialogActions>
                {props.isUpdate ? (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={updateItemFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Update/>}
                >Update</LoadingButton>) : (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={createItemFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Create/>}
                >Create</LoadingButton>)}
                <LoadingButton
                    color={'warning'}
                    size="medium"
                    variant="outlined"
                    onClick={props.handleClose}
                    loading={false}
                    loadingPosition="end"
                    disabled={false}
                    endIcon={<Cancel/>}
                >Cancel</LoadingButton>
            </DialogActions>
        </Box>
    )
}