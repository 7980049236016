import {Card} from "@mui/material";

export const ChartContainer = (props) => {

    return (
        <Card sx={{
            backgroundColor: '#ffffff',
            margin: '1%',
            border: '1.5px solid ' +
                '#54f586',
            borderBottom: '5px solid ' +
                '#54f586',
        }}>
            {props.children}
        </Card>
    )
}