import React, { createContext, useState } from 'react'
export const AppContext = createContext()
export const AppContextProvider = (props) => {
    const [auth, setAuth] = useState({})
    const authenticated = (data) => {
        setAuth(data)
    }
    return (
        <AppContext.Provider
            value={{
                auth,
                authenticated,
            }}>
            {props.children}
        </AppContext.Provider>
    )
}