import * as React from 'react';
import {useContext, useState} from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import DrawerNavigationItems from "../drawer/drawerNavigationItems";

import { Route, Routes } from "react-router-dom";
import Users from "../../pages/userMangement/users";
import Foundations from "../../pages/foundations";
import Deshana from "../../pages/deshana";
import DeshanaWebLink from '../../pages/deshana/webLink';

import FullScreenDialog from '../modal/fullScreenDialog';
import LoginMenu from '../appbar/loginMenu';
import Vouchers from "../../pages/finance/vouchers";
import Payments from "../../pages/finance/payments";
import Roles from "../../pages/userMangement/roles";
import Authorities from "../../pages/userMangement/authorities";
import Items from "../../pages/finance/items";
import Vendors from "../../pages/finance/vendors";
import Discounts from "../../pages/finance/discounts";
import ItemCategories from "../../pages/finance/itemCategories";
import Events from "../../pages/events";
import Assets from "../../pages/finance/assets";
import UtilityAccounts from "../../pages/finance/utilityAccounts";
import Employees from "../../pages/employees";
import {ToastContainer} from "react-toastify";
import Locations from "../../pages/locations";
import {AppContext} from "../../../context/appContext";
import Expenses from "../../pages/reports/finance/expenses";

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function MainLayout() {

    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

    const [loginDialogOpen, setLoginDialogOpen] = useState(true);

    const { auth } = useContext(AppContext)

    const handleOnLogOut = () => {
        setIsUserLoggedIn(false)
        setLoginDialogOpen(true);
    };

    const handleOnLoginSuccess = () => {
        setIsUserLoggedIn(true)
        setLoginDialogOpen(false);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <ToastContainer />
            <FullScreenDialog
                onSuccess={handleOnLoginSuccess}
                open={loginDialogOpen}
            >
            </FullScreenDialog>
            {(isUserLoggedIn) && (
                <>
                    <AppBar position="fixed" open={open}>
                        <Toolbar>
                            {auth.user_type === 'ADMIN' && (
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={handleDrawerOpen}
                                    edge="start"
                                    sx={{
                                        marginRight: 5,
                                        ...(open && { display: 'none' }),
                                    }}
                                >
                                    <MenuIcon />
                                </IconButton>
                            )}
                            <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
                                Pasadika Admin Portal
                            </Typography>
                            <LoginMenu
                                onClickLogoutButton={handleOnLogOut}
                            />
                        </Toolbar>
                    </AppBar>
                    {auth.user_type === 'ADMIN' && (<Drawer variant="permanent" open={open}>
                        <DrawerHeader>
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                            </IconButton>
                        </DrawerHeader>
                        <Divider />
                        <DrawerNavigationItems open={open} />
                    </Drawer>)}
                    <Box sx={{ flexGrow: 1, p: 1, marginTop: '55px', marginBottom: '55px' }}>
                        <Routes>
                            <Route path="/" element={<Deshana />} />
                            <Route path="/deshana" element={<Deshana />} />
                            <Route path="/deshana/:parentId" element={<Deshana />} />
                            <Route path="/users" element={<Users/>}/>
                            <Route path="/roles" element={<Roles/>}/>
                            <Route path="/authorities" element={<Authorities/>}/>
                            <Route path="/web-link" element={<DeshanaWebLink />} />
                            <Route path="/locations" element={<Locations />} />
                            <Route path="/foundations" element={<Foundations />} />
                            <Route path="/events" element={<Events />} />
                            <Route path="/vouchers" element={<Vouchers />} />
                            <Route path="/payments" element={<Payments />} />
                            <Route path="/items" element={<Items />} />
                            <Route path="/vendors" element={<Vendors />} />
                            <Route path="/discounts" element={<Discounts />} />
                            <Route path="/item-categories" element={<ItemCategories />} />
                            <Route path="/assets" element={<Assets />} />
                            <Route path="/utility-accounts" element={<UtilityAccounts />} />
                            <Route path="/employees" element={<Employees />} />
                            <Route path="/reports" element={<Expenses />} />
                            <Route path="/reports/finance/expenses" element={<Expenses />} />
                        </Routes>
                    </Box>
                </>
            )}
        </Box>
    );
}