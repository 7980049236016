import {useState, useEffect} from "react";

import {
    Box,
    TextField,
    DialogContent,
    DialogContentText,
    DialogActions, InputLabel, Select, MenuItem, FormHelperText, FormControl,
} from "@mui/material";
import {Cancel, Create, Update,} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";

import {
    createUtilityAccount,
    getUtilityAccountById,
    updateUtilityAccount
} from "../../../../client/utility-accounts/call";
import {loadLocationsSuggestions} from "../../../../client/location/call";
import {toast} from "react-toastify";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";


// create utilityAccount form
export default function CreateUtilityAccountForm(props) {

    const [isLoading, setIsLoading] = useState(false);

    // create user inputs
    const [id, setId] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [description, setDescription] = useState('');
    const [type, setType] = useState('');
    const [location, setLocation] = useState({});

    const [locationSuggestions, setLocationSuggestions] = useState(['']);
    
    useEffect(() => {
        loadLocationSuggestionsFunc()
        if (props.isUpdate) {
            setIsLoading(true)
            getUtilityAccountById(
                result => {
                    setId(result.content.id)
                    setAccountNumber(result.content.accountNumber)
                    setDescription(result.content.description)
                    setType(result.content.type)
                    setLocation(result.content.monastery)
                    setStatus(result.content.status)

                    setIsLoading(false)
                },
                error => {
                },
                props.utilityAccountId,
            );
        }
    }, [])

    const [status, setStatus] = useState('');

    function loadLocationSuggestionsFunc() {
        loadLocationsSuggestions(result => {
            setLocationSuggestions(result.content);
        });
    }

    //--------------------------------------------------
    const handleChangeAccountNumber = (event) => {
        setAccountNumber(event.target.value);
    };
    const handleChangeDescription = (event) => {
        setDescription(event.target.value);
    };
    const handleChangeType = (event) => {
        setType(event.target.value);
    };
    const handleChangeLocation = (event) => {
        setLocation(locationSuggestions.find((mnstry) => mnstry.id === event.target.value));
    };

    // validate user inputs
    const [accountNumberValidity, setAccountNumberValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateAccountNumber = () => {
        let validity = {};
        if (!accountNumber || accountNumber === '') {
            validity.isValid = false;
            validity.errorMessage = "AccountNumber cannot be empty"
            setAccountNumberValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setAccountNumberValidity(validity)
            return true
        }
    };

    // validate user inputs
    const [descriptionValidity, setDescriptionValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateDescription = () => {
        let validity = {};
        if (!description || description === '') {
            validity.isValid = false;
            validity.errorMessage = "Description cannot be empty"
            setDescriptionValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setDescriptionValidity(validity)
            return true
        }
    };

    // validate user inputs
    const [typeValidity, setTypeValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateType = () => {
        let validity = {};
        if (!type || type === '') {
            validity.isValid = false;
            validity.errorMessage = "Type cannot be empty"
            setTypeValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setTypeValidity(validity)
            return true
        }
    };

    const setInputsToDefaultOnFormSubmit = () => {
        setId('')
        setAccountNumber('')
        setDescription('')
        setType('')
        setLocation({})
        setStatus('')
    }

    const validateInputFields = () => {
        let formValidators = []
        let isFormValid = true
        if (!validateAccountNumber()) {
            formValidators[0] = false
        }
        if (!validateDescription()) {
            formValidators[1] = false
        }
        if (!validateType()) {
            formValidators[2] = false
        }
        formValidators.map((val) => {
            if (!val) {
                isFormValid = false
            }
        })
        return isFormValid;
    }
    const createUtilityAccountFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        let utilityAccount = {
            accountNumber: accountNumber,
            description: description,
            type: type,
            monasteryId: location ? location.id : "",
        };

        createUtilityAccount(
            (result) => {
                toast(`Utility account: ${utilityAccount.accountNumber} was created!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.createOrUpdatecallback(result.content);
            },
            utilityAccount,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    const updateUtilityAccountFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        let utitlityAccount = {
            id: id,
            accountNumber: accountNumber,
            description: description,
            type: type,
            monasteryId: location ? location.id : "",
            status: status
        };

        updateUtilityAccount(
            (result) => {
                toast(`Utility account: ${utitlityAccount.accountNumber} was updated!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.createOrUpdatecallback(result.content);
            },
            utitlityAccount,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    return (<Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1, width: '27ch'},
            }}
            noValidate
            autoComplete="off"
        >
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                >
                </DialogContentText>
                <div>
                    <TextField
                        required
                        id="account-number-input"
                        label="Account Number"
                        onChange={handleChangeAccountNumber}
                        value={accountNumber}
                        error={!accountNumberValidity.isValid}
                        onBlur={validateAccountNumber}
                        helperText={(accountNumberValidity.isValid) ? "Enter account number here" : accountNumberValidity.errorMessage}
                    />
                    <TextField
                        required
                        id="description-input"
                        label="Description"
                        onChange={handleChangeDescription}
                        value={description}
                        error={!descriptionValidity.isValid}
                        onBlur={validateDescription}
                        helperText={(descriptionValidity.isValid) ? "Enter description here" : descriptionValidity.errorMessage}
                    />
                </div>
                <div>
                    <FormControl sx={{m: 1, minWidth: '27ch', marginRight: 0}} error={!typeValidity.isValid}>
                        <InputLabel id="type-label">Type</InputLabel>
                        <Select
                            labelId="type-label"
                            id="type-input"
                            defaultValue=""
                            value={type}
                            onChange={handleChangeType}
                            onBlur={validateType}
                            error={!typeValidity.isValid}
                            autoWidth
                            label="Asset Type"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={'ELECTRICITY'}>Electricity</MenuItem>
                            <MenuItem value={'TELEPHONE'}>Telephone</MenuItem>
                            <MenuItem value={'WATER'}>Water</MenuItem>
                            <MenuItem value={'TAX'}>Tax</MenuItem>
                        </Select>
                        <FormHelperText>{typeValidity.isValid ? "Select the type" : "Type must not be empty"}</FormHelperText>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: '27ch'}}>
                        <InputLabel id="location-label">Location</InputLabel>
                        <Select
                            labelId="location-label"
                            id="location-input"
                            defaultValue=""
                            value={location ? location.id ? location.id : '' : ""}
                            onChange={handleChangeLocation}
                            autoWidth
                            label="Location"
                        >
                            <MenuItem key={"none"} value="">
                                <em>None</em>
                            </MenuItem>
                            {(locationSuggestions) && locationSuggestions.map((location, index) => (
                                <MenuItem key={index} value={location.id}>{location.nameUnicode}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{"Select a location"}</FormHelperText>
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions>
                {props.isUpdate ? (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={updateUtilityAccountFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Update/>}
                >Update</LoadingButton>) : (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={createUtilityAccountFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Create/>}
                >Create</LoadingButton>)}
                <LoadingButton
                    color={'warning'}
                    size="medium"
                    variant="outlined"
                    onClick={props.handleClose}
                    loading={false}
                    loadingPosition="end"
                    disabled={false}
                    endIcon={<Cancel/>}
                >Cancel</LoadingButton>
            </DialogActions>
        </Box>
    )
}