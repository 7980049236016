import * as React from 'react';

import { useState, useEffect } from "react";

import {
    Box, TextField, IconButton,
    InputLabel, Select, MenuItem, FormHelperText, FormControl,
    ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, ListItem, List, ListSubheader, DialogContent, DialogContentText, DialogActions,
} from "@mui/material";

import {
    Delete, AddCircleOutlineOutlined, LocalOfferOutlined, Cancel, Create, Update,
} from "@mui/icons-material";

import { LoadingButton } from "@mui/lab";

import { deshanaAccessSuggestions } from "../../../client/authorities/call";


import { loadDirectoryById, createDirectory, directoryUpdate } from '../../../client/deshana/call'
import {toast} from "react-toastify";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";


// create directory form
export default function CreateDirectoryForm(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        loadAllowedAccess()
        if (props.isUpdate) {
            setIsLoading(true)
            loadDirectoryById(
                result => {
                    setName(result.content.name)
                    setAllowedAccess(result.content.allowedUserAccess)
                    setDirectoryTags(result.content.tags)
                    setStatus(result.content.status)
                    setBreadcrumbs(result.content.breadcrumbs)

                    setIsLoading(false)
                    validateAllowedAccess()
                    validateName()
                },
                props.directoryToBeUpdated,
            );
        }
    }, [])

    const [status, setStatus] = useState('');
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    // create directory user inputs
    const [name, setName] = useState('');
    const handleChangeName = (event) => {
        setName(event.target.value);
    };
    const [nameValidity, setNameValidity] = useState(0);
    const validateName = (event) => {
        if (!name || name === '') {
            setNameValidity(1);
        } else {
            setNameValidity(2);
        }
    };

    const [allowedAccess, setAllowedAccess] = useState('');
    const handleChangeAllowedAccess = (event) => {
        setAllowedAccess(event.target.value);
    };
    const [allowedAccessValidity, setAllowedAccessValidity] = useState(0);
    const validateAllowedAccess = (event) => {
        if (!allowedAccess || allowedAccess === '') {
            setAllowedAccessValidity(1);
        } else {
            setAllowedAccessValidity(2);
        }
    };
    const [allowedAccessSugestions, setAllowedAccessSugestions] = useState([]);
    const loadAllowedAccess = () => {
        deshanaAccessSuggestions(result => {
            setAllowedAccessSugestions(result.content);
        });
    }

    const [directoryTag, setDirectoryTag] = useState('');
    const [directoryTags, setDirectoryTags] = useState([]);
    const handleChangeDirectoryTag = (event) => {
        setDirectoryTag(event.target.value);
    };

    const addToDirectoryTags = () => {
        validateDirectoryTag()
        if (directoryTag && !(directoryTag === ''))
            setDirectoryTags((directoryTags) => [...directoryTags, directoryTag])
        setDirectoryTagInputToDefault()
    }
    const [isDirectoryTagValid, setIsDirectoryTagValid] = useState(true);
    const validateDirectoryTag = (event) => {
        if (!directoryTag || (directoryTag === ''))
            setIsDirectoryTagValid(false);
    };
    const setDirectoryTagInputToDefault = () => setDirectoryTag('');

    const removeElementFromTags = (index) => {
        if (index > -1) { // only splice array when item is found
            directoryTags.splice(index, 1); // 2nd parameter means remove one item only
            setDirectoryTags([...directoryTags]);
        }
    };
    const checkFormValid = () => {
        if ((nameValidity === 2) && allowedAccessValidity === 2) {
            setIsFormValid(true)
        } else {
            setIsFormValid(false)
        }
    }

    useEffect(() => {
        checkFormValid()
    },
        [nameValidity, allowedAccessValidity]
    )

    const setInputsToDefaultOnFormSubmit = () => {
        setName('')
        setAllowedAccess('')
        setDirectoryTags([])
        setStatus('')
        setBreadcrumbs([])
    }

    const createDirectoryFunc = () => {
        setIsLoading(true)
        let directory = {
            name: name,
            elementType: 'DIR',
            parentId: props.parentDirectoryId,
            breadcrumbs: props.breadcrumbs,
            tags: directoryTags,
            allowedUserAccess: allowedAccess
        };

        createDirectory(
            () => {
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.refreshTable();
            },
            directory,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    const updateDirectory = () => {
        setIsLoading(true)
        let directory = {
            id: props.directoryToBeUpdated,
            name: name,
            elementType: 'DIR',
            parentId: props.parentDirectoryId,
            breadcrumbs: breadcrumbs,
            tags: directoryTags,
            allowedUserAccess: allowedAccess,
            status: status
        };

        directoryUpdate(
            () => {
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.refreshTable();
            },
            directory,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    return (<Box
        component="form"
        sx={{
            '& .MuiTextField-root': { m: 1, width: '27ch' },
        }}
        noValidate
        autoComplete="off"
    >
        <DialogContent dividers={true}>
            <DialogContentText
                id="scroll-dialog-description"
                tabIndex={-1}
            >
            </DialogContentText>
            <div>
                <TextField
                    required
                    id="outlined-required"
                    label="Folder Name"
                    onChange={handleChangeName}
                    value={name}
                    error={nameValidity === 1}
                    onClick={() => setNameValidity(0)}
                    onBlur={validateName}
                    helperText={(nameValidity === 1) ? "Enter folder name here" : "Folder name must not be empty"}
                />
                <FormControl sx={{ m: 1, minWidth: '27ch' }} error={allowedAccessValidity === 1}>
                    <InputLabel id="directory-user-role-label">User Role</InputLabel>
                    <Select
                        labelId="directory-user-role-label"
                        id="directory-user-role-input"
                        value={allowedAccess}
                        onChange={(event) => {
                            handleChangeAllowedAccess(event)
                        }}
                        onBlur={validateAllowedAccess}
                        autoWidth
                        label="Role"
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {allowedAccessSugestions.map((rl, index) => (
                            <MenuItem key={index} value={rl.id}>{rl.name}</MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{allowedAccessValidity === 1 ? "Select a user role" : "User role must not be empty"}</FormHelperText>
                </FormControl>
            </div>
            <div>
                <TextField
                    id="outlined"
                    label="Tag"
                    onChange={handleChangeDirectoryTag}
                    value={directoryTag}
                    error={!isDirectoryTagValid}
                    onClick={() => setIsDirectoryTagValid(true)}
                    helperText={isDirectoryTagValid ? "Enter folder tag here" : "Folder tag must not be empty"}
                />
                <IconButton
                    aria-label="expand row"
                    size="large"
                    sx={{ marginTop: 1 }}
                    onClick={addToDirectoryTags}
                >
                    <AddCircleOutlineOutlined fontSize={"large"} />
                </IconButton>
            </div>
            <div>
                <div>
                    <List >
                        <ListSubheader>Directory Tags</ListSubheader>
                        {directoryTags.map((item, index) => (
                            <ListItem key={index}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <LocalOfferOutlined />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={item}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton onClick={() => removeElementFromTags(index)} edge="end" aria-label="delete">
                                        <Delete />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </div>
            </div>
        </DialogContent>
        <DialogActions>
            {props.isUpdate ? (<LoadingButton
                size="medium"
                variant="outlined"
                onClick={updateDirectory}
                loading={isLoading}
                loadingPosition="end"
                disabled={!isFormValid}
                endIcon={<Update />}
            >Update</LoadingButton>) : (<LoadingButton
                size="medium"
                variant="outlined"
                onClick={createDirectoryFunc}
                loading={isLoading}
                loadingPosition="end"
                disabled={!isFormValid}
                endIcon={<Create />}
            >Create</LoadingButton>)}

            <LoadingButton
                size="medium"
                variant="outlined"
                onClick={props.handleClose}
                loading={false}
                loadingPosition="end"
                disabled={false}
                endIcon={<Cancel />}
            >Cancel</LoadingButton>
        </DialogActions>
    </Box>
    )
}