import PageLayout from "../../../ui/layout/pageLayout";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {deleteUtilityAccountById, searchUtilityAccounts} from "../../../../client/utility-accounts/call";
import {Card, MenuItem, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import ActionMenu from "../../../ui/menus/actionMenu";
import ListItemIcon from "@mui/material/ListItemIcon";
import {Delete, Edit} from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import CreateUtilityAccountForm from "../utilityAccounts/createUtilityAccountForm";
import FloatingActionButtons from "../../../ui/buttons/floatingActions";
import MainModalDialog from "../../../ui/modal/mainModal";
import MainTable from "../../../ui/table/mainTable";
import Pagination from "../../../ui/pagination/pagination";
import AddIcon from "@mui/icons-material/Add";
import {toast} from "react-toastify";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Swal from "sweetalert2";

export default function UtilityAccounts() {

    const navigate = useNavigate()
    const params = useParams()

    const [pagination, setPagination] = useState({})

    // loading section
    const [isUtilityAccountsLoading, setIsUtilityAccountsLoading] = useState(true);

    // utilityAccounts list
    const [utilityAccountsList, setUtilityAccountsList] = useState([]);

    function loadUtilityAccounts(page, rowsPerPage) {
        setIsUtilityAccountsLoading(true)
        searchUtilityAccounts(
            result => {
                setUtilityAccountsList(result.content);
                setPagination(result.pagination);
                setIsUtilityAccountsLoading(false)
            },
            err => {
                setIsUtilityAccountsLoading(false)
            },
            page ? page : pagination.pageNumber,
            rowsPerPage ? rowsPerPage : pagination.pageSize,
            null,
            null,
            null
        );
    }

    const {page, rowsPerPage} = pagination
    useEffect(loadUtilityAccounts, [page, rowsPerPage])

    // modal related properties
    const [modalOpen, setModalOpen] = useState(false);
    const [modelToOpen, setModelToOpen] = useState('');
    const [modelHeader, setModelHeader] = useState('');
    const [utilityAccountTobeUpdated, setUtilityAccountTobeUpdated] = useState('');

    function deleteUtilityAccountsFunc(utilityAccountId) {
        deleteUtilityAccountById(data => {
                toast(`Utility account was deleted!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                loadUtilityAccounts();
            },
            utilityAccountId,
            error => {
                toast(`Error: ${error}`,
                    {
                        closeOnClick: true,
                        type: "error",
                        theme: "light",
                        icon: <ErrorOutlineIcon/>
                    });
            })
    }

    const tableHeader = (<TableHead>
        <TableRow>
            <TableCell>Account Number</TableCell>
            <TableCell align="left">Description</TableCell>
            <TableCell align="left">Type</TableCell>
            <TableCell align="left">Action Items</TableCell>
        </TableRow>
    </TableHead>)

    const tableBody = (
        (utilityAccountsList && utilityAccountsList.length > 0) ? (<TableBody>
                {utilityAccountsList.map((utilityAccount, index) => (
                    <TableRow
                        key={index}
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                        <TableCell align="left">{utilityAccount.accountNumber}</TableCell>
                        <TableCell align="left">{utilityAccount.description}</TableCell>
                        <TableCell align="left">{utilityAccount.type}</TableCell>
                        <TableCell align="left" onClick={() => {
                            setUtilityAccountTobeUpdated(utilityAccount.id)
                        }}>
                            <ActionMenu>
                                <MenuItem onClick={() => {
                                    showDeleteConfirmation(utilityAccount.id, utilityAccount.accountNumber)
                                }}>
                                    <ListItemIcon>
                                        <Delete/>
                                    </ListItemIcon>
                                    <ListItemText>Delete</ListItemText>
                                    <Typography variant="body2" color="text.secondary">
                                    </Typography>
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    handleModalOpen("updateUtilityAccount")
                                }}>
                                    <ListItemIcon>
                                        <Edit/>
                                    </ListItemIcon>
                                    <ListItemText>Update</ListItemText>
                                    <Typography variant="body2" color="text.secondary">
                                    </Typography>
                                </MenuItem>
                            </ActionMenu>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        ) : null
    )

    const handleModalOpen = (model) => {
        if (model === 'createUtilityAccount') {
            setModelHeader("Create Utility Account")
            setModelToOpen(createUtilityAccountForm)
        } else if (model === 'updateUtilityAccount') {
            setModelHeader("Update Utility Account")
            setModelToOpen(updateUtilityAccountForm)
        }
        setModalOpen(true);
    }
    const handleModalClose = () => setModalOpen(false);

    const createUtilityAccountForm = (<CreateUtilityAccountForm
        isUpdate={false}
        handleClose={handleModalClose}
        utilityAccountId={null}
        createOrUpdatecallback={(data) => {
            loadUtilityAccounts()
            handleModalClose()
        }}
    />);

    const updateUtilityAccountForm = (<CreateUtilityAccountForm
        isUpdate={true}
        handleClose={handleModalClose}
        utilityAccountId={utilityAccountTobeUpdated}
        createOrUpdatecallback={(data) => {
            loadUtilityAccounts()
            handleModalClose()
        }}
    />);

    const floatingActionConfig = [
        {
            title: "Create",
            icon: <AddIcon sx={{mr: 1}}/>,
            onClickAction: handleModalOpen,
            modelName: "createUtilityAccount",
            visible: true,
        }
    ]

    const showDeleteConfirmation = (pid, itmId) => {
        Swal.fire({
            icon: 'warning',
            title: `${itmId}`,
            text: `Are you sure you want to delete this item?`,
            showConfirmButton: true,
            showCancelButton: true,
            footer: 'Please double check before accepting this action'
        }).then((value) => {
            if (value.isConfirmed) {
                deleteUtilityAccountsFunc(pid)
            }
        })
    }

    return (
        <PageLayout header="Utility Accounts">
            <Card sx={{padding: 1, margin: 0, border: '1px solid', borderColor: 'rgb(229,226,226)'}}>
                <FloatingActionButtons config={floatingActionConfig}/>
                <MainModalDialog
                    open={modalOpen}
                    handleClose={handleModalClose}
                    header={modelHeader}
                    body={modelToOpen}
                />
                <MainTable
                    iscontentLoading={isUtilityAccountsLoading}
                    tableHeader={tableHeader} tableBody={tableBody}
                />
                <Pagination
                    reloadData={(pageNumber, pageSize) => loadUtilityAccounts(pageNumber, pageSize)}
                    pagination={pagination}
                />
            </Card>
        </PageLayout>
    );
}