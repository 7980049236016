import { useState, useEffect } from "react";

import { Box, Stack, Typography, Paper, DialogContent, DialogContentText, DialogActions, Button, } from "@mui/material";
import { Cancel, Clear, SelectAll, Upload, } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import { LinearProgressBar } from "../../ui/progress/linearProgressBar";


// upload deshan model dialog body
export default function UploadDeshanaForm(props) {

    const [uploadedDeshanaList, setUploadedDeshanaList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    // upload deshana user inputs
    const [filesToBeUploaded, setFilesToBeUploaded] = useState([]);
    const handleChangeFilesToBeUploaded = (event) => {
        let files = event.target.files
        setFilesToBeUploaded(files);
        let temp = []
        for (let i = 0; i < files.length; i++) {
            temp.push({"name": files[i].name, progress: 0})
        }
        setFilesProgress(temp); 
    };
    const [isFilesToBeUploadedValid, setIsFilesToBeUploadedValid] = useState(false);
    
    useEffect(() => {
        if (filesToBeUploaded.length < 1) {
            setIsFilesToBeUploadedValid(false);
        } else {
            setIsFilesToBeUploadedValid(true)
        }
    }, [filesToBeUploaded])

    useEffect(() => {
        if ((filesToBeUploaded.length) === (uploadedDeshanaList.length)) {
            if(isLoading) {
                props.refreshTable();
                setUploadedDeshanaList([]);
                setFilesToBeUploaded([])
            }
            setIsLoading(false);
            
        }
    }, [uploadedDeshanaList])

    const [filesProgress, setFilesProgress] = useState([])

    const uploadDeshanaFiles = () => {
        setIsLoading(true)
        for (let i = 0; i < filesToBeUploaded.length; i++) {            
            props.uploadDeshanaFile(
                () => {
                    setUploadedDeshanaList((uploadedDeshanaList) => [...uploadedDeshanaList, filesToBeUploaded[i].name])
                },
                err => {

                },
                prog => {
                    let temp = [...filesProgress]
                    temp[i].progress = prog
                    setFilesProgress([...temp])
                },
                filesToBeUploaded[i],
            );
        }
    }

    const clearFilesTobeUploaded = () => {
        setFilesProgress([]);
        setFilesToBeUploaded([]);
    }

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '27ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                >
                </DialogContentText>
                <Stack direction="row" sx={{minWidth: "400px"}} >
                    <input
                        required
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        onChange={handleChangeFilesToBeUploaded}
                        type={"file"}
                        multiple
                    />
                    <label htmlFor="raised-button-file">
                        <Button variant="outlined" component="span" startIcon={<SelectAll />} >
                            Select Files
                        </Button>
                    </label>
                    <Button 
                        sx={{marginLeft:"5px"}} 
                        variant="outlined" 
                        component="span" 
                        startIcon={<Clear />}
                        disabled={!isFilesToBeUploadedValid}
                        onClick={clearFilesTobeUploaded}
                    >
                            Clear
                    </Button>
                </Stack>
                {filesProgress.map((item, index) => (
                    <Paper key={index} sx={{ margin: '0.5rem 0', padding: '0.5rem' }}>
                        <Typography variant="caption" display="block" gutterBottom>
                            {item.name}
                        </Typography>
                        <Box sx={{ width: '100%' }}>
                            <LinearProgressBar value={item.progress} />
                        </Box>
                    </Paper>
                ))}
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={uploadDeshanaFiles}
                    loading={isLoading}
                    loadingPosition="end"
                    disabled={!isFilesToBeUploadedValid}
                    endIcon={<Upload />}
                >Upload</LoadingButton>
                <LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={props.handleClose}
                    loading={isLoading}
                    loadingPosition="end"
                    disabled={false}
                    endIcon={<Cancel />}
                >Cancel</LoadingButton>
            </DialogActions>
        </Box>
    )
}