import {BFF_CONFIGS} from '../../config'
import {BFF_ENDPOINTS} from '../../utils/constants'
import { _get, _put, _post ,_delete } from '../fetch-api'

export const createContentWrapContainer = (callback, data, errCallBack) => {
  const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.contentWrapContainerCreate}`
  _post(callback, endpointURL, data, {}, errCallBack)
}

export const updatePageContainerOrder = (callback, data, errCallBack) => {
  const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.updatePageContainerOrder}`
  _put(callback, endpointURL, data, errCallBack)
}

export const deleteContentWrapContainerById = (callback, metadata, errCallBack) => {
  const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.contentWrapContainerDelete}`
  _delete(callback, endpointURL, errCallBack, {metadata})
}

export const getContentWrapContainerById = (callback, errorCallback, id) => {
  const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getContentWrapContainerById}/${id}`
  _get(callback, endpointURL, {}, null, errorCallback)
}

export const updateContentWrapContainer = (callback, body, errCallBack) => {
  const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.updateContentWrapContainer}`
  _put(callback, endpointURL, body, errCallBack)
}

export const getContentWrapContainerSuggestions = (callback, errCallBack, parentId) => {
  const endpointURL = `${BFF_CONFIGS.baseUrl}/${BFF_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getContentWrapContainerSuggestions}/${parentId}`
  _get(callback, endpointURL, {}, null, errCallBack)
}
