import {useState, useEffect} from "react";

import {
    Box,
    TextField,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@mui/material";
import {Cancel, Create, Update,} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";

import {
    createDiscount,
    getDiscountById,
    updateDiscount
} from "../../../../client/discounts/call";
import {toast} from "react-toastify";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {formatAmount} from "../../../../utils/app-utils";


// create discount form
export default function CreateDiscountForm(props) {

    const [isLoading, setIsLoading] = useState(false);

    // create user inputs
    const [id, setId] = useState('');
    const [amount, setAmount] = useState('');

    useEffect(() => {
        if (props.isUpdate) {
            setIsLoading(true)
            getDiscountById(
                result => {
                    setId(result.content.id)
                    setAmount(result.content.amount)
                    setStatus(result.content.status)

                    setIsLoading(false)
                },
                error => {
                },
                props.discountId,
            );
        }
    }, [])

    const [status, setStatus] = useState('');

    //--------------------------------------------------
    const handleChangeAmount = (event) => {
        const sanitizedValue = event.target.value.replace(/,/g, '');
        setAmount(sanitizedValue);
    }

    // validate user inputs
    const [amountValidity, setAmountValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateAmount = () => {
        let validity = {};
        if (!amount || amount === '') {
            validity.isValid = false;
            validity.errorMessage = "Amount cannot be empty"
            setAmountValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setAmountValidity(validity)
            return true
        }
    };

    const setInputsToDefaultOnFormSubmit = () => {
        setAmount('')
        setStatus('')
    }

    const validateInputFields = () => {
        let formValidators = []
        let isFormValid = true
        if (!validateAmount()) {
            formValidators[0] = false
        }
        formValidators.map((val) => {
            if (!val) {
                isFormValid = false
            }
        })
        return isFormValid;
    }
    const createDiscountFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        let discount = {
            amount: parseFloat(amount),
        };

        createDiscount(
            (result) => {
                toast(`Discount: ${discount.amount} was created!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.createOrUpdatecallback(result.content);
            },
            discount,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    const updateDiscountFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        let discount = {
            id: id,
            amount: parseFloat(amount),
            status: status
        };

        updateDiscount(
            (result) => {
                toast(`Discount: ${discount.amount} was updated!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.createOrUpdatecallback(result.content);
            },
            discount,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    return (<Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1, width: '27ch'},
            }}
            noValidate
            autoComplete="off"
        >
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                >
                </DialogContentText>
                <div>
                    <TextField
                        required
                        id="amount-input"
                        label="Amount"
                        onChange={handleChangeAmount}
                        value={formatAmount(amount)}
                        error={!amountValidity.isValid}
                        onBlur={validateAmount}
                        helperText={(amountValidity.isValid) ? "Enter amount here" : amountValidity.errorMessage}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                {props.isUpdate ? (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={updateDiscountFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Update/>}
                >Update</LoadingButton>) : (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={createDiscountFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Create/>}
                >Create</LoadingButton>)}
                <LoadingButton
                    color={'warning'}
                    size="medium"
                    variant="outlined"
                    onClick={props.handleClose}
                    loading={false}
                    loadingPosition="end"
                    disabled={false}
                    endIcon={<Cancel/>}
                >Cancel</LoadingButton>
            </DialogActions>
        </Box>
    )
}