import {
    Autocomplete,
    Box,
    Card,
    FormControl, FormControlLabel,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select, Switch,
    TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {
    CleaningServices,
    Eject,
    Search,
} from "@mui/icons-material";
import {useContext, useEffect, useState} from "react";
import {LoadingButton} from "@mui/lab";
import {getVendorSuggestions} from "../../../../client/vendors/call";
import {getVoucherSuggestions} from "../../../../client/vouchers/call";
import {loadLocationsSuggestions} from "../../../../client/location/call";
import {loadFoundationsSuggestions} from "../../../../client/foundation/call";
import {getEventSuggestions} from "../../../../client/events/call";
import {
    getAssociateSuggestions,
    getItemCategoryById,
    getItemCategorySuggestions
} from "../../../../client/item-categories/call";
import * as moment from "moment";
import {allowSpecialPayments} from "../../../../utils/app-utils";
import {AppContext} from "../../../../context/appContext";


export default function PaymentSearchForm(props) {

    const { auth } = useContext(AppContext)

    const [billNo, setBillNo] = useState(''); // Vendor's payment id
    const [paymentId, setPaymentId] = useState(''); // Pasadika reference id
    const [paymentType, setPaymentType] = useState('ALL');
    const [transactionDateFrom, setTransactionDateFrom] = useState(null);
    const [transactionDateTo, setTransactionDateTo] = useState(null);
    const [vendor, setVendor] = useState({});
    const [voucher, setVoucher] = useState({});
    const [location, setLocation] = useState({});
    const [foundation, setFoundation] = useState({});
    const [event, setEvent] = useState({});
    const [category, setCategory] = useState({});
    const [subCategory, setSubCategory] = useState({});
    const [associate, setAssociate] = useState({});
    const [tobeReviewed, setTobeReviewed] = useState(null);
    const [isSpecial, setIsSpecial] = useState(false);

    const [vendorSuggestions, setVendorSuggestions] = useState(['']);
    const [voucherSuggestions, setVoucherSuggestions] = useState(['']);
    const [locationSuggestions, setLocationSuggestions] = useState(['']);
    const [foundationSuggestions, setFoundationSuggestions] = useState(['']);
    const [eventSuggestions, setEventSuggestions] = useState(['']);
    const [categorySuggestions, setCategorySuggestions] = useState(['']);
    const [subCategorySuggestions, setSubCategorySuggestions] = useState(['']);
    const [associateSuggestions, setAssociateSuggestions] = useState(['']);

    useEffect(() => {
        loadVendorSuggestionsFunc()
        loadVoucherSuggestionsFunc()
        loadLocationSuggestionsFunc()
        loadFoundationSuggestionsFunc()
        loadEventSuggestionsFunc()
        loadCategorySuggestions()
    }, [])

    const handleChangeBillNo = (event) => {
        setBillNo(event.target.value);
    };

    const handleChangePaymentId = (event) => {
        setPaymentId(event.target.value);
    };

    const handleChangePaymentType = (event) => {
        setPaymentType(event.target.value);
    };

    const handleChangeTransactionDateFrom = (value) => {
        let val = null
        if (value) {
            val = value.$d
            setTransactionDateFrom(moment(val).format('YYYY-MM-DD'));
        } else {
            setTransactionDateFrom(null)
        }
    };
    const handleChangeTransactionDateTo = (value) => {
        let val = null
        if (value) {
            val = value.$d
            setTransactionDateTo(moment(val).format('YYYY-MM-DD'));
        } else {
            setTransactionDateTo(null)
        }
    };

    const handleChangeVendor = (event, newValue) => {
        setVendor(newValue);
    };
    const handleChangeVoucher = (event, newValue) => {
        setVoucher(newValue);
    };

    const handleChangeLocation = (event) => {
        setLocation(locationSuggestions.find((mnstry) => mnstry.id === event.target.value));
    };
    const handleChangeFoundation = (event) => {
        setFoundation(foundationSuggestions.find((fnd) => fnd.id === event.target.value));
    };
    const handleChangeEvent = (event, newValue) => {
        setEvent(newValue);
    };

    const handleChangeCategory = (event) => {
        setCategory(categorySuggestions.find((cat) => cat.id === event.target.value));
        setSubCategory({})
        setAssociate({})
    };

    const handleChangeAssociate = (event) => {
        setAssociate(associateSuggestions.find((asscte) => asscte.id === event.target.value));
    };

    const handleChangeSubCategory = (event) => {
        setSubCategory(subCategorySuggestions.find((cat) => cat.id === event.target.value));
        setAssociate({})
    };

    const handleChangeTobeReviewed = () => {
        setTobeReviewed(!tobeReviewed);
    };

    const handleChangeIsSpecial = () => {
        setIsSpecial(!isSpecial);
    };

    useEffect(() => {
        if (category && category.id) {
            getItemCategoryById((result) => {
                    setSubCategorySuggestions(result.content ? result.content.subCategories : [])
                },
                (error) => {
                },
                category.id
            );
            if (category.type && (category.type !== '')) {
                loadAssociateSuggestionsFunc(category.type);
            }
        }
    }, [category])

    function loadAssociateSuggestionsFunc(type) {
        getAssociateSuggestions(result => {
                setAssociateSuggestions(result.content);
            },
            type
        );
    }

    const handleSearch = () => {
        let searchParams = {
            billNo: billNo ? billNo : '',
            paymentId: paymentId ? paymentId : '',
            paymentType: paymentType ? paymentType : '',
            categoryId: category ? category.id : '',
            associateId: associate ? associate.id : '',
            subCategoryId: subCategory ? subCategory.id : '',
            transactionDateFrom: transactionDateFrom,
            transactionDateTo: transactionDateTo,
            voucherId: voucher.id,
            vendorId: vendor.id,
            monasteryId: location ? location.id : "",
            foundationId: foundation ? foundation.id : "",
            eventId: event ? event.id : "",
            tobeReviewed: tobeReviewed,
            isSpecial: isSpecial,
        }
        props.searchPayments(searchParams)
    }

    function loadVendorSuggestionsFunc() {
        getVendorSuggestions(result => {
            setVendorSuggestions(result.content);
        });
    }

    function loadVoucherSuggestionsFunc() {
        getVoucherSuggestions(result => {
            setVoucherSuggestions(result.content);
        });
    }

    function loadLocationSuggestionsFunc() {
        loadLocationsSuggestions(result => {
            setLocationSuggestions(result.content);
        });
    }

    function loadFoundationSuggestionsFunc() {
        loadFoundationsSuggestions(result => {
            setFoundationSuggestions(result.content);
        });
    }

    function loadEventSuggestionsFunc() {
        getEventSuggestions(result => {
            setEventSuggestions(result.content);
        });
    }

    function loadCategorySuggestions() {
        getItemCategorySuggestions(result => {
            setCategorySuggestions(result.content);
        });
    }

    const setInputsToDefaultOnFormSubmit = () => {
        setBillNo('')
        setPaymentId('')
        setPaymentType('')
        setCategory({})
        setSubCategory({})
        setAssociate({})
        setTransactionDateFrom(null)
        setTransactionDateTo(null)
        setVendor({})
        setVoucher({})
        setLocation({})
        setFoundation({})
        setEvent({})
        setTobeReviewed(false)
        setIsSpecial(false)
    }

    return (
        <Card sx={{marginBottom: 1, overflow: 'visible', border: '1px solid', borderColor: 'rgb(229,226,226)'}}>
            <Box component="form"
                 sx={{
                     '& .MuiTextField-root': {m: 1, width: '25ch'},
                 }}
                 noValidate
                 autoComplete="off">
                <Typography mt={2} align="center" variant="subtitle1" gutterBottom
                            component="div">
                    Search Payments
                </Typography>
                {auth && allowSpecialPayments(auth.authorities) && (
                    <FormControlLabel control={
                        <Switch checked={isSpecial}
                                onChange={handleChangeIsSpecial}
                                aria-label={"Special payment"}
                                defaultChecked color="primary"/>
                    }
                                      label={"Special payment"}
                    />
                )}
                <div>
                    <FormControl sx={{m: 1, minWidth: '25ch', marginRight: 0}}>
                        <InputLabel id="payment-type-label">Payment Type</InputLabel>
                        <Select
                            labelId="payment-type-label"
                            id="payment-type-input"
                            defaultValue=""
                            value={paymentType}
                            onChange={handleChangePaymentType}
                            label="Payment Type"
                        >
                            <MenuItem value={'none'}>All</MenuItem>
                            <MenuItem value={'PAYMENT'}>Payment</MenuItem>
                            <MenuItem value={'BILL'}>Bill</MenuItem>
                        </Select>
                        <FormHelperText>Select the payment type</FormHelperText>
                    </FormControl>
                    <TextField
                        id="billNo-input"
                        label="Vendor bill Number"
                        onChange={handleChangeBillNo}
                        value={billNo}
                        helperText={"Enter bill number here"}
                    />
                    <TextField
                        id="payment-id-input"
                        label="Pasadika payment Number"
                        onChange={handleChangePaymentId}
                        value={paymentId}
                        helperText={"Enter pasadika payment number here"}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            type='date'
                            id="transaction-date-from-input"
                            label="Transaction Date From"
                            inputFormat="YYYY-MM-DD"
                            value={transactionDateFrom}
                            onChange={handleChangeTransactionDateFrom}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    helperText={"Enter transaction date from here"}
                                />
                            }

                        />
                    </LocalizationProvider>
                </div>
                <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            type='date'
                            id="transaction-date-to-input"
                            label="Transaction Date To"
                            inputFormat="YYYY-MM-DD"
                            value={transactionDateTo}
                            onChange={handleChangeTransactionDateTo}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    helperText={"Enter transaction date to here"}
                                />
                            }

                        />
                    </LocalizationProvider>
                    <FormControl sx={{m: 1, minWidth: '25ch'}}>
                        <InputLabel id="location-label">Location</InputLabel>
                        <Select
                            labelId="location-label"
                            id="location-input"
                            defaultValue=""
                            value={location ? location.id ? location.id : '' : ""}
                            onChange={handleChangeLocation}
                            autoWidth
                            label="Location"
                        >
                            <MenuItem key={"none"} value="">
                                <em>None</em>
                            </MenuItem>
                            {(locationSuggestions) && locationSuggestions.map((location, index) => (
                                <MenuItem key={index} value={location.id}>{location.nameUnicode}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{"Select a location"}</FormHelperText>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: '25ch'}}>
                        <InputLabel id="foundation-label">Foundation</InputLabel>
                        <Select
                            labelId="foundation-label"
                            id="foundation-input"
                            defaultValue=""
                            value={foundation ? foundation.id ? foundation.id : '' : ""}
                            onChange={handleChangeFoundation}
                            autoWidth
                            label="Foundation"
                        >
                            <MenuItem key={"none"} value="">
                                <em>None</em>
                            </MenuItem>
                            {(foundationSuggestions) && foundationSuggestions.map((foundation, index) => (
                                <MenuItem key={index} value={foundation.id}>{foundation.nameUnicode}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{"Select a foundation"}</FormHelperText>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: '25ch', marginRight: 0}}>
                        <InputLabel id="category-label">Item Category</InputLabel>
                        <Select
                            labelId="category-label"
                            id="category-input"
                            defaultValue=""
                            value={category ? category.id ? category.id : '' : ''}
                            onChange={handleChangeCategory}
                            label="Category"
                        >
                            <MenuItem key={"none"} value="">
                                <em>None</em>
                            </MenuItem>
                            {(categorySuggestions) && categorySuggestions.map((category, index) => (
                                <MenuItem key={index} value={category.id}>{category.name}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{"Select a category"}</FormHelperText>
                    </FormControl>
                </div>
                <div>
                    {category && category.subCategories && category.subCategories.length > 0 && (
                        <FormControl sx={{m: 1, minWidth: '27ch', marginRight: 0}}>
                            <InputLabel id="sub-category-label">Item SubCategory</InputLabel>
                            <Select
                                labelId="sub-category-label"
                                id="sub-category-input"
                                defaultValue=""
                                value={subCategory ? subCategory.id ? subCategory.id : '' : ''}
                                onChange={handleChangeSubCategory}
                                label="Sub-Category"
                            >
                                <MenuItem key={"none"} value="">
                                    <em>None</em>
                                </MenuItem>
                                {(subCategorySuggestions) && subCategorySuggestions.map((subCategory, index) => (
                                    <MenuItem key={index} value={subCategory.id}>{subCategory.name}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{"Select a sub category"}</FormHelperText>
                        </FormControl>
                    )}
                    {category && category.type && category.type !== '' && (
                        <FormControl sx={{m: 1, minWidth: '27ch', marginRight: 0}}>
                            <InputLabel id="associate-label">{category.type.toLowerCase()}</InputLabel>
                            <Select
                                labelId="associate-label"
                                id="associate-input"
                                defaultValue=""
                                value={associate ? associate.id ? associate.id : '' : ''}
                                onChange={handleChangeAssociate}
                                label={`${category.type.toLowerCase()}`}
                            >
                                <MenuItem key={"none"} value="">
                                    <em>None</em>
                                </MenuItem>
                                {(associateSuggestions) && associateSuggestions.map((associate, index) => (
                                    <MenuItem key={index} value={associate.id}>{associate.identifier}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{`Select ${category.type.toLowerCase()}`}</FormHelperText>
                        </FormControl>
                    )}
                    <FormControl sx={{minWidth: '35ch', m: 0}}>
                        <Autocomplete
                            id="vendor-input"
                            value={vendor}
                            onChange={handleChangeVendor}
                            getOptionLabel={(option) => typeof option.name === 'string'
                            || option.name instanceof String ? option.name : ""}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            disablePortal
                            options={vendorSuggestions}
                            sx={{m: 0}}
                            renderInput={(params) =>
                                <TextField {...params}
                                           label="Vendor"
                                           sx={{m: 0, minWidth: '32ch'}}
                                           helperText={"Select the vendor"}
                                />}
                        />
                    </FormControl>
                </div>
                <div>
                    <FormControl sx={{minWidth: '35ch', m: 0}}>
                        <Autocomplete
                            id="event-input"
                            value={event}
                            onChange={handleChangeEvent}
                            getOptionLabel={(option) => typeof option.title === 'string'
                            || option.title instanceof String ? option.title : ""}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            disablePortal
                            options={eventSuggestions}
                            sx={{m: 0}}
                            renderInput={(params) =>
                                <TextField {...params}
                                           label="Event"
                                           sx={{m: 0, minWidth: '45ch'}}
                                           helperText={"Select project or event"}
                                />}
                        />
                    </FormControl>
                    <FormControl sx={{minWidth: '35ch', m: 0}}>
                        <Autocomplete
                            id="voucher-input"
                            value={voucher}
                            onChange={handleChangeVoucher}
                            getOptionLabel={(option) => typeof option.description === 'string'
                            || option.description instanceof String ? option.description : ""}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            disablePortal
                            options={voucherSuggestions}
                            sx={{m: 0}}
                            renderInput={(params) =>
                                <TextField {...params}
                                           label="Voucher"
                                           sx={{m: 0, minWidth: '35ch'}}
                                           helperText={"Select the voucher"}
                                />}
                        />
                    </FormControl>
                </div>
                <div>
                    <FormControlLabel control={
                        <Switch checked={tobeReviewed}
                                onChange={handleChangeTobeReviewed}
                                aria-label={tobeReviewed ? "Pending for review" : "No need a review"}
                                defaultChecked color="primary"/>}
                                      label={tobeReviewed ? "Pending for review" : "No need a review"}
                    />
                </div>
            </Box>
            <Box mb={1}>
                <LoadingButton sx={{marginRight: 1}}
                               size="medium"
                               variant="outlined"
                               onClick={handleSearch}
                               loading={false}
                               loadingPosition="end"
                               endIcon={<Search/>}
                >Search</LoadingButton>
                <LoadingButton sx={{marginRight: 1}}
                               size="medium"
                               variant="outlined"
                               onClick={() => {
                                   setInputsToDefaultOnFormSubmit()
                                   props.searchPayments({})
                                   props.closeForm()
                               }}
                               loading={false}
                               loadingPosition="end"
                               endIcon={<CleaningServices/>}
                >Clear Filters</LoadingButton>
                <LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={props.closeForm}
                    loading={false}
                    loadingPosition="end"
                    endIcon={<Eject/>}
                >Hide Search</LoadingButton>
            </Box>
        </Card>
    )
}