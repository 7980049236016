import {useState, useEffect} from "react";

import {
    Box,
    TextField,
    DialogContent,
    DialogContentText,
    DialogActions,
    IconButton, List, ListSubheader, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction,
} from "@mui/material";
import {AddCircleOutlineOutlined, Cancel, Create, Delete, LocalOfferOutlined, Update,} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import {createVendor, getVendorById, updateVendor} from "../../../../client/vendors/call";
import {toast} from "react-toastify";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";


// create item form
export default function CreateItemForm(props) {

    const [isLoading, setIsLoading] = useState(false);

    // create user inputs
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [addresses, setAddresses] = useState([]);
    const [address, setAddress] = useState('');
    const [phones, setPhones] = useState([]);
    const [phone, setPhone] = useState('');

    useEffect(() => {
        if (props.isUpdate) {
            setIsLoading(true)
            getVendorById(
                result => {
                    setId(result.content.id)
                    setName(result.content.name)
                    setAddresses(result.content.address)
                    setPhones(result.content.phone)
                    setStatus(result.content.status)

                    setIsLoading(false)
                },
                error => {
                },
                props.vendorId,
            );
        }
    }, [])

    const [status, setStatus] = useState('');

    //--------------------------------------------------
    const handleChangeName = (event) => {
        setName(event.target.value);
    };
    const handleChangeAddress = (event) => {
        setAddress(event.target.value);
    };
    const handleChangePhone = (event) => {
        setPhone(event.target.value);
    };

    // validate user inputs
    const [nameValidity, setNameValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateName = () => {
        let validity = {};
        if (!name || name === '') {
            validity.isValid = false;
            validity.errorMessage = "Name cannot be empty"
            setNameValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setNameValidity(validity)
            return true
        }
    };

    const [addressValidity, setAddressValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateAddress = () => {
        let validity = {};
        if (!address || address === '') {
            validity.isValid = false;
            validity.errorMessage = "Address cannot be empty"
            setAddressValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setAddressValidity(validity)
            return true
        }
    };

    const [phoneValidity, setPhoneValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validatePhone = () => {
        let validity = {};
        if (!phone || phone === '') {
            validity.isValid = false;
            validity.errorMessage = "Address cannot be empty"
            setPhoneValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setPhoneValidity(validity)
            return true
        }
    };

    const setInputsToDefaultOnFormSubmit = () => {
        setName('')
        setAddresses([])
        setPhones([])
        setStatus('')
    }

    const validateInputFields = () => {
        let formValidators = []
        let isFormValid = true
        if (!validateName()) {
            formValidators[0] = false
        }
        formValidators.map((val) => {
            if (!val) {
                isFormValid = false
            }
        })
        return isFormValid;
    }

    const createVendorFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        let vendor = {
            name: name,
            address: addresses,
            phone: phones,
        };

        createVendor(
            (result) => {
                toast(`Vendor: ${vendor.name} was created!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.createOrUpdatecallback(result.content);
            },
            vendor,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    const updateVendorFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        let vendor = {
            id: id,
            name: name,
            address: addresses,
            phone: phones,
            status: status
        };

        updateVendor(
            (result) => {
                toast(`Vendor: ${vendor.name} was updated!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.createOrUpdatecallback(result.content);
            },
            vendor,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    const addToAddresses = () => {
        if (!validateAddress()) {
            return
        }
        let temp = addresses.concat([address])
        setAddresses(temp)
        setAddress('')
    }

    const addToPhones = () => {
        if (!validatePhone()) {
            return
        }
        let temp = phones.concat([phone])
        setPhones(temp)
        setPhone('')
    }

    const removeAddressFromAddresses = (index) => {
        if (index > -1) { // only splice array when item is found
            addresses.splice(index, 1); // 2nd parameter means remove one item only
            setAddresses([...addresses]);
        }
    };

    const removePhoneFromPhones = (index) => {
        if (index > -1) { // only splice array when item is found
            phones.splice(index, 1); // 2nd parameter means remove one item only
            setPhones([...phones]);
        }
    };

    return (<Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1, width: '27ch'},
            }}
            noValidate
            autoComplete="off"
        >
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                >
                </DialogContentText>
                <div>
                    <TextField
                        required
                        id="name-input"
                        label="Name"
                        onChange={handleChangeName}
                        value={name}
                        error={!nameValidity.isValid}
                        onBlur={validateName}
                        helperText={(nameValidity.isValid) ? "Enter name here" : nameValidity.errorMessage}
                    />
                </div>
                <div>
                    <TextField
                        required
                        id="address-input"
                        label="Address"
                        onChange={handleChangeAddress}
                        value={address}
                        error={!addressValidity.isValid}
                        onBlur={validateAddress}
                        helperText={(addressValidity.isValid)  ? "Enter address here" : addressValidity.errorMessage}
                    />
                    <IconButton
                        aria-label="expand row"
                        size="large"
                        sx={{ marginTop: 1 }}
                        onClick={addToAddresses}
                    >
                        <AddCircleOutlineOutlined fontSize={"large"} />
                    </IconButton>
                </div>
                <div>
                    <TextField
                        required
                        id="phone-input"
                        label="Phone number"
                        onChange={handleChangePhone}
                        value={phone}
                        error={!phoneValidity.isValid}
                        onBlur={validatePhone}
                        helperText={(phoneValidity.isValid) ? "Enter phone number here" : phoneValidity.errorMessage}
                    />
                    <IconButton
                        aria-label="expand row"
                        size="large"
                        sx={{ marginTop: 1 }}
                        onClick={addToPhones}
                    >
                        <AddCircleOutlineOutlined fontSize={"large"} />
                    </IconButton>
                </div>
                <div>
                    <div>
                        <List >
                            <ListSubheader>Addresses</ListSubheader>
                            {(addresses) && addresses.map((ad, index) => (
                                <ListItem key={index}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <LocalOfferOutlined />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={ad}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={() => removeAddressFromAddresses(index)} edge="end" aria-label="delete">
                                            <Delete />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                    <div>
                        <List >
                            <ListSubheader>Phones</ListSubheader>
                            {(phones) && phones.map((ph, index) => (
                                <ListItem key={index}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <LocalOfferOutlined />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={ph}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={() => removePhoneFromPhones(index)} edge="end" aria-label="delete">
                                            <Delete />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                {props.isUpdate ? (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={updateVendorFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Update/>}
                >Update</LoadingButton>) : (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={createVendorFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Create/>}
                >Create</LoadingButton>)}
                <LoadingButton
                    color={'warning'}
                    size="medium"
                    variant="outlined"
                    onClick={props.handleClose}
                    loading={false}
                    loadingPosition="end"
                    disabled={false}
                    endIcon={<Cancel/>}
                >Cancel</LoadingButton>
            </DialogActions>
        </Box>
    )
}