import * as React from "react";

import {useMemo, forwardRef, useState} from "react";

import PropTypes from 'prop-types';

import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import ListItemIcon from '@mui/material/ListItemIcon';

import {Link as RouterLink, MemoryRouter} from 'react-router-dom';
import { StaticRouter } from 'react-router-dom/server';
import {ArrowDropDown, ArrowRight} from "@mui/icons-material";
import {Collapse} from "@mui/material";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import PersonIcon from "@mui/icons-material/Person";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FoundationIcon from '@mui/icons-material/Foundation';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SecurityIcon from '@mui/icons-material/Security';

import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import MoneyIcon from '@mui/icons-material/Money';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ClassIcon from '@mui/icons-material/Class';
import StoreIcon from '@mui/icons-material/Store';
import DiscountIcon from '@mui/icons-material/Discount';
import LabelIcon from '@mui/icons-material/Label';
import DateRangeIcon from '@mui/icons-material/DateRange';
import BadgeIcon from '@mui/icons-material/Badge';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import QueryStatsIcon from '@mui/icons-material/QueryStats';


const DrawerNavigationItems = (props) => {

    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    function Router(props) {
        const { children } = props;
        if (typeof window === 'undefined') {
            return <StaticRouter location="/deshana">{children}</StaticRouter>;
        }

        return (
            <MemoryRouter initialEntries={['/deshana']} initialIndex={0}>
                {children}
            </MemoryRouter>
        );
    }

    Router.propTypes = {
        children: PropTypes.node,
    };

    function ListItemLink(props) {
        const { icon, primary, to, index, isSub } = props;

        const renderLink = useMemo(
            () =>
                forwardRef(function Link(itemProps, ref) {
                    return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
                }),
            [to],
        );

        return (
            <li>
                <ListItemButton
                    selected={selectedIndex === index}
                    onClick={(event) => handleListItemClick(event, index)}
                    component={renderLink}
                    sx={{ pl: isSub?4:2 }}
                >
                    {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                    <ListItemText primary={primary} />
                </ListItemButton>
            </li>
        );
    }

    ListItemLink.propTypes = {
        icon: PropTypes.element,
        primary: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
    };

    const [isUserCollapse, setIsUserCollapse] = useState(false);
    const handleUserCollapse = () => {
        isUserCollapse?setIsUserCollapse(false):setIsUserCollapse(true);
    }

    const [isAccountingCollapse, setIsAccountingCollapse] = useState(false);
    const handleAccountingCollapse = () => {
        isAccountingCollapse?setIsAccountingCollapse(false):setIsAccountingCollapse(true);
    }

    const [isReportCollapse, setIsReportCollapse] = useState(false);
    const handleReportCollapse = () => {
        isReportCollapse?setIsReportCollapse(false):setIsReportCollapse(true);
    }

    return (
        <List>
            <ListItemLink to='/deshana' primary="Deshana" icon=<LibraryMusicIcon/> index={0}/>
            <ListItemButton
                onClick={handleUserCollapse}
            >
                <ListItemIcon><ManageAccountsIcon/></ListItemIcon>
                <ListItemText primary={"User Management"} />
                {isUserCollapse ? <ArrowDropDown /> : <ArrowRight /> }
            </ListItemButton>
            <Collapse in={isUserCollapse} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemLink isSub={true} to="/users" primary="Users" icon=<PersonIcon/> index={1}/>
                    <ListItemLink isSub={true} to="/roles" primary="Roles" icon=<SecurityIcon/> index={2}/>
                    <ListItemLink isSub={true} to="/authorities" primary="Authorities" icon=<SettingsAccessibilityIcon/> index={3}/>
                </List>
            </Collapse>
            <ListItemLink to='/locations' primary="Locations" icon=<LocationOnIcon/> index={4}/>
            <ListItemLink to='/foundations' primary="Foundations" icon=<FoundationIcon/> index={5}/>
            <ListItemLink to='/events' primary="Projects & Events" icon=<DateRangeIcon/> index={6}/>
            <ListItemLink to='/employees' primary="Employees" icon=<BadgeIcon/> index={7}/>
            <ListItemButton
                onClick={handleAccountingCollapse}
            >
                <ListItemIcon><AccountBalanceIcon/></ListItemIcon>
                <ListItemText primary={"Finance"} />
                {isAccountingCollapse ? <ArrowDropDown /> : <ArrowRight /> }
            </ListItemButton>
            <Collapse in={isAccountingCollapse} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemLink isSub={true} to="/vouchers" primary="Vouchers" icon=<ReceiptLongIcon/> index={8}/>
                    <ListItemLink isSub={true} to="/payments" primary="Payments" icon=<AttachMoneyIcon/> index={9}/>
                    <ListItemLink isSub={true} to="/items" primary="Items" icon=<ClassIcon /> index={10}/>
                    <ListItemLink isSub={true} to="/vendors" primary="Vendors" icon=<StoreIcon /> index={11}/>
                    <ListItemLink isSub={true} to="/discounts" primary="Discounts" icon=<DiscountIcon /> index={12}/>
                    <ListItemLink isSub={true} to="/item-categories" primary="Item Categories" icon=<LabelIcon /> index={13}/>
                    <ListItemLink isSub={true} to="/assets" primary="Assets" icon=<EmojiTransportationIcon /> index={14}/>
                    <ListItemLink isSub={true} to="/utility-accounts" primary="Utility Accounts" icon=<ReceiptIcon /> index={15}/>
                </List>
            </Collapse>
            <ListItemButton
                onClick={handleReportCollapse}
            >
                <ListItemIcon><QueryStatsIcon/></ListItemIcon>
                <ListItemText primary={"Reports"} />
                {isAccountingCollapse ? <ArrowDropDown /> : <ArrowRight /> }
            </ListItemButton>
            <Collapse in={isReportCollapse} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemLink isSub={true} to="/reports/finance/expenses" primary="Expenses" icon=<ReceiptLongIcon/> index={16}/>
                </List>
            </Collapse>
        </List>
    )
}

export default DrawerNavigationItems;